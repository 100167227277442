import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
import ReferenceService from '../components/api/referenceService.js';
import CustomerStatusService from '../components/api/customerStatus';
import WarningModal from './warningModal.js';
import Modal from 'react-responsive-modal';
import { createLogger } from '@trv-ent-web/logging';
import clientConfig from '../../src/utilities/clientConfig';
import { ReactComponent as InfoCircle18 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-18.svg';
const RecaptchaEnabled = clientConfig.isRecaptchaEnabled();
var url = '';
var isRecaptacha = '';
let logger = createLogger({ name: 'CPA Diagnosis log: View Status page logger' });

export class ViewStatus extends Component {
  constructor(props) {
    super(props);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.firstMethod = this.firstMethod.bind(this);
    this.secondMethod = this.secondMethod.bind(this);
    this.thirdMethod = this.thirdMethod.bind(this);
    this.recaptchaloaded = this.recaptchaloaded.bind(this);
    this.state = {
      dob: '',
      dobDay: '',
      dobMonth: '',
      dobYear: '',
      formErrors: { cssDob: '', cssLastName: '', cssSsn: '', cssTrackingNo: '', dob: '', lastName: '', ssn: '', trackingNo: '' },
      formValid: false,
      isCaptchaVerified: false,
      isError: false,
      isFormValid: false,
      isInternal: false,
      isSSNValid: false,
      isdobValid: false,
      islastNameValid: false,
      istrackingNoValid: false,
      lastName: '',
      open: false,
      reCatchaKey: '',
      recaptchaResponse: '',
      show: false,
      ssn: '',
      ssnValid: false,
      step: 1,
      trackingNo: ''
    };
  }
  firstMethod(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  secondMethod(e) {
    const re = /[a-zA-Z0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }
  thirdMethod(e) {
    const re = /[a-zA-Z'-\s]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  navigate = () => {
    this.props.navigateToHome();
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  onOpenModal = (e) => {
    e.preventDefault();
    this.setState({ open: true }
    );
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  continue = async (e) => {
    e.preventDefault();
    logger.info('CPA Diagnosis log: On click of continue on View Status page');
    try {
      const postData = { dateOfBirth: '', lastName: '', reqId: '', ssn: '' };
      postData.lastName = this.state.lastName;
      postData.ssn = this.state.ssn;
      postData.dateOfBirth = this.state.dob;
      postData.reqId = this.state.trackingNo;
      postData.recaptchaToken = this.state.recaptchaResponse;

      // calling the api to check the request count 
      const checkReqResponse = await CustomerStatusService.verifyRequestStatus(postData);
      if (checkReqResponse != null && checkReqResponse.data.length > 0) {
        this.props.navigatToGrid(checkReqResponse.data);

      }
      else if (checkReqResponse != null && checkReqResponse.data.name === 'StatusCodeError') {
        this.props.navigateToServiceDown();
      }
      else {
        this.setState({
          isError: true
        }, () => {
          window.scrollTo(0, 0);
        });

      }
    }
    catch (e) {
      logger.info('View Status Req Login');
      this.props.navigateToServiceDown();
    }
  }

  recaptchaloaded() {
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'Consumer Privacy | Travelers Insurance';
    let configs = await ReferenceService.configs();
    this.setState({ isInternal: configs.isInternal });
  };

  verifyCallback(response) {
    if (response) {
      this.setState({
        isCaptchaVerified: true,
        recaptchaResponse: response
      });
    }
  }

  onOpenModal = (e) => {
    e.preventDefault();
    this.setState({ open: true }, () => {
      window.scrollTo(0, 0);
    });
    window.scrollTo(0, 0);
  };

  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleUserInput = input => (e) => {
    const value = e.target.value;
    this.setState({ [input]: value }, () => { this.validateField(input, value); });
  }

  leftBlank = input => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [input]: value }, () => { this.forBlank(input, value, name); });
  }

  forBlank(fieldName, value, name) {
    switch (fieldName) {
      case 'dob':
        const month = document.getElementById('month').value;
        if (month.length === 2 && name === 'month') {
          document.getElementById('day').focus();
        }
        const day = document.getElementById('day').value;
        if (day.length === 2 && name === 'day') {
          document.getElementById('year').focus();
        }
        if (value === '' || value === '-0-0') {
          this.setState({
            formErrors: {
              cssDob: 'tds-field--error',
              dob: 'Please enter a valid date.',
            }
          });
        } else {
          this.setState({
            formErrors: {
              cssDob: '',
              dob: '',
            }
          });
        }
        break;
      case 'lastName':
        if (value === '') {
          this.setState({
            formErrors: {
              cssLastName: 'tds-field--error',
              lastName: 'Cannot be left blank.',
            }
          });

        } else {
          this.setState({
            formErrors: {
              cssLastName: '',
              lastName: '',
            }
          });
          this.validateField(fieldName, value);
        }
        break;
      case 'ssn':
        if (value === '') {
          this.setState({
            formErrors: {
              cssSsn: 'tds-field--error',
              ssn: 'Cannot be left blank.',
            }
          });

        } else {
          this.setState({
            formErrors: {
              cssSsn: '',
              ssn: '',
            }
          });
        }
        break;
      case 'trackingNo':
        if (value === '' && !this.state.isInternal) {
          this.setState({
            formErrors: {
              cssTrackingNo: 'tds-field--error',
              trackingNo: 'Cannot be left blank.',
            }
          });

        } else {
          this.setState({
            formErrors: {
              cssTrackingNo: '',
              trackingNo: '',
            }
          });
        }
        break;
      default:
        break;
    }
  }

  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    const formErrors1 = this.state.formErrors;
    let dobValid = this.state.isdobValid;
    let lastNameValid = this.state.islastNameValid;
    let socialSecurityNoValid = this.state.isSSNValid;
    let trackingNoValid = this.state.istrackingNoValid;
    switch (fieldName) {
      case 'lastName':
        const lName = value.replace(
          /(^\s*)|(\s*$)/gi, ''); this.setState({ lastName: lName });
        if (lName === '') {
          this.setState({
            formErrors: {
              cssLastName: 'tds-field--error',
              lastName: 'Cannot be left blank.',
            }
          });
        } else if (lName.match(/^[a-zA-Z’'-\s]{1,30}$/i)) {
          this.setState({
            formErrors: {
              cssLastName: '',
              lastName: '',
            }
          });
          lastNameValid = true;
        } else {
          lastNameValid = false;
          this.setState({
            formErrors: {
              cssLastName: 'tds-field--error',
              lastName: 'Please enter valid information.',
            }
          });
        }
        break;
      case 'ssn':
        if (value === '') {
          socialSecurityNoValid = false;
          this.setState({
            formErrors: {
              cssSsn: 'tds-field--error',
              ssn: 'Cannot be left blank.',
            }
          });
        } else if (value.match(/^\d{4}$/)) {
          this.setState({
            formErrors: {
              cssSsn: '',
              ssn: '',
            }
          });
          socialSecurityNoValid = true;
        } else {
          socialSecurityNoValid = false;
          this.setState({
            formErrors: {
              cssSsn: 'tds-field--error',
              ssn: 'Must be at least 4 characters.',
            }
          });
        }
        break;
      case 'dob':
        let month = '';
        let day = '';
        let year = '';
        let finalDate = new Date();
        if (document.getElementById('month')) {
          month = document.getElementById('month').value;
          if (month.length < 2 && month.length > 0) {
            month = '0' + month;
            document.getElementById('month').value = month;
          }
        }
        if (document.getElementById('day')) {
          day = document.getElementById('day').value;
          if (day.length < 2 && day.length > 0) {
            day = '0' + day;
            document.getElementById('day').value = day;
          }
        }
        if (document.getElementById('year')) {
          year = document.getElementById('year').value;
        }
        if (document.getElementById('month') && document.getElementById('day') && document.getElementById('year')) {
          finalDate = year + '-' + month + '-' + day;

        }
        this.setState({
          cssDob: '',
          dob: finalDate
        });
        var tdate = new Date();
        tdate.setHours(0, 0);
        tdate.setMinutes(0, 0);
        tdate.setSeconds(0, 0);
        tdate.setMilliseconds(0, 0);
        var bdate = new Date(finalDate);
        bdate.setHours(0, 0);
        bdate.setMinutes(0, 0);
        bdate.setSeconds(0, 0);
        bdate.setMilliseconds(0, 0);
        bdate.setDate(bdate.getDate() + 1);
        if (value === '' || value === '-0-0') {
          dobValid = false;
          this.setState({
            formErrors: {
              cssDob: 'tds-field--error',
              dob: 'Please enter a valid date.',
            }
          });
        }
        if (month && day && year) {
          var yr = year.match(/^20|19(0|1[1-9]|[0-9][0-9])$/);
          if ((bdate - tdate >= 0) || (day === ('31') && (['4','6','9','11','04','06','09'].includes(month)))){
            dobValid = false;
            this.setState({
              formErrors: {
                cssDob: 'tds-field--error',
                dob: 'Please enter a valid date.',
              }
            });
          } else if (parseInt(month) === 2) {
            //leap year
            if (year % 4 === 0 && yr) {
              if ((parseInt(day) >= 30) || (year % 4 === 0 && !yr)){
                dobValid = false;
                this.setState({
                  formErrors: {
                    cssDob: 'tds-field--error',
                    dob: 'Please enter a valid date.',
                  }
                });
              } else {
                this.setState({
                  formErrors: {
                    cssDob: '',
                    dob: '',
                  }
                });
                dobValid = true;
              }
            } else {
              if (parseInt(day) >= 29) {
                dobValid = false;
                this.setState({
                  formErrors: {
                    cssDob: 'tds-field--error',
                    dob: 'Please enter a valid date.',
                  }
                });
              } else {
                dobValid = true;
                this.setState({
                  formErrors: {
                    cssDob: '',
                    dob: '',
                  }
                });
              }
            }
          }
          else if ((tdate <= bdate) ||  (parseInt(month) > 12 || parseInt(day) > 31 || !yr || year.length < 4) || (month.toString() <= ('0') || month.toString() <= ('00') || day.toString() <= ('0') || day.toString() <= ('00'))){
            dobValid = false;
            this.setState({
              formErrors: {
                cssDob: 'tds-field--error',
                dob: 'Please enter a valid date.',
              }
            });
          } else {
            this.setState({
              formErrors: {
                cssDob: '',
                dob: '',
              }
            });
            dobValid = true;
          }
        }
        break;
      case 'trackingNo':
        if (this.state.isInternal) {
          if (value.match(/^[a-zA-Z0-9]{18}$/i) && value.length === 18) {
            //this.state.formErrors.trackingNo = '';
            this.setState({
              formErrors: {
                cssTrackingNo: '',
                trackingNo: '',
              }
            });
            trackingNoValid = true;
          }
          else if (value.length === 0) {
            this.setState({
              formErrors: {
                cssTrackingNo: '',
                trackingNo: '',
              }
            });
          }
          else if (value.length !== 18) {
            trackingNoValid = false;
            this.setState({
              formErrors: {
                cssTrackingNo: 'tds-field--error',
                trackingNo: 'Must be at least 18 characters.',
              }
            });
          }
          else {
            trackingNoValid = false;
            this.setState({
              formErrors: {
                cssTrackingNo: 'tds-field--error',
                trackingNo: 'Not a valid number.',
              }
            });
          }
        } else {
          if (value === '') {
            this.setState({
              formErrors: {
                cssTrackingNo: 'tds-field--error',
                trackingNo: 'Cannot be left blank.',
              }
            });
            document.getElementById('tb1').scrollIntoView();
            trackingNoValid = false;
          }
          else if (value.match(/^[a-zA-Z0-9]{18}$/i) && value.length === 18) {
            this.setState({
              formErrors: {
                cssTrackingNo: '',
                trackingNo: '',
              }
            });
            trackingNoValid = true;
          }
          else if (value.length !== 18) {
            trackingNoValid = false;
            this.setState({
              formErrors: {
                cssTrackingNo: 'tds-field--error',
                trackingNo: 'Must be at least 18 characters.',
              }
            });
            document.getElementById('tb1').scrollIntoView();
          }
          else {
            trackingNoValid = false;
            this.setState({
              formErrors: {
                cssTrackingNo: 'tds-field--error',
                trackingNo: 'Not a valid number.',
              }
            });
            document.getElementById('tb1').scrollIntoView();
          }
        }
        break;
      default:
        break;
    }
    this.setState((prevState) => ({
      isSSNValid: socialSecurityNoValid !== undefined ? socialSecurityNoValid : prevState.isSSNValid,
      isdobValid: dobValid !== undefined ? dobValid : prevState.isdobValid,
      islastNameValid: lastNameValid !== undefined ? lastNameValid : prevState.islastNameValid,
      istrackingNoValid: trackingNoValid !== undefined ? trackingNoValid : prevState.istrackingNoValid
    }), this.validateForm);
  }

  forEnable = () => {
    if (this.state.dob === '' || this.state.dob === '-0-0' || this.state.dob.length !== 10) {
      this.setState({
        formErrors: {
          cssDob: 'tds-field--error',
          dob: 'Please enter a valid date.',
        },
        isdobValid: false,
      });
      document.getElementById('tbssn').scrollIntoView();
    } if (!this.state.isdobValid) {
      this.setState({
        formErrors: {
          cssDob: 'tds-field--error',
          dob: 'Please enter a valid date.',
        },
        isdobValid: false,
      });
      document.getElementById('tbssn').scrollIntoView();
    }
    if (this.state.ssn === '') {
      this.setState({
        formErrors: {
          cssSsn: 'tds-field--error',
          ssn: 'Cannot be left blank.',
        },
        isSSNValid: false,
      });
      document.getElementById('tblastName').scrollIntoView();
    } else if (!this.state.isSSNValid) {
      this.setState({
        formErrors: {
          cssSsn: 'tds-field--error',
          ssn: 'Please enter valid information.',
        },
        isSSNValid: false,
      });
      document.getElementById('tblastName').scrollIntoView();
    }
    else {
      this.setState({
        formErrors: {
          cssSsn: '',
          ssn: '',
        },
        isSSNValid: true,
      });
    }
    if (this.state.lastName === '') {
      this.setState({
        formErrors: {
          cssLastName: 'tds-field--error',
          lastName: 'Cannot be left blank.',
        },
        islastNameValid: false,
      });
      document.getElementById('tbtrackingNo').scrollIntoView();
    } else if (!this.state.islastNameValid) {
      this.setState({
        formErrors: {
          cssLastName: 'tds-field--error',
          lastName: 'Please enter valid information.',
        },
        islastNameValid: false,
      });
      document.getElementById('tbtrackingNo').scrollIntoView();
    } else {
      this.setState({
        formErrors: {
          cssLastName: '',
          lastName: '',
        },
        islastNameValid: true,
      });
    }
    if (this.state.isInternal) {
      if ((this.state.trackingNo.match(/^[a-zA-Z0-9]{18}$/i) && this.state.trackingNo.length === 18) || (this.state.trackingNo.length === 0)){
        this.setState({
          formErrors: {
            cssTrackingNo: '',
            trackingNo: '',
          },
          istrackingNoValid: true,
        });
      } else if (this.state.trackingNo.length !== 18) {
        this.setState({
          formErrors: {
            cssTrackingNo: 'tds-field--error',
            trackingNo: 'Must be at least 18 characters.',
          },
          istrackingNoValid: false,
        });
        document.getElementById('tb1').scrollIntoView();
      }
      else {
        this.setState({
          formErrors: {
            cssTrackingNo: 'tds-field--error',
            trackingNo: 'Not a valid number.',
          },
          istrackingNoValid: false,
        });
        document.getElementById('tb1').scrollIntoView();
      }
    } else {
      let reg1 = this.state.trackingNo.match(/^[a-zA-Z0-9]{18}$/i);
      if (this.state.trackingNo === '') {
        this.setState({
          formErrors: {
            cssTrackingNo: 'tds-field--error',
            trackingNo: 'Cannot be left blank.',
          },
          istrackingNoValid: false,
        });
        document.getElementById('tb1').scrollIntoView();
      }
      else if (this.state.trackingNo.length !== 18) {
        this.setState({
          formErrors: {
            cssTrackingNo: 'tds-field--error',
            trackingNo: 'Must be at least 18 characters.',
          },
          istrackingNoValid: false,
        });
        document.getElementById('tb1').scrollIntoView();
      }
      else if (this.state.trackingNo !== '' && !reg1) {
        this.setState({
          formErrors: {
            cssTrackingNo: 'tds-field--error',
            trackingNo: 'Not a valid number.',
          },
          istrackingNoValid: false,
        });
        document.getElementById('tb1').scrollIntoView();
      }
      if (!this.state.istrackingNoValid) {
        document.getElementById('tb1').scrollIntoView();
      }
    }
  }

  validateForm() {
    if (this.state.isInternal && this.state.trackingNo === '') {
      return this.state.isSSNValid && this.state.islastNameValid && this.state.isdobValid;
    }
    if (this.state.isInternal && this.state.trackingNo !== '') {
      return this.state.isSSNValid && this.state.islastNameValid && this.state.isdobValid && this.state.istrackingNoValid;
    }
    else {
      return RecaptchaEnabled ?
        this.state.isSSNValid && this.state.islastNameValid && this.state.isdobValid
        && this.state.isCaptchaVerified && this.state.istrackingNoValid
        : this.state.isSSNValid && this.state.islastNameValid && this.state.isdobValid
        && this.state.istrackingNoValid;
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.forEnable();
    if (this.validateForm()) {
      this.continue(event);
    }
  }

  render() {
    const hidden = this.state.isError ? 'tds-inline-alert--error' : 'tds-d-none';
    const { open } = this.state;
    if (this.state.isInternal) {
      url = <div className={`tds-field ${this.state.formErrors.cssTrackingNo}`}>
        <label htmlFor="trackingNo" className={`tds-field__label ${this.state.formErrors.cssTrackingNo}`}>Tracking Number (Optional) </label>
        <div className="tds-iconbutton" aria-label="Help">
          <span className="tds-tooltip__container">
            <span className="icon icon-16 error-16-small">
              <InfoCircle18 className="tds-icon-18" slot="icon-first" />
            </span>
            <div role="tooltip" id="button-description1" className="tds-tooltip">
              This is the unique number assigned to each CCPA request submitted to Travelers.
            </div>
          </span>
        </div>
        <input type="text" className="tds-field__input" onMouseDown={(e) => e.target.focus()}
          name="trackingNo" defaultValue={this.state.trackingNo} id="tbtrackingNo"
          onBlur={this.handleUserInput('trackingNo')}
          onKeyPress={(e) => this.secondMethod(e)}
          onChange={this.leftBlank('trackingNo')}
          minLength="18" maxLength="18"
          aria-describedby="tbtrackingNo_help"
        ></input>
        <div id="tbtrackingNo_help" aria-atomic="true" className="tds-field__message" role="alert">
          <p>{this.state.formErrors.trackingNo}</p>
        </div>
      </div>;
      isRecaptacha = '';
    } else {
      url = <div className={`tds-field ${this.state.formErrors.cssTrackingNo}`}>
        <label htmlFor="trackingNo" className={`tds-field__label ${this.state.formErrors.cssTrackingNo}`}>Tracking Number</label>
        <div className="tds-iconbutton" aria-label="Help">
          <span className="tds-tooltip__container">
            <span className="icon icon-16 error-16-small">
              <InfoCircle18 className="tds-icon-18" slot="icon-first" />
            </span>
            <div role="tooltip" id="button-description1" className="tds-tooltip">
              This is the unique number assigned to each CCPA request submitted to Travelers.
            </div>
          </span>
        </div>
        <input type="text" className="tds-field__input" onMouseDown={(e) => e.target.focus()}
          name="trackingNo" defaultValue={this.state.trackingNo} id="tbtrackingNo" aria-describedby="tbtrackingNo_help"
          onBlur={this.handleUserInput('trackingNo')}
          onKeyPress={(e) => this.secondMethod(e)}
          onChange={this.leftBlank('trackingNo')}
          minLength="18" maxLength="18"
        ></input>
        <div id="tbtrackingNo_help" aria-atomic="true" className="tds-field__message" role="alert">
          <p>{this.state.formErrors.trackingNo}</p>
        </div>
      </div>;
    }
    return (
      <div className="tds-container tds-col-md-5">
        <div className={hidden}><b>WE CAN'T FIND YOUR REQUEST</b>
          We were not able to find your request with the
          information provided below. Please check your
          entries and try again.
        </div>
        <form method="POST" onSubmit={this.handleSubmit} >
          <h1 className="tds-h1--small">First, we'll need to locate your request</h1>
          <div id="tb1"></div>
          {url}
          <div className={`tds-field ${this.state.formErrors.cssLastName}`}>
            <label htmlFor="lastName" className={`tds-field__label ${this.state.formErrors.cssLastName}`}>Last Name</label>
            <input type="text" className="tds-field__input" name="lastName" maxLength="30"
              onBlur={this.handleUserInput('lastName')} onMouseDown={(e) => e.target.focus()}
              onKeyPress={(e) => this.thirdMethod(e)} id="tblastName"
              onChange={this.leftBlank('lastName')}
              defaultValue={this.state.lastName}
              aria-describedby="tblastName_help" />
            <div id="tblastName_help" aria-atomic="true" className="tds-field__message" role="alert">
              <p>{this.state.formErrors.lastName}</p>
            </div>
          </div>
          <div className="tds-row">
            <div className="tds-col">
              <div className={`tds-field ${this.state.formErrors.cssSsn}`}>
                <label htmlFor="ssn" className={`col-sm-4 control-label fitContent tds-field__label ${this.state.formErrors.cssSsn}`}>SSN (Last 4 Digits)</label>
                <div className="col-sm-7 col-10">
                  <input type="text" className="form-control tds-field__input" name="ssn"
                    maxLength="4"
                    defaultValue={this.state.ssn}
                    onBlur={this.handleUserInput('ssn')} onMouseDown={(e) => e.target.focus()}
                    onKeyPress={(e) => this.firstMethod(e)} id="tbssn"
                    onChange={this.leftBlank('ssn')}
                    aria-describedby="tbssn_help" />
                  <div id="tbssn_help" aria-atomic="true" className="tds-field__message" role="alert">
                    <p>{this.state.formErrors.ssn}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="tds-col">
            <div className="tds-col">
              <fieldset className={`${this.state.formErrors.cssDob}`}>
                <legend className={`tds-field__label-date-time-input fitContent ${this.state.formErrors.cssDob}`}>Date Of Birth</legend>
                <div className="tds-date-input" id="date-input-0">
                  <span className="tds-date-input__date-part">
                    <input name="month" type="text" inputMode="numeric" pattern="\d*"
                      placeholder="mm" minLength="2" maxLength="2" data-date-part="month" aria-label="Month"
                      id="month" defaultValue={this.state.dobMonth} onBlur={this.handleUserInput('dob')} onMouseDown={(e) => e.target.focus()}
                      onKeyPress={(e) => this.firstMethod(e)} onChange={this.leftBlank('dob')} />
                  </span>
                  <span className="tds-date-input__date-part">
                    <input name="day" type="text" inputMode="numeric" pattern="\d*"
                      placeholder="dd" minLength="2" maxLength="2" data-date-part="day" aria-label="Day" id="day"
                      defaultValue={this.state.dobDay} onBlur={this.handleUserInput('dob')} onMouseDown={(e) => e.target.focus()}
                      onKeyPress={(e) => this.firstMethod(e)} onChange={this.leftBlank('dob')} />
                  </span>
                  <span className="tds-date-input__date-part">
                    <input name="year" type="text" inputMode="numeric" pattern="\d*"
                      placeholder="yyyy" minLength="4" maxLength="4" data-date-part="year" aria-label="Year" id="year"
                      defaultValue={this.state.dobYear} onBlur={this.handleUserInput('dob')} onMouseDown={(e) => e.target.focus()}
                      onKeyPress={(e) => this.firstMethod(e)}
                      onChange={this.leftBlank('dob')} />
                  </span>
                </div>
                <div className="tds-field__message">
                  <p>{this.state.formErrors.dob}</p>
                </div>
              </fieldset>
            </div>
            </div>
          </div>
          {!this.state.isInternal && RecaptchaEnabled ?
            <div className="tds-button-container">
              <Recaptcha
                sitekey={this.props.reCatchaKey}
                render="explicit"
                onloadCallback={this.recaptchaloaded}
                verifyCallback={this.verifyCallback} />
            </div>
            :
            <div>  </div>
          }
          <div className="tds-button-container" >
            <button type="submit" onClick={this.handleSubmit} className="tds-button--primary">CONTINUE
            </button>
            <main>
              <button type="button" className="tds-button--tertiary" onClick={this.onOpenModal}>CANCEL
              </button>
              <Modal open={open}
                onClose={this.onCloseModal}
                center
                showCloseIcon={true}>
                <WarningModal
                  onCloseModal={this.onCloseModal.bind(this)}
                  navigateToHome={this.navigate.bind(this)} />
              </Modal>
            </main>
          </div>
        </form>
      </div>
    );
  }
}
export default ViewStatus;
