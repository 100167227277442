import React, { Component } from 'react';
import symsprt_logo from '@trv-ebus/tds-icons/dist/assets/icons/logos/symbol-sprites.svg';
import Image from '../../assets/images/trav-logo.png';
import 'svgxuse';



export class Header extends Component {
    render() {
        return (
            <header className="tds-global-header" role="banner">
            <div className="tds-global-header__wrapper">
              <div className="tds-global-header__identity-block">
                <div className="tds-global-header__identity">
                  <a href="/" aria-label="home" title="Home">
                    <svg className="tds-global-header__logo" role="img" focusable="false">
                    <use xlinkHref={symsprt_logo + '#trv-logo-2color-small'}/>
                    </svg>
                  </a>
                  <div className="tds-global-header__product">California Consumer Privacy Act</div>
                </div>
              </div>
              <div className="tds-global-header__menu-wrapper">
                <div className="tds-global-header__actions-block">
                  <div className="tds-global-header__actions-item tds-action-icon-wrapper">
                   </div>
                </div>
              </div>
            </div>
          </header>
        );
    }
}

export default Header;
