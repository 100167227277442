import React, { Component } from 'react';
import ReferenceService from '../components/api/referenceService.js';

export class thankYou extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disclosureLink: '',
		};
	}
	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
		let response = await ReferenceService.configs();

		this.setState({
			disclosureLink: response.disclosureLink

		});
	}

	navigate = () => {
		this.props.navigateToHome();
	}

	handleCheck = (event) => {
		event.preventDefault();
		this.props.navigateToHome();
	}

	render() {
		return (
			<div className="tds-container tds-col-md-5">
				<h1 className="tds-h1--small">Thank you for your interest!</h1>
				<p>
					For additional information on your rights under the CCPA, please see the &nbsp;
					<a href={this.state.disclosureLink} target="_blank"> California Consumer Privacy Act Disclosure.
					</a>
				</p>
				<div className="tds-button-container tds-mt-xxl" id="tds-button--primary">
					<button type="button" className="tds-button--primary" onClick={this.handleCheck}>DONE</button>
				</div>
			</div>
		);
	}
}
export default thankYou;
