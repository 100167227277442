import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router';
import ReferenceService from '../components/api/referenceService.js';
import CustomerRequestService from '../components/api/customerRequest';
import { createLogger } from '@trv-ent-web/logging';
let logger = createLogger({ name: 'CPA Diagnosis log: Infinite Delete request page logger' });
var dateFormat = require('dateformat');

export class InfiniteDelete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disclosureLink: '',
			isGoingX: false,
			isGoingZ: false,
			isRequestToAccess: false,
			isRequestToDelete: false,
			trackingNo: '',
			yes: ''
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
		let response = await ReferenceService.configs();
		this.setState({
			disclosureLink: response.disclosureLink
		});
	}

	continue = async (e) => {
		e.preventDefault();
		const data = {
			checked: this.props.checked,
			deleteOnly: this.state.isGoingX,
			selected: this.props.selected,
			thankYou: this.state.isGoingZ,
			value: this.props.values
		};

		// calling the api to customer request submit
		logger.info('CPA Diagnosis log: On click of next on Infinite Delete page');
		try {
			if (!document.getElementById('isGoingX').selected && document.getElementById('isGoingZ').selected) {
				this.props.navigateToThankYou();
			}
			else {
				const response = await CustomerRequestService.customerRequestSubmit(data);
				if (response.data != null && document.getElementById('isGoingX').selected && !document.getElementById('isGoingZ').selected) {

					this.props.navigateToDeletedTrackingConfirmation(response?.data.CPA_CUST_REQUEST_ID);
				}
			}
		}
		catch (e) {
			console.log('duplicate page - catch:', e);
			this.props.navigateToServiceDown();
		}
	};

	back = (e) => {
		e.preventDefault();
		this.props.navigateToRelWithTrav();
	};

	navigate = () => {
		this.props.navigateToHome();
	}

	handleCheck = (event) => {
		event.preventDefault();
		if (this.props.values.isGoingX === false && this.props.values.isGoingZ === false) {
			window.scrollTo(0, 0);
			document.getElementById('tdError').classList.remove('tds-d-none');
			document.getElementById('tdError').classList.add('tds-inline-alert--error');
			document.getElementById('tdError').innerHTML = 'Please select at least one.';
			var elems = document.querySelectorAll('.tds-check--info');
			[].forEach.call(elems, function (el) {
				el.className = el.className.replace(/\btds-check--info\b/, 'tds-check--error');
			});

		}
		else if ((this.props.values.isGoingX === true || this.props.values.isGoingZ === false) || (this.props.values.isGoingX === false || this.props.values.isGoingZ === true)){
			document.getElementById('tdError').innerHTML = '';
			document.getElementById('tdError').classList.remove('tds-inline-alert--error');
			this.continue(event);
		}
		return this.props.values.isGoingX || this.props.values.isGoingZ;
	}

	handleSubmit() {
		this.props.navigateToHome();

	}

	render() {
		const { values } = this.props;
		const ReqId = this.props.latestTrackingNumber;
		const secondLatestReqNumber = this.props.secondTrackingNumber;
		const SubmittedDate = this.props.SubmittedDate;
		const secondLatestReqSubmittedDate = this.props.secondSubmittedDate;
		const requestType = this.props.requestType;
		return (
			<div className="tds-container tds-col-md-5">
				<div className="tds-alert--warning">
					<span>You've <b><u>already submitted 2 access</u></b> requests in the last 12 months.</span>
					<span>Here is your recent Tracking Number:</span>
					<p>
						<span className="blueColor">
							{ReqId}
						</span> {dateFormat(SubmittedDate, 'mm/dd/yyyy')} </p>
				</div>
				<div className="tds-inline-alert--error tds-d-none" id="tdError"></div>
				<h4>Step 4 out of 4</h4>
				<div className="form-group">
					<h1 className="tds-h1--small">Do you want to submit a delete request?</h1>
					<div>
						<fieldset role="group" className="fieldset-bottom">
							<label className="tds-check tds-check--info">
								<input id="isGoingX" type="radio" name="radio"
									checked={values.isGoingX}
									selected={values.isGoingX}
									onChange={this.props.handleInputChange2} />
								<span className={['tds-check__label']}> Yes</span>
							</label>
							<label className="tds-check">
								<input id="isGoingZ" type="radio" name="radio"
									checked={values.isGoingZ}
									selected={values.isGoingZ}
									onChange={this.props.handleInputChange2} />
								<span className={['tds-check__label']}> No </span>
							</label>
							<hr className="tds-mt-xl" />
						</fieldset>
					</div>
				</div>
				<p className="tds-left tds-small"> For additional information on your rights under the CCPA, please see the
					<a className="textDecNone" href={this.state.disclosureLink} target="_blank"> California Consumer Privacy Act Disclosure.</a>
					<p className="tds-mt-xl">By submitting this request, I declare under penalty of perjury that all information provided is true and correct. </p>
				</p>
				<div className="tds-button-container tds-mt-xxl" id="tds-button--primary">
					<button type="button" className="tds-button--primary" onClick={this.handleCheck}>NEXT</button>
					<button type="button" className="tds-button--tertiary" onClick={this.back}>BACK</button>
				</div>
			</div>
		);
	}
}
export default withRouter(InfiniteDelete);

