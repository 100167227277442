import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router';
var dateFormat = require('dateformat');

export class AccessRequest extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.print = this.print.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Consumer Privacy | Travelers Insurance';
    }
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    handleSubmit() {
        this.props.navigateToHome();
    }
    print() {
        window.print();
    }

    render() {

        if (this.props.location === undefined) {
            return (<Redirect to="/" />);
        }
        const ReqId = this.props.trackingNo;
        const SubmissionDate = this.props.trackingNoSubmissionDate;
        const type = this.props.requestType;

        return (

            <div className="tds-container tds-col-md-5">
                <div id="printDivConfirm">
                    <h1 className="tds-h1--small">Your request for access to your data has been submitted. </h1>
                    <p className="tds-large">You should receive a response to your request within 45 days.
                        If we require additional time to respond, we will notify you via email.
                    </p>
                    <div className="tds-requestConfirmationPage">
                        <h3>For your reference, here is your Tracking Number:</h3>
                        <div className="tds-col-6">
                            <h4 className="tds-mt-m">Tracking Number</h4>
                            <div className="tds-large">{ReqId}</div>
                        </div>
                        <div className="tds-col-6">
                            <h4 className="tds-mt-m">Type</h4>
                            <div className="tds-large">{type}</div>
                        </div>
                    </div>
                    <p className="tds-large tds-mt-xl">Thank you for your inquiry.</p>
                    <div className="tds-button-container tds-mt-xxl" id="react-no-print">
                        <button type="button" className="tds-button--primary" onClick={this.handleSubmit}>DONE</button>
                        <button type="button" className="tds-button--tertiary" onClick={this.print}>PRINT</button>
                    </div>
                </div>
            </div>
        );
    }
}
export default withRouter(AccessRequest);
