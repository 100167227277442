import React, { Component } from 'react';
import symsprt_logo from '@trv-ebus/tds-icons/dist/assets/icons/logos/symbol-sprites.svg';
import ReferenceService from '../../components/api/referenceService';


export class Footer extends Component {

  constructor(props) {
    super(props);
    this.state ={
      footerLinks :[]
    };
  }

  async componentDidMount() {

    let response = await ReferenceService.configs();
    this.setState({
      footerLinks: response.footer
    });

	
	}

  render() {
    return (
      <footer className="tds-global-footer" role="contentinfo">
        <div className="tds-global-footer__wrapper">
          <div className="tds-global-footer__identity">
          <svg className="tds-global-footer__logo" role="img" focusable="false" aria-label="Travelers logo">
          <use xlinkHref={symsprt_logo + '#trv-logo-2color-small'}/>
        </svg>
            <p className="tds-global-footer__copy">
              &copy; {new Date().getFullYear()} The Travelers Indemnity Company. All rights reserved.
            </p>
          </div>
          <div className="tds-global-footer__links">
            <ul>
              <li onClick={this.OpenInNewTabWinBrowser}>
             
                <a id="termsofservice" className="tds-global-footer__link-item"
                  href={this.state.footerLinks.TRAVELERS_LEGAL_URL} target="_blank">
                  Terms of Service
                </a>
              </li>
              <li onClick={this.OpenInNewTabWinBrowser}>
                <a id="privacyandsecurity" className="tds-global-footer__link-item"
                  href={this.state.footerLinks.TRAVELERS_PRIVATE_STATEMENT} target= "_blank">
                  Privacy &amp; Security
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
