import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router';
import AcxiomService from '../components/api/identityVerification';
import WarningModal from './warningModal.js';
import Modal from 'react-responsive-modal';
import { createLogger } from '@trv-ent-web/logging';
import { Button } from 'trv-tds-react';

let logger = createLogger({ name: 'Acxiom Quiz UI logger' });

export class Acxiom extends Component {
  constructor(...props) {
    super(...props);
    this.continue = this.continue.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  state = {
    busy: false,
    canOnlyDelete: '',
    choices_five: '',
    choices_four: '',
    choices_one: '',
    choices_three: '',
    choices_two: '',
    counter: 1,
    examUuId: this.props.acxiomResponse?.examUuid,
    isChecked: false,
    isQuestionOneChecked: false,
    isQuestionThreeChecked: false,
    isQuestionTwoChecked: false,
    isSecondAttempt: false,
    open: false,
    postssn: this.props.postssn,
    questionId_five: '',
    questionId_four: '',
    questionId_one: '',
    questionId_three: '',
    questionId_two: '',
    questionOneError: '',
    questionSetId: this.props.acxiomResponse?.questionSetId,
    token: this.props.acxiomResponse?.accessToken

  };

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = 'Consumer Privacy | Travelers Insurance';
  }

  navigate = () => {
    this.props.navigateToHome();
    this.setState((prevState) => ({
      show: false,
      step: prevState.step - 1
    }));
    this.props.history.replace('/');
  }

  continue = async (e) => {
    e.preventDefault();
    this.setState({
      busy: true
    }, () => {
    });

    setTimeout(() => {
      this.setState({
        busy: false
      });
    }, 3000);


    const acxiomResponse = await AcxiomService.getAcxiomScore(this.state);
    
    if (acxiomResponse?.data?.passed) {
      if (acxiomResponse?.data?.canOnlyDelete) {
        this.setState({
          canOnlyDelete: acxiomResponse?.data?.canOnlyDelete
        });
      }
      this.props.navigateToStep2(acxiomResponse?.data?.canOnlyDelete);
    }
    else if (acxiomResponse != null && acxiomResponse.data != null && acxiomResponse.data.error) {
      logger.info('TIME OUT FROM ACXIOM SCORE RESPONSE');
      this.props.navigateToIdentityFailure(acxiomResponse.data.error.code, acxiomResponse.data.statusCode);
    }
    else if (acxiomResponse != null && acxiomResponse.data != null && acxiomResponse.data.error != undefined) {
      this.props.navigateToIdentityFailure(acxiomResponse.data.error.code, acxiomResponse.data.statusCode);
    }
    else if (this.state.counter < 2) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 3500);
      this.setState((prevState) => ({
        choices_five: '',
        choices_four: '',
        choices_one: '',
        choices_three: '',
        choices_two: '',
        counter: prevState.counter + 1,
        isSecondAttempt: true,
        questionId_five: '',
        questionId_four: '',
        questionId_one: '',
        questionId_three: '',
        questionId_two: '',
      }));
      const acxiomResponse = await AcxiomService.getAcxiomToken(this.props.values);
      if (acxiomResponse != null && acxiomResponse.data != null && acxiomResponse.data.error != undefined) {
        this.props.navigateToIdentityFailure(acxiomResponse.data.error.code, acxiomResponse.data.statusCode);
      }
      else {
        let accessToken = acxiomResponse?.headers?.authorization;
        this.props.navigateToAcxiom(acxiomResponse?.data, accessToken, this.state.postssn);
      }
    }
    else {
      this.props.navigateToIdentityFailure('200-4303', '403');
    }
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  onOpenModal = (e) => {
    e.preventDefault();
    this.setState({ open: true }
    );
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  handleQuestion = (e) => {
    const target = e.target;
    const value = target.value;
    const answer_array = value.split('_');
    document.getElementById(`choicesError_${answer_array[3]}`).classList.remove('tds-check-group--error');
    document.getElementById(`choicesError_${answer_array[3]}`).classList.remove('tds-field--error');
    document.getElementById(`quesError_${answer_array[3]}`).innerHTML = '';

    if (answer_array[2] === '0') {
      this.setState({
        choices_one: answer_array[5],
        questionId_one: answer_array[3]
      });
    }

    if (answer_array[2] === '1') {
      this.setState({
        choices_two: answer_array[5],
        questionId_two: answer_array[3]
      });
    }

    if (answer_array[2] === '2') {
      this.setState({
        choices_three: answer_array[5],
        questionId_three: answer_array[3]
      });
    }

    if (answer_array[2] === '3') {
      this.setState({
        choices_four: answer_array[5],
        questionId_four: answer_array[3]
      });
    }

    if (answer_array[2] === '4') {
      this.setState({
        choices_five: answer_array[5],
        questionId_five: answer_array[3]
      });
    }
  }

  validateForm(e) {
    let arrayOfIsTrue = [this.props.acxiomResponse?.questions.length];
    for (var i = 0; i < this.props.acxiomResponse?.questions.length; i++) {
      for (var j = 0; j < this.props.acxiomResponse?.questions[i].choices.length; j++) {
        let isCheckd = false;
        if (document.getElementById(`tbChoices_${i}_${this.props.acxiomResponse?.questions[i].choices[j].choice}`).checked) {
          isCheckd = true;
        }
        arrayOfIsTrue[i] = isCheckd;
        if (!isCheckd) {
          document.getElementById(`choicesError_${this.props.acxiomResponse?.questions[i].questionUuid}`).className = 'tds-check-group--error tds-field--error';
          document.getElementById(`quesError_${this.props.acxiomResponse?.questions[i].questionUuid}`).innerHTML = 'Please select one.';
        }
        else {
          document.getElementById(`choicesError_${this.props.acxiomResponse?.questions[i].questionUuid}`).classList.remove('tds-check-group--error');
          document.getElementById(`choicesError_${this.props.acxiomResponse?.questions[i].questionUuid}`).classList.remove('tds-field--error');
          document.getElementById(`quesError_${this.props.acxiomResponse?.questions[i].questionUuid}`).innerHTML = '';
          break;
        }
        if (isCheckd) {
          break;
        }
      }
    }
    let isFormValid = true;
    for (i = 0; i < this.props.acxiomResponse?.questions.length; i++) {
      if (!arrayOfIsTrue[i]) {
        isFormValid = false;
        document.getElementById(`eachQuestion_${i}`).scrollIntoView();
        break;
      }
    }
    if (isFormValid) {
      this.continue(e);
    }
  }
  render() {
    const busy = this.state.busy;
    const { open } = this.state;
    const isSeondAttempt = this.state.isSecondAttempt ? 'tds-inline-alert--error' : 'tds-d-none';
    if (this.state.counter === 1) {
      const displayQustions = this.props.acxiomResponse?.questions.map((ques, index) => {
        return (
          <div key={ques.questionUuid} id={`eachQuestion_${index}`} >
            <div className="acxiom-ques-spacing"><input type="hidden" values={ques.questionUuid} id={`question_${index}`} /><h3>{index + 1}. {ques.text}</h3></div>
            <div className="" id={`choicesError_${ques.questionUuid}`}>
              {ques.choices.map((ch, i) => {
                return (<label className="tds-check" key={ch.choice}>
                  <input type="radio" name={`radio_${ques.questionUuid}`}
                    value={`choice_${`question_${index}`}_${ques.questionUuid}_${i}_${ch.choiceId}`}
                    onChange={this.handleQuestion} id={`tbChoices_${index}_${ch.choice}`} />
                  <span className="tds-check__label">{ch.choice}</span>
                </label>);
              })}
              <div aria-atomic="true" aria-live="assertive" className="tds-field__message" role="alert" id={`quesError_${ques.questionUuid}`}>
              </div>
            </div>
          </div>
        );
      });

      return (<div className="tds-container tds-col-md-5">
        <div className="tds-mt-xl">
          <h4>Step 2 out of 4</h4>
          <h1 className="tds-h1--small">Verify your identity by answering the following  questions.</h1>
          <p>The answers are used only to verify your identity. We will not retain this data, or use it for other purposes.</p>
          <div>{displayQustions}</div>
          <div className="tds-button-container tds-mt-xxxl">
            <button type="button" className="tds-button--primary"
              onClick={this.validateForm}>CONTINUE</button>
            <button type="button" className="tds-button--tertiary" onClick={this.onOpenModal}>CANCEL</button>
            <Modal open={open}
              onClose={this.onCloseModal}
              center
              showCloseIcon={true}
            >
              <WarningModal
                onCloseModal={this.onCloseModal.bind(this)}
                navigateToHome={this.navigate.bind(this)}
              />
            </Modal>
          </div>
        </div>
      </div>
      );
    }

    if (this.state.counter === 2) {
      let displayQustions = this.props.acxiomResponse?.questions.map((ques, index) => {
        return (
          <div key={ques.questionUuid} id={`eachQuestion_${index}`}>
            <div className="acxiom-ques-spacing"><input type="hidden" values={ques.questionUuid} id={`question_${index}`} /><h3>{index + 1}. {ques.text}</h3></div>
            <div className="" id={`choicesError_${ques.questionUuid}`}>
              {ques.choices.map((ch, i) => {
                return (<label className="tds-check" key={ques.questionUuid}>
                  <input type="radio" name={`radio1111_${ques.questionUuid}`}
                    value={`choice_${`question_${index}`}_${ques.questionUuid}_${i}_${ch.choiceId}`}
                    onChange={this.handleQuestion} id={`tbChoices_${index}_${ch.choice}`} />
                  <span className="tds-check__label">{ch.choice}</span>
                </label>);
              })}
              <div className="tds-field__message--error" id={`quesError_${ques.questionUuid}`}></div>
            </div>
          </div>
        );
      });
      return (<div className="tds-container">
        <div className="tds-row">
          <div className="tds-col-5 tds-offset-4">
            <div id="tbAcxiomError" className={isSeondAttempt}>
              You've failed to verify your identity. You have 1 out of 2 attempts remaining.
            </div>
            <h4>Step 2 out of 4</h4>
            <h1 className="tds-h1--small">Verify your identity by answering the following questions.</h1>
            <p>The answers are used only to verify your identity. We will not retain this data and cannot access or view your answers.</p>
            <div>{displayQustions}</div>
            <div className="tds-button-container tds-mt-xxxl">
              {busy ? (
                <div >
                  <div >
                    <Button
                      className="tds-button--primary"
                      variant="primary"
                      disabled={false}
                      small={false}
                      busy
                      onClick={this.validateForm}
                    >
                      CONTINUE
                    </Button>
                  </div>
                </div>
              ) : (
                <div >
                  <div >
                    <Button
                      className="tds-button--primary"
                      variant="primary"
                      disabled={false}
                      small={false}
                      busy={false}
                      onClick={this.validateForm}
                    >
                      CONTINUE
                    </Button>
                  </div>
                </div>
              )}
              <main>
                <button type="button" className="tds-button--tertiary" onClick={this.onOpenModal}>CANCEL</button>
                <Modal open={open}
                  onClose={this.onCloseModal}
                  center
                  showCloseIcon={true}
                >
                  <WarningModal
                    onCloseModal={this.onCloseModal.bind(this)}
                    navigateToHome={this.navigate.bind(this)}
                  />
                </Modal>
              </main>
            </div>
          </div>
        </div>
      </div>
      );
    }
  }
}

export default withRouter(Acxiom);
