import React from 'react';
import { withRouter } from 'react-router';

class HomePage extends React.Component {
	state = {
		step: 7
	};
	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
	}
	continue = e => {
		e.preventDefault();
		this.props.nextStep();
	};
	viewStatus = e => {
		e.preventDefault();
		this.props.navigateToViewStatusRequest();
	};

	render() {
		return (
			<div className="tds-container tds-col-md-5">

				<div className="tds-mt-xl">
					<h1 className="tds-h1--small ">California Consumer Privacy Act Requests</h1>
					<p>
						If you are a California resident, you have the right to request access to the data that Travelers has collected about you in
						the last 12 months, pursuant to the California Consumer Privacy Act (CCPA). You also have the right to request that Travelers
						delete your personal information, and to ask that Travelers correct any of your personal information that is inaccurate.
					</p>
					<h1 className="tds-h1--small">Access and Deletion Requests</h1>
					<p>
						In order for us to process your request to access your personal information, or to delete your personal information,
						we need to collect certain information from you to allow us to search our records.
						It should take approximately 15 minutes for you to complete the request form. In addition to supplying certain identifying information,
						you may be asked for additional information, depending on your relationship with the company.
					</p>
					<p className="tds-bold">For example:</p>
					<ul className="tds-ul">
						<li className="tds-li">Account Number</li>
						<li className="tds-li">Billing Number</li>
						<li className="tds-li">Claim Number</li>
						<li className="tds-li">National Producer Code</li>
					</ul>
					<div className="tds-button-container">
					<div className="tds-centered">
						<button type="button" className="tds-button--primary tds-col-md-8" onClick={this.continue}>Begin Access or Deletion Request</button>
					</div>
					</div>
					<div className="tds-my-l">
						<h1 className="tds-h1--small">Correction Requests</h1>
						<p className="tds-bold">View status of your request or to submit a correction request.</p>
					</div>
					<div className="tds-button-container">
					<div className="tds-centered">
						<button type="button" className="tds-button--primary tds-col-md-8" onClick={this.viewStatus}>View Status or Correction Request</button>
					</div>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(HomePage);
