import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

export class WarningModal extends Component {
	constructor(props) {
		super(props);
		this.navigate = this.navigate.bind(this);
	}
	hideModal = () => {
		this.props.onCloseModal();
	}

	navigate = () => {
		this.props.navigateToHome();
	}

	render() {
		return (
			<div role="dialog" className="tds-modal tds-modal__dialog--small">
				<div role="document" className="tds-modal__dialog-container" aria-labelledby="my-modal-heading-md"
					aria-describedby="my-modal-description-md">
					<div className="tds-modal__dialog-header">
						<div role="alert">
						<h2 className="tds-modal__dialog-title">Are you sure you want to leave?</h2>
						</div>
					</div>
					<div className="tds-modal__dialog-body">
						<div className="tds-modal__dialog-body-content">
							
							<p className="tds-centered">You'll lose the information you provided.</p>
						</div>
					</div>
					<div className="tds-modal__dialog-footer">
						<div className="tds-modal__dialog-button-container">
							<div className="tds-modal__dialog-button-layout">
								<button className="tds-button--primary tds-button--small" onClick={this.hideModal}>STAY</button>
								<button className="tds-button--secondary tds-button--small" onClick={this.navigate}>LEAVE</button>
							</div>
						</div>
					</div>
					<button aria-label="Dismiss dialog" className="tds-modal__dismiss" type="button" onClick={this.hideModal}><svg focusable="false"><use href="/assets/icons/functional/symbol-sprites.svg#x-1-12"></use>
						<svg id="x-1-12" viewBox="0 0 36 36">
							<path d="M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z" fill="currentColor">
							</path>
						</svg>
					</svg></button>
				</div>
			</div>
		);
	}
};
export default withRouter(WarningModal);


