import React from 'react';
import Routes from './routes';
import 'react-app-polyfill/ie11'; 
import { Footer } from './components/common/Footer';
import { Header } from './components/common/Header';
import './styles/app.scss';
import 'svgxuse';
import { Security  } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import { useHistory } from 'react-router-dom';
import clientConfig from './utilities/clientConfig';

function App() {
  let config = clientConfig.getClientConfig();
  const history = useHistory();
  if(config.internal === 'true'){
    const oktaConfig = config.oktaConfig;
    const oktaAuth = new OktaAuth(oktaConfig);

    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
      history.push(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    return(
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <div>
          <Header/> 
          <div className="tds-body tds-mt-xxxl">
            <Routes/>
          </div>
          <Footer/>
        </div>
      </Security>
    );
  } else{
    return(
      <div>
          <Header/> 
          <div className="tds-body tds-mt-xxxl">
            <Routes/>
          </div>
          <Footer/>
        </div>
    );
  }
}

export default App;
