import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ReferenceService from '../components/api/referenceService.js';
import CustomerRequestService from '../components/api/customerRequest';
import { createLogger } from '@trv-ent-web/logging';
import { Button } from 'trv-tds-react';
let logger = createLogger({ name: 'CPA Diagnosis log: Access or Delete page logger' });
export class AccessOrDelete extends Component {

	constructor(props) {
		super(props);

		this.state = {
			busy: false,
			disclosureLink: '',
			isGoing: false,
			isGoingY: false,
			isRequestToAccess: false,
			isRequestToDelete: false,
			trackingNo: '',
		};

	}
	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
		let response = await ReferenceService.configs();

		this.setState({
			disclosureLink: response.disclosureLink
		});
	}
	navigate = () => {
		this.props.navigateToHome();
	}

	onOpenModal = (e) => {
		e.preventDefault();
		this.setState({ open: true }
		);
	};
	onCloseModal = () => {
		this.setState({ open: false });
	};

	showModal = () => {
		this.setState({ show: true });
	};

	hideModal = () => {
		this.setState({ show: false });
	};

	continue = async (e) => {
		e.preventDefault();
		logger.info('CPA diagnosis log: On click of continue on Access or Delete page');
		this.setState({
			busy: true
		}, () => {
			console.log('continue button clicked after', this.state.busy);
		});

		setTimeout(() => {
			this.setState({
				busy: false
			});
		}, 3000);
		// calling the api to customer request submit

		const data = {
			accessTo: this.state.isGoing,
			checked: this.props.checked,
			deleteTo: this.state.isGoingY,
			value: this.props.values
		};
		try {
			const response = await CustomerRequestService.customerRequestSubmit(data);
			if (response.data != null && response?.data.length === 2 && response?.data.name !== 'RequestError') {
				this.props.navigateToAccessDeleteRequest(response.data);
			}
			else if (document.getElementById('isGoing').checked && !document.getElementById('isGoingY').checked && response.data.name !== 'RequestError') {
				this.props.navigateToTrackingConfirmation(response?.data.CPA_CUST_REQUEST_ID);
			}
			else if (!document.getElementById('isGoing').checked && document.getElementById('isGoingY').checked && response.data.name !== 'RequestError') {
				this.props.navigateToDeletedTrackingConfirmation(response?.data.CPA_CUST_REQUEST_ID);
			}
		}

		catch (e) {
			this.props.navigateToServiceDown();
		}
	};

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};

	handleCheck = (event) => {
		event.preventDefault();

		if (this.props.values.isGoing === false && this.props.values.isGoingY === false) {
			window.scrollTo(0, 0);
			document.getElementById('tdError').classList.remove('tds-d-none');
			document.getElementById('tdError').classList.add('tds-inline-alert--error');
			document.getElementById('tdError').innerHTML = 'Please select at least one.';

			var elems = document.querySelectorAll('.tds-check--info');
			[].forEach.call(elems, function (el) {
				el.className = el.className.replace(/\btds-check--info\b/, 'tds-check--error');
			});

		}
		else if ((this.props.values.isGoing === true || this.props.values.isGoingY === false) || (this.props.values.isGoing === false || this.props.values.isGoingY === true)){
			document.getElementById('tdError').innerHTML = '';
			document.getElementById('tdError').classList.remove('tds-inline-alert--error');
			this.continue(event);
		}
	}

	render() {
		const busy = this.state.busy;
		const { values } = this.props;
		const bg = {
			overlay: {
				'box-shadow': 'none'
			}
		};
		const { open } = this.state;

		return (

			<div className="tds-container tds-col-md-5">

				<div className="tds-inline-alert--error tds-d-none" id="tdError"></div>
				<h4>Step 4 out of 4</h4>
				<div className="form-group">
					<h1 className="tds-h1--small">Do you want to request access to or deletion of your data?</h1>
					<p>(Choose at least one)</p>

					<div>
						<fieldset className="fieldset-bottom" role="group">
							<label className="tds-check tds-check--info">
								<input name="isGoing" type="checkbox"
									checked={values.isGoing} id="isGoing"
									onChange={this.props.handleInputChange} />
								<span className={['tds-check__label']}> I'm requesting <b>access</b> to my data</span>
							</label>

							<label className="tds-check tds-check--info">
								<input name="isGoingY" type="checkbox"
									checked={values.isGoingY} id="isGoingY"
									onChange={this.props.handleInputChange} />
								<span className={['tds-check__label']}> I'm requesting <b>deletion</b> of my data</span>
							</label>
						</fieldset>
					</div>
					<span>
						<p> For additional information on your rights under the CCPA, please see the
							<a className="textDecNone"
								href={this.state.disclosureLink} target="_blank"> California Consumer Privacy Act Disclosure.
							</a></p>
						<p className="tds-small tds-mt-xxl">By submitting this request, I declare under penalty of perjury that I am the person whose information is the subject of this request, and all information provided is true and correct.</p>
					</span>
				</div>
				<div className="tds-button-container tds-mt-xxl">
					{busy ? (
								<Button
									className="tds-button--primary"
									variant="primary"
									disabled={false}
									small={false}
									busy
									onClick={this.handleCheck}
								>
									SUBMIT REQUEST
								</Button>
					) : (
								<Button
									className="tds-button--primary"
									variant="primary"
									disabled={false}
									small={false}
									busy={false}
									onClick={this.handleCheck}
								>
									SUBMIT REQUEST
								</Button>
					)}

					<main>
						<button type="button" className="tds-button--tertiary" onClick={this.back}>BACK</button>
					</main>
				</div>
			</div>

		);
	}
}
export default withRouter(AccessOrDelete);
