import httpClient from '../../utilities/httpClient';

let client = httpClient.createClient();

class CustomerRequestApi {
    
    static async checkRequestCount(data, isForDataOnly) {
        const requestData = {
            isForDataOnly: isForDataOnly || false,
            recaptchaToken: data.recaptchaToken ? data.recaptchaToken : '',
            ssn: data.ssn
        };
        var response = await client.post('/api/customer-request/check-reqcount', requestData);
        return response;
    };
    static async customerRequestSubmit(data) {
        let requestData = {
            accessTo: data.accessTo,
            checked: {},
            deleteOnly: data.deleteOnly,
            deleteTo: data.deleteTo,
            selected: {},
            thankYou: data.thankYou,
            value: {}
        };
        if (data.value) {
            requestData.value.firstName = data.value.firstName;
            requestData.value.lastName = data.value.lastName;
            requestData.value.phoneNo = data.value.phoneNo;
            requestData.value.ssn = data.value.ssn;
            requestData.value.address = data.value.address;
            requestData.value.zipCode = data.value.zipCode;
            requestData.value.city = data.value.city;
            requestData.value.email = data.value.email;
            requestData.value.dob = data.value.dob;
            requestData.value.apt = data.value.apt;
            requestData.value.isGoing = data.value.isGoing;
            requestData.value.isGoingX = data.value.isGoingX;
            requestData.value.isGoingY = data.value.isGoingY;
            requestData.value.isGoingZ = data.value.isGoingZ;
            requestData.value.isNoneOfThese = data.value.isNoneOfThese;
            requestData.value.textAreatravID = data.value.textAreatravID;
            requestData.value.textAreaPolicyBondNumber = data.value.textAreaPolicyBondNumber;
            requestData.value.textAreaAccountNo = data.value.textAreaAccountNo;
            requestData.value.textAreaVINNo_One = data.value.textAreaVINNo_One;
            requestData.value.textAreaVINNo_Two = data.value.textAreaVINNo_Two;
            requestData.value.textAreaVINNo_Three = data.value.textAreaVINNo_Three;
            requestData.value.textAreaDriverLicenseNo = data.value.textAreaDriverLicenseNo;
            requestData.value.textAreaDriverState = data.value.textAreaDriverState;
            requestData.value.textAreaEmployeeId = data.value.textAreaEmployeeId;
            requestData.value.textAreaEmployeeProdId = data.value.textAreaEmployeeProdId;
            requestData.value.textAreaEmployeeTicId = data.value.textAreaEmployeeTicId;
            requestData.value.textAreaAgentCode = data.value.textAreaAgentCode;
            requestData.value.textAreaNationalProducerNo = data.value.textAreaNationalProducerNo;
            requestData.value.textAreaQuote = data.value.textAreaQuote;
            requestData.value.textAreaClaim = data.value.textAreaClaim;
        }
        if (data.checked) {
            requestData.checked.claim = data.checked.claim;
            requestData.checked.currentPolicy = data.checked.currentPolicy;
            requestData.checked.quote = data.checked.quote;
            requestData.checked.travAgent = data.checked.travAgent;
            requestData.checked.travEmp = data.checked.travEmp;
            requestData.checked.travID = data.checked.travID;
            requestData.checked.travPolicy = data.checked.travPolicy;
        }
        if (data.selected) {
            requestData.selected.isGoingX = data.selected.isGoingX;
            requestData.selected.isGoingZ = data.selected.isGoingZ;
        }

        var response = await client.post('/api/customer-request/submitRequest', requestData);
        return response;
    };
    static async updateInformation(data) {
        const requestObject = {
            details: data.details,
            trackingNumber: data.trackingNumber
        };
        let params = { cpa_req: data.trackingNumber };
        try{
            return await client.post('/api/customer-request/submitUpdateInformationRequest', requestObject,{params:params });
        }catch (error){
            return error;
        }
        
        
    };
}

export default CustomerRequestApi;
