import React from 'react';
import { withRouter } from 'react-router';

class RequestCorrectionConfirmation extends React.Component {
  constructor(props) {
  super(props);
  this.handleSubmit = this.handleSubmit.bind(this);
  this.print = this.print.bind(this);
}
componentDidMount() {
  window.scrollTo(0, 0);
}
print(){
  window.print();
}
handleSubmit = () => {
  this.props.history.push('/');
}
render() {
		return (			
      <div className="tds-container tds-col-md-5">       
          <div className="tds-mt-xl" id="printDivConfirm" >
            <h1 className="tds-h1--small">Your correction request has been submitted. </h1>
            <p className="tds-large">You should receive a response to your request within 45 days.
              If we require additional time to respond, we will notify you via email. Please call <a href="tel:1-866-976-3041">1-866-976-3041</a> to check the status of your correction request.
            </p>
            <div className="tds-requestConfirmationPage">                             
                <h3>For your reference, here is your Tracking Number:</h3>
                <div className="tds-col-10">                                                        
                      <h4 className="tds-mt-m">Tracking Number</h4>                      
                      <div className="tds-large">{this.props.location?.state?.trackingNumber}</div>                                                       
                </div>              
            </div> 
            <div className="tds-mt-xl"><p className="tds-large">Thank you for your inquiry.</p> </div>                                  
            <div className="tds-button-container" id = "react-no-print">
              <button type="button" className="tds-button--primary" onClick={this.handleSubmit}>DONE</button>
              <button type="button" className="tds-button--tertiary" onClick={this.print}>PRINT</button>
            </div>
          </div>        
      </div>    
		);
	}
}

export default withRouter(RequestCorrectionConfirmation);
