import React from 'react';
import WarningModal from './warningModal.js';
import Modal from 'react-responsive-modal';
import CustomerRequestService from '../components/api/customerRequest';
import { createLogger } from '@trv-ent-web/logging';
import { ReactComponent as InfoCircle18 } from '@trv-ebus/tds-icons/dist/assets/icons/functional/svg/info-circle-1-18.svg';

let logger = createLogger({ name: 'CPA Diagnosis log: Relationship With Travelers page' });
export class relWithTrav extends React.Component {
	constructor(...props) {
		super(...props);
		this.state = {
			canOnlyDelete: this.props.canOnlyDelete,
			checked: false,
			formErrors: {
				account: '', address: '', agentCode: '', apt: '', city: '', claim: '', dob: '', email: '',
				emailMsg: '', employeeID: '', firstName: '', lastName: '', licence: '', phoneNo: '', policy: '', prodID: '',
				producerNo: '', quote: '', ssn: '', state: '', textAreaAccountNo: '', textAreaAgentCode: '', textAreaClaim: '',
				textAreaDriverLicenseNo: '', textAreaEmployeeID: '', textAreaEmployeeTicId: '',
				textAreaNationalProducerNo: '', textAreaPolicyBondNumber: '', textAreaQuote: '', textAreaVINNo_One: '',
				textAreaVINNo_Three: '', textAreaVINNo_Two: '', textAreatravID: '',
			},
			formValid2: false,
			hover: false,
			isDivDisabled: false,
			isNonDivDisabled: false,
			oneError: '',
			open: false,
			postssn: this.props.postssn,
			tdNone: false,
			textAreaAccountNo: '',
			textAreaAccountNoValid: false,
			textAreaAgentCode: '',
			textAreaAgentCodeValid: false,
			textAreaBondNumber: '',
			textAreaBondNumberValid: false,
			textAreaClaim: '',
			textAreaClaimValid: false,
			textAreaDriverLicenseNo: '',
			textAreaDriverLicenseNoValid: false,
			textAreaDriverState: '',
			textAreaDriverStateValid: false,
			textAreaEmployeeID: '',
			textAreaEmployeeIDValid: false,
			textAreaEmployeeProdId: '',
			textAreaEmployeeProdIdValid: false,
			textAreaEmployeeTicId: '',
			textAreaEmployeeTicIdValid: false,
			textAreaNationalProducerNo: '',
			textAreaNationalProducerNoValid: false,
			textAreaPolicyBondNumber: '',
			textAreaPolicyBondNumberValid: false,
			textAreaQuote: '',
			textAreaQuoteValid: false,
			textAreaVINNo_One: '',
			textAreaVINNo_OneValid: false,
			textAreaVINNo_Three: '',
			textAreaVINNo_ThreeValid: false,
			textAreaVINNo_Two: '',
			textAreaVINNo_TwoValid: false,
			textAreatravID: '',
			textAreatravIDValid: false,
			travID: '',
		};
		this.disableDiv = this.disableDiv.bind(this);
		this.checkDivStatus = this.checkDivStatus.bind(this);
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
	}

	continue = async (e) => {
		e.preventDefault();
		logger.info('CPA diagnosis log: On click of continue on Relationship with Travelers page');
		if (this.props.isStep2Valid && this.props.step2ValidValues.isVinOnevalid &&
			this.props.step2ValidValues.isVinThreevalid && this.props.step2ValidValues.isVinTwovalid
			&& this.props.step2ValidValues.isAccountvalid
			&& this.props.step2ValidValues.isAgentCodeValid
			&& this.props.step2ValidValues.isShortNamevalid
			&& this.props.step2ValidValues.isTicIdValid
			&& this.props.step2ValidValues.isNPNValid
			&& this.props.step2ValidValues.isEmpIdvalid
			&& this.props.step2ValidValues.isClaimValid
			&& this.props.step2ValidValues.isDriverLiscenseValid
			&& this.props.step2ValidValues.isDriverStateValid
			&& this.props.step2ValidValues.isQuoteValid
			&& this.props.step2ValidValues.isTravIdValid
		) {
			if (this.state.canOnlyDelete) {
				const checkReqResponse = await CustomerRequestService.checkRequestCount(this.state.postssn, true);
				this.props.navigateToDuplicate(checkReqResponse.data);
			}
			else { this.props.nextStep(); }
		}
	};

	navigate = () => {
		this.props.navigateToHome();
	}

	showModal = () => {
		this.setState({ show: true });
	};

	hideModal = () => {
		this.setState({ show: false });
	};

	onOpenModal = (e) => {
		e.preventDefault();

		this.setState({ open: true }
		);

	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};

	errorClass(error) {
		return (error.length === 0 ? '' : 'has-error');
	}

	validateFormTR() {

		return this.props.validateFormTravRel || this.state.isDivDisabled;

	}

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.error.formErrors.textAreaEmployeeTicId !== '') {
			document.getElementById('EID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaEmployeeID !== '') {
			document.getElementById('EID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaEmployeeProdId !== '') {
			document.getElementById('EID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaAgentCode !== '') {
			document.getElementById('NID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaNationalProducerNo !== '') {
			document.getElementById('NID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaVINNo_Three !== '') {
			document.getElementById('PID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaVINNo_Two !== '') {
			document.getElementById('PID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaVINNo_One !== '') {
			document.getElementById('PID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaAccountNo !== '') {
			document.getElementById('PID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaPolicyBondNumber !== '') {
			document.getElementById('PID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaDriverLicenseNo !== '') {
			document.getElementById('DID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaClaim !== '') {
			document.getElementById('CID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreaQuote !== '') {
			document.getElementById('QID').scrollIntoView();
		}
		if (this.props.error.formErrors.textAreatravID !== '') {
			document.getElementById('T1ID').scrollIntoView();
		}
		if (!this.props.checked.travID
			&& !this.props.checked.quote
			&& !this.props.checked.claim
			&& !this.props.checked.currentPolicy

			&& !this.props.checked.autoPolicy
			&& !this.props.checked.travAgent
			&& !this.props.checked.travPolicy
			&& !this.props.checked.travEmp
			&& !document.getElementById('tdNone').checked
		) {
			window.scrollTo(0, 0);
			document.getElementById('tdError').classList.remove('tds-d-none');
			document.getElementById('tdError').classList.add('tds-inline-alert--error');
			document.getElementById('tdError').innerHTML = 'Please select one option.';
			var elems = document.querySelectorAll('.tds-check--info');
			[].forEach.call(elems, function (el) {
				el.className = el.className.replace(/\btds-check--info\b/, 'tds-check--error');
			});
		}
		else {
			document.getElementById('tdError').innerHTML = '';
			document.getElementById('tdError').classList.remove('tds-inline-alert--error');
			this.continue(event);
		}
	}

	checkDivStatus() {
		if (
			this.props.checked.travID
			|| this.props.checked.quote
			|| this.props.checked.claim
			|| this.props.checked.currentPolicy
			|| this.props.checked.autoPolicy
			|| this.props.checked.travAgent
			|| this.props.checked.travPolicy
			|| this.props.checked.travEmp) {
			document.getElementById('tdNone').checked = false;
			this.setState((prevState) => ({
				isNonDivDisabled: !prevState.isNonDivDisabled
			}));
		}
	}

	disableDiv() {
		this.setState((prevState) => ({
			isDivDisabled: !prevState.isDivDisabled
		}), () => {
			if (this.state.isDivDisabled) {
				this.props.ClearOtherFieldsData();
				document.getElementById('step-2form').reset();
				document.getElementById('tdNone').checked = true;
			}
		});
	}

	firstMethod(e) {
		const re = /[0-9]+/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	secondMethod(e) {
		const re = /[a-zA-Z0-9]+/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	thirdMethod(e) {
		const re = /[a-zA-Z ]+/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	handleMouseIn() {
		this.setState({ hover: true });
	}

	handleMouseOut() {
		this.setState({ hover: false });
	}

	render() {
		const { open } = this.state;
		const { validateFormTravRel, formErrors, error, textArea, values, handleInput, checked, cannotbeLeftBlank } = this.props;
		const hiddenTrav = this.props.checked.travID ? '' : 'tds-d-none';
		const hiddenquote = this.props.checked.quote ? '' : 'tds-d-none';
		const hiddenclaim = this.props.checked.claim ? '' : 'tds-d-none';
		const hiddentravPolicy = this.props.checked.travPolicy ? '' : 'tds-d-none';
		const hiddencurrentPolicy = this.props.checked.currentPolicy ? '' : 'tds-d-none';
		const hiddenautoPolicy = this.props.checked.autoPolicy ? '' : 'tds-d-none';
		const hiddentravAgent = this.props.checked.travAgent ? '' : 'tds-d-none';
		const hiddentravEmp = this.props.checked.travEmp ? '' : 'tds-d-none';
		const tooltipStyle = {
			display: this.state.hover ? 'block' : 'none'
		};
		return (
			<div className="tds-container tds-col-md-5">
				<div className="tds-d-none" id="tdError"></div>
				<form method="POST" id="step-2form">
					<h4>Step 3 out of 4</h4>
					<h1 className="tds-h1--small">Tell us about your relationship with Travelers.</h1>
					<p> The more information you provide, the better able we will be to locate your data.</p>
					<div>
						<div id="T1ID"></div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" name="travID" id="travID" checked={checked.travID}
									onChange={this.props.handleCheckBoxChange}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="travID">I have a MyTravelers<sup>&reg;</sup> ID </label>
  </div>
						</fieldset>
						<div className={`formbg tds-mb-xxl ${hiddenTrav}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreatravID}`} >
								<span role="tooltip" id="button-description1" className="tds-tooltip" onMouseOver={this.handleMouseIn.bind(this)} onMouseOut={this.handleMouseOut.bind(this)}>
									<label className={`tds-field__label ${error.formErrors.cssTextAreatravID}`}>
										<div>(Fill in as much information as you can) </div>MyTravelers<sup>&reg;</sup> ID
										<div className="tds-iconbutton" aria-label="Help">
											<span className="tds-tooltip__container">
												<span className="icon icon-16 error-16-small">
													<InfoCircle18 className="tds-icon-18" slot="icon-first" />
												</span>
												<div role="tooltip" id="button-description1" className="tds-tooltip">
													This is the login ID used to access your Travelers online account.
												</div>
											</span>
										</div>
									</label>
								</span>
								<input className="tds-field__input" id="textAreatravID" type="text"
									onMouseDown={(e) => e.target.focus()}
									aria-describedby="fname1-input-error-100" maxLength="60"
									onBlur={handleInput('textAreatravID')}
									onChange={cannotbeLeftBlank('textAreatravID')}
									defaultValue={values.textAreatravID}
									name="textAreatravID"
									disabled={this.props.isDivDisabled} />
								<div id="textAreatravID_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreatravID}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" name="quote" id="quote"
									onChange={this.props.handleCheckBoxChange}
									checked={checked.quote} disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="quote">I have requested a quote from Travelers</label>
	</div>
						</fieldset>
						<div className={`formbg tds-mb-xxl ${hiddenquote}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaQuote}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaQuote}`} id="QID">
									<div>(Fill in as much information as you can) </div>Quote Number
									<input className="tds-field__input" id="textAreaQuote" type="text" maxLength="10" minLength="1"
										aria-describedby="fname1-input-error-100" disabled={this.props.isDivDisabled}
										onBlur={handleInput('textAreaQuote')}
										onMouseDown={(e) => e.target.focus()}
										onChange={cannotbeLeftBlank('textAreaQuote')}
										defaultValue={values.textAreaQuote}
										name="textAreaQuote" />
								</label>
								<div id="textAreaQuote_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaQuote}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" name="claim" id="claim"
									onChange={this.props.handleCheckBoxChange}
									checked={checked.claim}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="claim">I have had a claim with Travelers</label>
	</div>
						</fieldset>
						<div className={`formbg tds-mb-xxl ${hiddenclaim}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaClaim}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaClaim}`} id="CID">
									<div>(Fill in as much information as you can)</div> Claim Number
									<input className="tds-field__input" id="textAreaClaim" type="text"
										maxLength="21" minLength="1"
										aria-required="true" aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaClaim')}
										onMouseDown={(e) => e.target.focus()}
										defaultValue={values.textAreaClaim} name="textAreaClaim"
										disabled={this.props.isDivDisabled} />
								</label>
								<div id="textAreaClaim_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaClaim}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" id="travPolicy" name="travPolicy" onChange={this.props.handleCheckBoxChange}
									checked={checked.travPolicy}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="travPolicy">I'm listed as an authorized driver on a Travelers policy </label>
	</div>
	</fieldset>
						<div className={`formbg tds-mb-xxl ${hiddentravPolicy}`}>
							<div className="tds-row">
								<div className="tds-col-8">
									<label className={`tds-field__label ${error.formErrors.cssTextDriverLicenseNo}`} id="DID">
										<div className="form-group tds-field">
											<div>(Fill in as much information as you can) </div>Driver's License Number
											<input className="tds-field__input" id="textAreaDriverLicenseNo" type="text" minLength="1"
												onMouseDown={(e) => e.target.focus()}
												aria-describedby="fname1-input-error-100"
												onBlur={handleInput('textAreaDriverLicenseNo')}
												defaultValue={values.textAreaDriverLicenseNo}
												name="textAreaDriverLicenseNo"
												maxLength="30" disabled={this.props.isDivDisabled} />
											<div id="textAreaDriverLicenseNo_help" aria-atomic="true" className="tds-field__message" role="alert">
												<p>{error.formErrors.textAreaDriverLicenseNo}</p>
											</div>
										</div>
									</label>
								</div>
								<div className="tds-col-4">
									<label className="tds-field__label">
										<div className={`form-group tds-field ${error.formErrors.cssTextAreaDriverState}`}>
											State
											<select className="tds-field__input" name="textAreaDriverState" id="textAreaDriverState" disabled={this.props.isDivDisabled}
												onBlur={handleInput('textAreaDriverState')} onMouseDown={(e) => e.target.focus()}
												defaultValue={'California'} >
												<option aria-label="California" value="California">California</option>
												<option aria-label="Alabama" value="AL">Alabama</option>
												<option aria-label="Alaska" value="AK">Alaska</option>
												<option aria-label="Arizona" value="AZ">Arizona</option>
												<option aria-label="Arkansas" value="AR">Arkansas</option>
												<option aria-label="Colorado" value="CO">Colorado</option>
												<option aria-label="Connecticut" value="CT">Connecticut</option>
												<option aria-label="Delaware" value="DE">Delaware</option>
												<option aria-label="District Of Columbia" value="DC">District Of Columbia</option>
												<option aria-label="Florida" value="FL">Florida</option>
												<option aria-label="Georgia" value="GA">Georgia</option>
												<option aria-label="Hawaii" value="HI">Hawaii</option>
												<option aria-label="Idaho" value="ID">Idaho</option>
												<option aria-label="Illinois" value="IL">Illinois</option>
												<option aria-label="Indiana" value="IN">Indiana</option>
												<option aria-label="Iowa" value="IA">Iowa</option>
												<option aria-label="Kansas" value="KS">Kansas</option>
												<option aria-label="Kentucky" value="KY">Kentucky</option>
												<option aria-label="Louisiana" value="LA">Louisiana</option>
												<option aria-label="Maine" value="ME">Maine</option>
												<option aria-label="Maryland" value="MD">Maryland</option>
												<option aria-label="Massachusetts" value="MA">Massachusetts</option>
												<option aria-label="Michigan" value="MI">Michigan</option>
												<option aria-label="Minnesota" value="MN">Minnesota</option>
												<option aria-label="Mississippi" value="MS">Mississippi</option>
												<option aria-label="Missouri" value="MO">Missouri</option>
												<option aria-label="Montana" value="MT">Montana</option>
												<option aria-label="Nebraska" value="NE">Nebraska</option>
												<option aria-label="Nevada" value="NV">Nevada</option>
												<option aria-label="New Hampshire" value="NH">New Hampshire</option>
												<option aria-label="New Jersey" value="NJ">New Jersey</option>
												<option aria-label="New Mexico" value="NM">New Mexico</option>
												<option aria-label="New York" value="NY">New York</option>
												<option aria-label="North Carolina" value="NC">North Carolina</option>
												<option aria-label="North Dakota" value="ND">North Dakota</option>
												<option aria-label="Ohio" value="OH">Ohio</option>
												<option aria-label="Oklahoma" value="OK">Oklahoma</option>
												<option aria-label="Oregon" value="OR">Oregon</option>
												<option aria-label="Pennsylvania" value="PA">Pennsylvania</option>
												<option aria-label="Rhode Island" value="RI">Rhode Island</option>
												<option aria-label="South Carolina" value="SC">South Carolina</option>
												<option aria-label="South Dakota" value="SD">South Dakota</option>
												<option aria-label="Tennessee" value="TN">Tennessee</option>
												<option aria-label="Texas" value="TX">Texas</option>
												<option aria-label="Utah" value="UT">Utah</option>
												<option aria-label="Vermont" value="VT">Vermont</option>
												<option aria-label="Virginia" value="VA">Virginia</option>
												<option aria-label="Washington" value="WA">Washington</option>
												<option aria-label="West Virginia" value="WV">West Virginia</option>
												<option aria-label="Wisconsin" value="WI">Wisconsin</option>
												<option aria-label="Wyoming" value="WY">Wyoming</option>
											</select>
										</div>
									</label>
								</div>
								<div id="textAreaDriverState_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaDriverState}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" id="currentPolicy" name="currentPolicy" onChange={this.props.handleCheckBoxChange}
									checked={checked.currentPolicy}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="currentPolicy"> I'm a current or former policy holder </label>
	</div>
	</fieldset>
						<div className={`formbg ${hiddencurrentPolicy}`} >
							<div className={`tds-field ${error.formErrors.cssTextAreaPolicyBondNumber}`}>
								<label className="tds-field__label width100" id="PID">
									<div>(Fill in as much information as you can) </div>Policy / Bond Number
									<div className="tds-iconbutton" aria-label="Help">
										<span className="tds-tooltip__container">
											<span className="icon icon-16 error-16-small">
												<InfoCircle18 className="tds-icon-18" slot="icon-first" />
											</span>
											<div role="tooltip" id="button-description1" className="tds-tooltip">
												This number can be found on the insurance card or other documents provided by the insurance company upon the purchase of a policy.
											</div>
										</span>
									</div>
									<input className="tds-field__input" id="textAreaPolicyBondNumber" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaPolicyBondNumber')}
										onMouseDown={(e) => e.target.focus()}
										defaultValue={values.textAreaPolicyBondNumber} name="textAreaPolicyBondNumber"
										disabled={this.props.isDivDisabled}
										maxLength="40" />
									<div id="textAreaPolicyBondNumber_help" aria-atomic="true" className="tds-field__message" role="alert">
										<p>{error.formErrors.textAreaPolicyBondNumber}</p>
									</div>
								</label>
							</div>
						</div>
						<div className={`formbg ${hiddencurrentPolicy}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaAccountNo}`}>
								<label className="tds-field__label width100">
									Account Number
									<div className="tds-iconbutton" aria-label="Help">
										<span className="tds-tooltip__container">
											<span className="icon icon-16 error-16-small">
												<InfoCircle18 className="tds-icon-18" slot="icon-first" />
											</span>
											<div role="tooltip" id="button-description1" className="tds-tooltip">
												A Standard Account Identifier (SAI) number, billing account number, bond and financial products account number, or similarly labeled number.
											</div>
										</span>
									</div>
									<input className="tds-field__input" id="textAreaAccountNo" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaAccountNo')}
										defaultValue={values.textAreaAccountNo} name="textAreaAccountNo"
										disabled={this.props.isDivDisabled}
										maxLength="10" onMouseDown={(e) => e.target.focus()} />
									<div id="textAreaAccountNo_help" aria-atomic="true" className="tds-field__message" role="alert">
										<p>{error.formErrors.textAreaAccountNo}</p>
									</div>
								</label>
							</div>
						</div>
						<div className={`formbg ${hiddencurrentPolicy}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaVINNo_One}`}>
								<label className="tds-field__label width100">
									(VIN) Vehicle Identification Number 1
									<div className="tds-iconbutton" aria-label="Help">
										<span className="tds-tooltip__container">
											<span className="icon icon-16 error-16-small">
												<InfoCircle18 className="tds-icon-18" slot="icon-first" />
											</span>
											<div role="tooltip" id="button-description1" className="tds-tooltip">
												The VIN can usually be found on
												your insurance card, title document,
												driver’s side interior dash, or door jamb.
											</div>
										</span>
									</div>
									<input className="tds-field__input" id="textAreaVINNo_One" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaVINNo_One')}
										disabled={this.props.isDivDisabled} onMouseDown={(e) => e.target.focus()}
										defaultValue={values.textAreaVINNo_One} name="textAreaVINNo_One"
										maxLength="22" />
								</label>
								<div id="textAreaVINNo_One_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaVINNo_One}</p>
								</div>
							</div>
							<div className={`tds-field ${error.formErrors.cssTextAreaVINNo_Two}`}>
								<label className="tds-field__label width100">
									(VIN) Vehicle Identification Number 2
									<input className="tds-field__input" id="textAreaVINNo_Two" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaVINNo_Two')}
										defaultValue={values.textAreaVINNo_Two} name="textAreaVINNo_Two" onMouseDown={(e) => e.target.focus()}
										maxLength="22" disabled={this.props.isDivDisabled} />
								</label>
								<div id="textAreaVINNo_Two_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaVINNo_Two}</p>
								</div>
							</div>
							<div className={`tds-field tds-mb-xxl ${error.formErrors.cssTextAreaVINNo_Three}`}>
								<label className="tds-field__label width100">
									(VIN) Vehicle Identification Number 3
									<input className="tds-field__input" id="textAreaVINNo_Three" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaVINNo_Three')}
										defaultValue={values.textAreaVINNo_Three} name="textAreaVINNo_Three" onMouseDown={(e) => e.target.focus()}
										maxLength="22" disabled={this.props.isDivDisabled} />
								</label>
								<div id="textAreaVINNo_Three_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaVINNo_Three}</p>
								</div>
							</div>
						</div>
						{/* hidden ends here */}
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" id="travAgent" name="travAgent" onChange={this.props.handleCheckBoxChange}
									checked={checked.travAgent}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="travAgent">I'm an authorized Travelers agent</label>
	</div>
	</fieldset>
						<div className={`formbg ${hiddentravAgent}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaNationalProducerNo}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaNationalProducerNo}`} id="NID">
									<div>(Fill in as much information as you can) </div>	National Producer Number
									<input className="tds-field__input" id="textAreaNationalProducerNo" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaNationalProducerNo')}
										defaultValue={values.textAreaNationalProducerNo} name="textAreaNationalProducerNo"
										maxLength="10" disabled={this.props.isDivDisabled} onMouseDown={(e) => e.target.focus()}
									/>
								</label>
								<div id="textAreaNationalProducerNo_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaNationalProducerNo}</p>
								</div>
							</div>
							<div className={`tds-field tds-mb-xxl ${error.formErrors.cssTextAreaAgentCode}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaAgentCode}`}>
									<label>Agent Code</label>
									<input className="tds-field__input" id="textAreaAgentCode" type="text" minLength="5"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaAgentCode')}
										defaultValue={values.textAreaAgentCode} name="textAreaAgentCode" onMouseDown={(e) => e.target.focus()}
										maxLength="6" disabled={this.props.isDivDisabled} />
								</label>
								<div id="textAreaAgentCode_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaAgentCode}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" name="travEmp" id="travEmp"
									onChange={this.props.handleCheckBoxChange}
									checked={checked.travEmp}
									disabled={this.props.isDivDisabled} />
    <label className="tds-check__label" for="travEmp">I'm a current or former employee</label>
	</div>
	</fieldset>
						<div className={`formbg ${hiddentravEmp}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaEmployeeID}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaEmployeeID}`} id="EID">
									<div>(Fill in as much information as you can) </div>
									Employee ID
									<input className="tds-field__input" id="textAreaEmployeeID" type="text" minLength="9"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaEmployeeID')}
										defaultValue={values.textAreaEmployeeID} name="textAreaEmployeeID"
										maxLength="11" disabled={this.props.isDivDisabled} onMouseDown={(e) => e.target.focus()} />
								</label>
								<div id="textAreaEmployeeID_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaEmployeeID}</p>
								</div>
							</div>
						</div>
						<div className={`formbg ${hiddentravEmp}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaEmployeeProdId}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaEmployeeProdId}`}>
									<label>Employee Short Name (Prod ID)</label>
									<input className="tds-field__input" id="textAreaEmployeeProdId" type="text" minLength="1"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaEmployeeProdId')}
										defaultValue={values.textAreaEmployeeProdId} name="textAreaEmployeeProdId"
										maxLength="8" disabled={this.props.isDivDisabled} onMouseDown={(e) => e.target.focus()}
									/>
								</label>
								<div id="textAreaEmployeeProdId_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaEmployeeProdId}</p>
								</div>
							</div>
						</div>
						<div className={`formbg tds-mb-xxl ${hiddentravEmp}`}>
							<div className={`tds-field ${error.formErrors.cssTextAreaEmployeeTicId}`}>
								<label className={`tds-field__label width100 ${error.formErrors.cssTextAreaEmployeeTicId}`}>
									<label>Employee Travelers Insurance Company ID (TIC-ID)</label>
									<input className="tds-field__input" id="textAreaEmployeeTicId" type="text" minLength="6"
										aria-describedby="fname1-input-error-100"
										onBlur={handleInput('textAreaEmployeeTicId')} onMouseDown={(e) => e.target.focus()}
										defaultValue={values.textAreaEmployeeTicId} name="textAreaEmployeeTicId"
										maxLength="6" disabled={this.props.isDivDisabled} />
								</label>
								<div id="textAreaEmployeeTicId_help" aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.textAreaEmployeeTicId}</p>
								</div>
							</div>
						</div>
						<fieldset role="group">
						<div className="tds-check tds-check--large">
    <input type="checkbox" name="none"
									id="tdNone" value={this.props.none}
									onChange={this.props.handleCheckBoxChange}
									checked={checked.none}
									disabled={this.props.isNonDivDisabled} />
    <label className="tds-check__label" for="tdNone">None of this applies to me</label>
	</div>
</fieldset>
						<main>
							<div className="tds-button-container tds-mt-xl">
								<button type="button" className="tds-button--primary"
									onClick={this.handleSubmit}>continue</button>
								<button type="button" className="tds-button--tertiary" onClick={this.onOpenModal} > CANCEL</button>
								<Modal open={open}
									onClose={this.onCloseModal}
									center
									showCloseIcon={true}
								>
									<WarningModal
										onCloseModal={this.onCloseModal.bind(this)}
										navigateToHome={this.navigate.bind(this)}
									/>
								</Modal>
							</div>	</main>
					</div>
				</form>
			</div>
		);
	}
}
export default relWithTrav;




