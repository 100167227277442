import React, { Component } from 'react';


export class IdentityFailure extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error4300: <span><div className="tds-mt-xl"><h1 className="tds-h1--small">We’re Sorry</h1>
                <p className="tds-large"> We are having difficulty verifying your identity. In order to protect your privacy, we are unable to process your request at this time.</p>
                <p className="tds-large">For additional information about how we collect, use and disclose personal information, please refer to the Privacy & Security section of our website.
                </p></div></span>,

            error4303: <span><div className="tds-mt-xl"><h1 className="tds-h1--small">We’re Sorry</h1>
                <p className="tds-large">You’ve exhausted the number of attempts to verify your identity. </p> </div>
                <div className="tds-button-container"><button type="button" className="tds-button--tertiary" onClick={this.navigate}>CANCEL</button></div><hr />
                <div className="tds-mt-l tds-text-align-md-center"><span className="tds-bold">Technical Support</span>
                    <div>
                        If you're having problems registering, call us for help.</div><a href = "tel:1-844-505-7208">1-844-505-7208</a>
                    <div> 8 a.m. to 8 p.m. ET, Monday-Friday</div>
                </div>
            </span>,
            error4404: <span><div className="tds-mt-xl"><h1 className="tds-h1--small">We couldn't verify your identity.</h1>
                <p className="tds-large"> We can’t retrieve your record. Make sure you entered your information correctly.</p>
                <p className="tds-large"> If you feel this is in error, please call us at 1-844-505-7208 from M-F, 8a.m.-5 p.m. ET.</p></div>
                <div className="tds-button-container"><button type="button" className="tds-button--tertiary" onClick={this.navigate}>
                    CANCEL
                </button></div></span>,

            weAreSorry: <span><div className="tds-mt-xl">
                <h1 className="tds-h1--small">We’re Sorry</h1>
                <p className="tds-large"> We are currently unavailable. Please try back soon.</p></div>
                <div className="tds-button-container"><button type="button" className="tds-button--tertiary" onClick={this.navigate}>CANCEL</button>
                </div><hr />
                <div className="tds-mt-l tds-text-align-md-center"><span className="tds-bold">Technical Support</span>
                    <div>
                        If you're having problems registering, call us for help.</div><a href = "tel:1-844-505-7208">1-844-505-7208</a>
                    <div> 8 a.m. to 8 p.m. ET, Monday-Friday</div>
                </div>
            </span>,
        };
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Consumer Privacy | Travelers Insurance';
        if (this.props.httpCode === '404') {

            if (this.props.errorCode === '200-4404') {
                await this.setState((prevState) => ({
                    errorMessage: prevState.error4404
                }));
            }
            else {
                await this.setState((prevState) => ({
                    errorMessage: prevState.weAreSorry
                }));
            }
        }

        else if (this.props.httpCode === '403') {
            if (this.props.errorCode === '200-4303') {
                await this.setState((prevState) => ({
                    errorMessage: prevState.error4303
                }));
            }
            else if (this.props.errorCode === '200-4300') {
                await this.setState((prevState) => ({
                    errorMessage: prevState.error4300
                }));
            }
            else {
                await this.setState((prevState) => ({
                    errorMessage: prevState.weAreSorry
                }));
            }
        }
        else {
            await this.setState((prevState) => ({
                errorMessage: prevState.weAreSorry
            }));
        }
    }

    navigate = () => {
        this.props.navigateToHome();
    }

    render() {
        return (
            <div className="tds-container tds-col-md-5">
                {this.state.errorMessage}
            </div>
        );
    };
}

export default IdentityFailure;
