import React, { Component } from 'react';
import ReferenceService from '../components/api/referenceService';
import CustomerStatusService from '../components/api/customerStatus';
import CorrectInformation from './correctInformation';
import Modal from 'react-responsive-modal';
var dateFormat = require('dateformat');

export class DataReport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			gridData: [],
			isInternal: false,
			isSent: false,
			messageClass: '',
			open: false,
			openCorrectInformationModal: false,
			show: false
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	async componentDidMount() {
		document.title = 'Consumer Privacy | Travelers Insurance';
		window.scrollTo(0, 0);
		this.setState((prevState) => ({
			gridData: this.props.gridData !== undefined ? this.props.gridData : prevState.gridData
		}));
		let configs = await ReferenceService.configs();
		this.setState({ isInternal: configs.isInternal });
	}

	navigate = () => {
		this.setState((prevState) => ({
			message: '',
			show: false,
			step: prevState.step - 1
		}));
	}

	handleSubmit() {
		this.props.navigateToHome();

	}

	onOpenCorrectInformationModal = (e) => {
		e.preventDefault();
		this.setState({ openCorrectInformationModal: true }
		);
	};
	onCloseCorrectInformationModal = () => {
		this.setState({ openCorrectInformationModal: false });
	};

	continue = (input, email) => async (e) => {
		e.preventDefault();
		this.setState({
			isSent: true
		});

		// calling the api to customer request submit

		const response = await CustomerStatusService.receiveViaSecureEmail(input);

		if (response !== null && response.data !== null && response.data.statusCode === 500) {

			this.setState({
				message: 'Download link was not sent. Please come back in a few minutes and try again.',
				messageClass: 'tds-inline-alert--warning'
			}, () => {
				window.scrollTo(0, 0);
			});
		}
		else {
			const emailPart = email.split('@');
			const mask = emailPart[0].substr(0, emailPart[0].length - 4).padEnd(emailPart[0].length, '*');
			const emailAddress = mask + '@' + emailPart[1];
			this.setState({
				message: `Download link sent to ${emailAddress}`,
				messageClass: 'tds-inline-alert--success'
			}, () => {
				window.scrollTo(0, 0);
			});
		}
	};

	toTitleCase(text) {
		text = text.toLowerCase()
			.split(' ')
			.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ');
		return text;
	}
	render() {

		const { openCorrectInformationModal } = this.state;
		const hidden = this.state.isSent ? this.state.messageClass : 'tds-d-none';

		return (
			<div className="tds-container tds-col-12">
				<div className="tds-text-align-md-center tds-centered">
					<h1 className="tds-h1--small">Data Report for</h1>
					<h1 className="tds-h1--semibold">
						{this.toTitleCase(this.props.gridData[0].CPA_CUST_FULL_NAME)}
					</h1>
					<p>Data Report will expire within 7 years.</p>
				</div>
				<div id="basic-table-sample" className="tds-data-table-scrollpanel">
					<div className={hidden}>{this.state.message}</div>
					<table className="tds-data-table tds-data-table--hilite-row tds-data-table--header-dividers" id="tbGrid" data-table-sort="simple">
						<thead >
							<tr>
								<th id="hd-table-sample-hdr1" scope="col" role="columnheader" aria-sort="descending">
									<button className="tds-data-table__sort-col-button">DATE SUBMITTED</button>
								</th>
								<th className="" scope="col" role="columnheader" aria-sort="none" >
									<button className="tds-data-table__sort-col-button">TRACKING NUMBER</button>
								</th>

								<th className="" scope="col" role="columnheader" aria-sort="none" >
									<button className="tds-data-table__sort-col-button">TYPE</button>
								</th>

								<th className="" scope="col" role="columnheader" aria-sort="none">
									<button className="tds-data-table__sort-col-button">STATUS</button>
								</th>
								<th className="" scope="col" role="columnheader" aria-sort="none">
									<button className="tds-data-table__sort-col-button">Estimated Completion</button>
								</th>
								<th className="" scope="col" role="columnheader">
									Review Response
								</th>
								<th className="" scope="col" role="columnheader">
									Correction Request
								</th>
							</tr>
						</thead>
						<tbody>

							{this.state.gridData.map(row =>
							(
								<tr className="" key={row.CPA_CUST_REQUEST_ID}>
									<td className="tds-data-table__cell--align-left spacing-betweenn-td" data-sort-value={row.CPA_CUST_REQUEST_SUBMITTED_DATETIME}>
										{dateFormat(row.CPA_CUST_REQUEST_SUBMITTED_DATETIME, 'mm/dd/yy')}
									</td>
									<td className=" tds-data-table__cell--align-left spacing-betweenn-td" data-sort-value={row.CPA_CUST_REQUEST_ID}>
										<span id="trackingId">{row.CPA_CUST_REQUEST_ID}</span>
									</td>
									<td className="tds-data-table__cell--align-left spacing-betweenn-td">
										<span id="trackingId">{row.CPA_CUST_REQ_TO_ACCESS_DATA_FLG == 'Y' ? 'Access' : 'Delete'}</span>
									</td>
									<td className=" tds-data-table__cell--align-left spacing-betweenn-td" data-sort-value={row.CPA_CUST_REQUEST_STATUS}>
										{row.CPA_CUST_REQUEST_STATUS !== 'COMPLETED' ?
											<span>
												{row.CPA_CUST_REQUEST_STATUS === 'IN PROGRESS' ?
													<span>
														<b>In Progress</b>
													</span>
													:
													<span>
														<b>Open</b>
													</span>
												}
											</span>
											:
											<span className="" >
												<b>Completed On</b>
											</span>
										}
									</td>
									<td className="tds-data-table__cell--align-left spacing-betweenn-td" data-sort-value={row.CPA_CUST_REQUEST_COMPLETED_DATETIME}>
										{dateFormat(row.CPA_CUST_REQUEST_COMPLETED_DATETIME, 'mm/dd/yy')}
									</td>
									{row.CPA_CUST_REQUEST_STATUS === 'COMPLETED' && row.CPA_CUST_HR_REQUEST_FLAG !== 'Y' ?
										<td className="tds-data-table__cell--align-left spacing-betweenn-td">
											{row.CPA_CUST_REQ_TO_ACCESS_DATA_FLG == 'Y' && row.CPA_CUST_EMAIL_ID !== null && row.CPA_CUST_EMAIL_ID !== ''
												?
												<span id={row.CPA_CUST_REQUEST_ID}><a className="a remove-hyperlink" href="#"
													onClick={this.continue(row.CPA_CUST_REQUEST_ID, row.CPA_CUST_EMAIL_ID)} > Send Download Link Via Email</a>
												</span>
												:
												<span>
													{row.CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'Y' && (row.CPA_CUST_EMAIL_ID === null || row.CPA_CUST_EMAIL_ID === '') ?
														<span>Mailing Sent Via U.S. Mail</span>
														:
														<span>
															<span id={row.CPA_CUST_REQUEST_ID}><span>{row.DELETE_RESP_MSG}</span>
															</span>
														</span>
													}
												</span>
											}
										</td>
										:
										<td className="tds-data-table__cell--align-left spacing-betweenn-td">
											{row.CPA_CUST_REQUEST_STATUS === 'COMPLETED' && row.CPA_CUST_EMAIL_ID === null && row.CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'Y' && row.CPA_CUST_HR_REQUEST_FLAG !== 'Y' ?
												<span>Mailing Sent Via U.S. Mail</span> : row.CPA_CUST_REQUEST_STATUS === 'COMPLETED' ?
													<span>Call <a href="tel:1-866-976-3041" >1-866-976-3041</a> To Request Download</span> : <span></span>
											}
										</td>
									}
									<td className="tds-data-table__cell--align-top spacing-betweenn-td">
										{row.CPA_CUST_REQ_TO_ACCESS_DATA_FLG == 'Y' && row.CPA_CUST_REQUEST_STATUS === 'COMPLETED' ?
											<span id={row.CPA_CUST_REQUEST_ID}>
												<button type="button" className="tds-button--tertiary closeButton" onClick={this.onOpenCorrectInformationModal}
												>Request Correction</button>
												<span>Request correction or call <a href="tel:(866) 976-3041">(866) 976-3041</a></span>
												<Modal open={openCorrectInformationModal}
													onClose={this.onCloseCorrectInformationModal}
													center focusTrapped={false}
													showCloseIcon={true}
												>
													<CorrectInformation
														onCloseModal={this.onCloseCorrectInformationModal.bind(this)}
														trackingNumber={row.CPA_CUST_REQUEST_ID}
													/>
												</Modal>
											</span>
											: <span></span>
										}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div className="tds-button-container">
					<button type="button" className="tds-button--primary" onClick={this.handleSubmit}>DONE</button>
				</div>
			</div>

		);
	}
}
export default DataReport;
