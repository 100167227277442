import React, { Component } from 'react';
import HomePage from '../pages/homePage.js';
import AccessOrDelete from '../pages/accessOrDelete.js';
import VerifyIdentity from '../pages/verifyIdentity.js';
import RelWithTrav from '../pages/relWithTrav.js';
import AccessRequest from '../pages/accessRequest.js';
import ReferenceService from './api/referenceService.js';
import AcxiomQuiz from '../pages/acxiomQuiz.js';
import IdentityFailure from '../pages/identityFailure';
import InfiniteDeleteRequests from '../pages/infiniteDeleteRequests.js';
import ViewStatus from '../pages/viewStatusPage.js';
import DataReport from '../pages/dataReport.js';
import DeleteRequest from '../pages/deleteRequest';
import AccessAndDeleteRequest from '../pages/accessAndDeleteRequest.js';
import ExemptionPage from '../pages/exemptionPage.js';
import ServiceDownPage from '../pages/serviceDownPage';
import ThankYou from '../pages/thankYou.js';
import { withOktaAuth } from '@okta/okta-react';


export default withOktaAuth(class UserForm extends Component {
  state = {
    access: '',
    accessDeleteData: [],
    account: '',
    accountValid: false,
    acxiomResponse: [],
    address: '',
    addressValid: false,
    agentCode: '',
    agentCodeValid: false,
    apt: '',
    aptValid: false,
    canOnlyDelete: false,
    checked: {
      account: false, agentCode: false, autoPolicy: false, claim: false, currentPolicy: false,
      employeeID: false, licence: false, none: false, policy: false, prodID: false, producerNo: false,
      quote: false, tIDArea: false,
      textAreaAccountNo: false, textAreaAgentCode: false,
      textAreaBondNumberValid: false,
      textAreaClaim: false,
      textAreaDriverLicenseNo: false,
      textAreaEmployeeID: false, textAreaEmployeeTicId: false,
      textAreaNationalProducerNo: false,
      textAreaPolicyBondNumber: false,
      textAreaQuote: false, textAreaVINNo_One: false,
      textAreaVINNo_Three: false,
      textAreaVINNo_Two: false, ticID: false,
      travAgent: false, travEmp: false, travID: false,
      travPolicy: false, vinNo: false
    },

    city: '',
    cityValid: false,
    claim: '',
    claimValid: false,
    confirmEmail: '',
    confirmEmailValid: false,
    confirmEmailValidEnable: false,
    dob: '',

    dobValid: false,
    dobpart: '',
    email: '',
    emailInvalid: true,
    emailMsg: '',
    emailValid: false,
    employeeID: '',
    employeeIDValid: false,
    errorCode: 0,
    firstName: '',
    firstNameValid: false,
    formErrors: {
      account: '', address: '', agentCode: '', apt: '', city: '', claim: '', cssAddress: '', cssCity: '', cssConfirmEmail: '', cssDob: '', cssEmailMsg: '', cssFirstName: '', cssLastName: '',
      cssPhoneNo: '', cssSsn: '', cssZipcode: '', dob: '', email: '',
      emailMsg: '', employeeID: '', firstName: '', lastName: '', licence: '', phoneNo: '', policy: '', prodID: '',
      producerNo: '', quote: '', ssn: '', state: '', textAreaAccountNo: '', textAreaAgentCode: '', textAreaBondNumber: '', textAreaClaim: '',
      textAreaDriverLicenseNo: '', textAreaDriverState: '', textAreaEmployeeID: '', textAreaEmployeeProdId: '', textAreaEmployeeTicId: '',
      textAreaNationalProducerNo: '', textAreaPolicyBondNumber: '', textAreaQuote: '', textAreaVINNo_One: '',
      textAreaVINNo_Three: '', textAreaVINNo_Two: '', textAreatravID: '', ticID: '', travID: '', vinNo: '', zipCode: ''
    },
    formValid: false,
    formValid2: false,
    gridData: [],
    httpCode: 0,
    isAccountvalid: true,
    isAgentCodeValid: true,


    isCaptchaVerified: false,

    isClaimValid: true,
    isDivDisabled: false,
    isDriverLiscenseValid: true,
    isDriverStateValid: true,
    isEmpIdvalid: true,
    isGoing: false,
    isGoingX: false,
    isGoingY: false,
    isGoingZ: false,

    isInternal: false,
    isNPNValid: true,
    isNonDivDisabled: false,
    isNoneOfThese: false,
    isOktaAuthorized: false,
    isQuoteValid: true,
    isShortNamevalid: true,
    isStep2Valid: true,
    isTicIdValid: true,
    isTravIdValid: true,
    isVinOnevalid: true,
    isVinThreevalid: true,
    isVinTwovalid: true,
    isvalid: false,
    lastName: '',

    lastNameValid: false,
    latestTrackingNumber: '',

    licence: '',
    licenceValid: false,
    message: '',
    phoneNo: '',
    phoneNoValid: false,
    policy: '',
    policyValid: false,
    postssn: '',
    prodID: '',
    prodIDValid: false,
    producerNo: '',
    producerNoValid: false,
    questionId: '',
    quote: '',
    quoteValid: false,
    reCatchaKey: '',
    requestType: '',
    secondSubmittedDate: '',
    secondTrackingNumber: '',
    selected: {

      isGoingX: false,
      isGoingZ: false,

    },
    socialSecurityNoValid: false,
    ssn: '',
    ssnVri: '',

    state: '',
    stateValid: false,
    step: 1,
    submitteddate: '',
    tdNone: '',
    textArea: {
      TICIDArea: '', VINNo1: '', VINNo2: '', VINNo3: '', accountNoArea: '', agentCodeArea: '',
      claimArea: '', driverLicenseNoArea: '', empIDArea: '', nationalProducerNoArea: '', policyOrBondNoArea: '',
      prodIDArea: '', quoteArea: '', stateArea: '', tIDArea: '', textAreaAccountNo: '', textAreaAgentCode: '',
      textAreaBondNumber: '',
      textAreaClaim: '', textAreaDriverLicenseNo: '', textAreaEmployeeID: '', textAreaEmployeeTicId: '',
      textAreaNationalProducerNo: '', textAreaPolicyBondNumber: '', textAreaQuote: '',
      textAreaVINNo_One: '', textAreaVINNo_Three: '', textAreaVINNo_Two: '', textAreatravID: '',
    },

    textAreaAccountNo: '',
    textAreaAccountNoValid: false,
    textAreaAgentCode: '',
    textAreaAgentCodeValid: false,
    textAreaBondNumber: '',
    textAreaBondNumberValid: false,
    textAreaClaim: '',
    textAreaClaimValid: false,

    textAreaDriverLicenseNo: '',
    textAreaDriverLicenseNoValid: false,

    textAreaDriverState: '',
    textAreaDriverStateValid: false,

    textAreaEmployeeID: '',
    textAreaEmployeeIDValid: false,

    textAreaEmployeeProdId: '',
    textAreaEmployeeProdIdValid: false,

    textAreaEmployeeTicId: '',
    textAreaEmployeeTicIdValid: false,

    textAreaNationalProducerNo: '',
    textAreaNationalProducerNoValid: false,

    textAreaPolicyBondNumber: '',
    textAreaPolicyBondNumberValid: false,

    textAreaQuote: '',
    textAreaQuoteValid: false,

    textAreaVINNo_One: '',
    textAreaVINNo_OneValid: false,

    textAreaVINNo_Three: '',
    textAreaVINNo_ThreeValid: false,

    textAreaVINNo_Two: '',
    textAreaVINNo_TwoValid: false,

    textAreatravID: '',
    textAreatravIDValid: false,
    token: '',
    trackingNo: '',
    trackingNoSubmissionDate: '',
    travID: '',
    travIDValid: false,
    yes: false,
    zipCode: '',
    zipCodeInvalid: true,
    zipCodeMsg: '',
    zipCodeValid: false
  };


  ClearOtherFieldsData() {

    this.setState({
      autoPolicy: false,

      claim: false,
      currentPolicy: false,
      formErrors: {
        account: '', address: '', agentCode: '', apt: '', city: '', claim: '', dob: '', email: '',
        emailMsg: '', employeeID: '', firstName: '', lastName: '', licence: '', phoneNo: '', policy: '', prodID: '',
        producerNo: '', quote: '', ssn: '', state: '', tdNone: '', textAreaAccountNo: '', textAreaAgentCode: '', textAreaBondNumber: '', textAreaClaim: '',

        textAreaDriverLicenseNo: '', textAreaDriverState: '', textAreaEmployeeID: '', textAreaEmployeeProdId: '', textAreaEmployeeTicId: '',
        textAreaNationalProducerNo: '', textAreaPolicyBondNumber: '', textAreaQuote: '', textAreaVINNo_One: '',
        textAreaVINNo_Three: '', textAreaVINNo_Two: '', textAreatravID: '', ticID: '', travID: '', vinNo: '', zipCode: ''
      },
      quote: false,
      tdNone: false,
      textAreaAccountNo: '',
      textAreaAgentCode: '',
      textAreaBondNumber: '',
      textAreaClaim: '',
      textAreaDriverLicenseNo: '',
      textAreaDriverState: '',
      textAreaEmployeeID: '',
      textAreaEmployeeProdId: '',
      textAreaEmployeeTicId: '',
      textAreaNationalProducerNo: '',
      textAreaPolicyBondNumber: '',
      textAreaQuote: '',
      textAreaVINNo_One: '',
      textAreaVINNo_Three: '',
      textAreaVINNo_Two: '',
      textAreatravID: '',
      travAgent: false,
      travEmp: false,
      travID: false,
      travPolicy: false,



    });
  }


  clearError = () => {
    this.state.formErrors.cssCity = '';
    this.state.formErrors.cssZipcode = '';
    this.state.formErrors.cssAddress = '';
    this.state.formErrors.city = '';
    this.state.formErrors.zipCode = '';
    this.state.formErrors.address = '';
    this.setState({
      addressValid: true,
      cityValid: true,
      zipCodeValid: true
    });
  }
  navigateToDeletedTrackingConfirmation(data) {

    this.setState({
      requestType: data.CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'Y' ? 'Access' : 'Delete',
      step: 12,
      trackingNo: data
    });
  }

  navigateToTrackingConfirmation(data, date) {
    this.setState({
      requestType: data.CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'Y' ? 'Delete' : 'Access',

      step: 7,
      trackingNo: data,
      trackingNoSubmissionDate: date
    });
  }

  navigateToAccessDeleteRequest(data) {
    this.setState({

      accessDeleteData: data,
      step: 13

    });
  }

  navigateToThankYou() {
    this.setState({
      step: 15
    });
  }

  getTrackingNumber(trackingNo, SubmittedDate, requestType) {

    this.setState({
      requestType: requestType,
      submitteddate: SubmittedDate,
      trackingNo: trackingNo

    });
  }
  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  // Handle fields change


  handleTrilliumAddress(data) {
    this.setState({
      address: data
    });
  }

  updateAddressProps(data) {
    this.setState({
      address: data.addressLine1,
      city: data.city,
      state: data.state,
      zipCode: data.zipcode

    });
  }

  handleUserInput = input => (e) => {
    const value = e.target.value;
    this.setState({ [input]: value }, () => { this.validateField(input, value); });

  }


  async componentDidMount() {
    let configs = await ReferenceService.configs();
    this.setState({ reCatchaKey: configs.getCaptchaKey });
    this.setState({ isInternal: configs.isInternal });
    if (window.location.pathname === '/ViewStatus' && !configs.isInternal) {
      this.navigateToViewStatusRequest();
    } else if (configs.isInternal) {
      if (!this.props.authState?.isAuthenticated) {
        this.oktaLogin();
        this.setState({
          isOktaAuthorized: false
        });
      } else {
        this.setState({
          isOktaAuthorized: true
        });
      }
    }
  }

  async oktaLogin() {
    await this.props?.oktaAuth?.token.getWithRedirect({
      scopes: ['openid', 'email', 'profile']
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
    if (value) {

      var elems = document.querySelectorAll('.tds-check--error');
      [].forEach.call(elems, function (el) {
        el.className = el.className.replace(/\btds-check--error\b/, 'tds-check--info');
      });
      document.getElementById('tdError').innerHTML = '';
      document.getElementById('tdError').classList.remove('tds-inline-alert--error');
    }
  }

  handleInputChange2(event) {

    const target = event.target;
    const value = target.type === 'radio' ? target.checked : target.value;
    const name = target.id;
    if (target.id === 'isGoingX') {
      this.setState({
        isGoingZ: false
      });
    }
    else {
      this.setState({
        isGoingX: false
      });
    }
    this.setState({
      [name]: value

    });

    if (value) {

      var elems = document.querySelectorAll('.tds-check--error');
      [].forEach.call(elems, function (el) {
        el.className = el.className.replace(/\btds-check--error\b/, 'tds-check--info');
      });
      document.getElementById('tdError').innerHTML = '';
      document.getElementById('tdError').classList.remove('tds-inline-alert--error');
    }

  }





  updateDisableDiv(data) {

    this.setState({
      isNoneOfThese: data
    });
  }


  handleRadioButtons(event) {
    this.props.updateCategory(event.target.value);
  }

  updateCategory = (e) => {
    if (e.target.checked) {
      this.props.updateCategory(e.target.value);
    }
  }

  handleCheckBoxChange(event) {

    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    const name = target.name;



    this.setState({
      [name]: value
    },
      () => {
        if (this.state.travID || this.state.quote || this.state.claim || this.state.travPolicy || this.state.currentPolicy || this.state.travAgent || this.state.travEmp) {

          this.setState({
            isNonDivDisabled: true
          });
          var elems = document.querySelectorAll('.tds-check--error');

          [].forEach.call(elems, function (el) {
            el.className = el.className.replace(/\btds-check--error\b/, 'tds-check--info');
          });
          document.getElementById('tdError').innerHTML = '';
          document.getElementById('tdError').classList.remove('tds-inline-alert--error');
        }

        else if (!this.state.travID && !this.state.quote && !this.state.claim && !this.state.travPolicy && !this.state.currentPolicy && !this.state.travAgent && !this.state.travEmp) {
          this.setState({
            isNonDivDisabled: false,

          });
        }
        if (this.state.none) {
          var elems = document.querySelectorAll('.tds-check--error');

          [].forEach.call(elems, function (el) {
            el.className = el.className.replace(/\btds-check--error\b/, 'tds-check--info');
          });
          document.getElementById('tdError').innerHTML = '';
          document.getElementById('tdError').classList.remove('tds-inline-alert--error');
        }

        if (name === 'none') {
          this.setState({

            isDivDisabled: !this.state.isDivDisabled
          }, () => {

            if (this.state.isDivDisabled) {
              this.ClearOtherFieldsData();
              document.getElementById('step-2form').reset();
            }

          });
        }
        if (!this.state.travID) {
          document.getElementById('textAreatravID').value = '';
          this.state.formErrors.cssTextAreatravID = '';
          this.state.formErrors.textAreatravID = '';
          this.setState({ isTravIdValid: true, textAreatravID: '' });
        }
        if (!this.state.quote) {
          document.getElementById('textAreaQuote').value = '';
          this.state.formErrors.cssTextAreaQuote = '';
          this.state.formErrors.textAreaQuote = '';
          this.setState({ isQuoteValid: true, textAreaQuote: '' });
        }
        if (!this.state.claim) {
          document.getElementById('textAreaClaim').value = '';
          this.state.formErrors.cssTextAreaClaim = '';
          this.state.formErrors.textAreaClaim = '';
          this.setState({ isClaimValid: true, textAreaClaim: '' });
        }
        if (!this.state.travPolicy) {
          document.getElementById('textAreaDriverLicenseNo').value = '';
          document.getElementById('textAreaDriverState').value = 'California';
          this.state.formErrors.cssTextDriverLicenseNo = '';
          this.state.formErrors.textAreaDriverLicenseNo = '';
          this.setState({ isDriverLiscenseValid: true, isDriverStateValid: true, textAreaDriverLicenseNo: '', textAreaDriverState: 'California' });
        }
        if (!this.state.currentPolicy) {
          document.getElementById('textAreaPolicyBondNumber').value = '';
          this.setState({ textAreaPolicyBondNumber: '' });
          this.state.formErrors.cssTextAreaPolicyBondNumber = '';
          this.state.formErrors.textAreaPolicyBondNumber = '';
          document.getElementById('textAreaAccountNo').value = '';
          this.setState({ textAreaAccountNo: '' });
          this.state.formErrors.cssTextAreaAccountNo = '';
          this.state.formErrors.textAreaAccountNo = '';
          document.getElementById('textAreaVINNo_One').value = '';
          this.setState({ textAreaVINNo_One: '' });
          this.state.formErrors.cssTextAreaVINNo_One = '';
          this.state.formErrors.textAreaVINNo_One = '';
          document.getElementById('textAreaVINNo_Two').value = '';
          this.setState({ textAreaVINNo_Two: '' });
          this.state.formErrors.cssTextAreaVINNo_Two = '';
          this.state.formErrors.textAreaVINNo_Two = '';
          document.getElementById('textAreaVINNo_Three').value = '';
          this.setState({ textAreaVINNo_Three: '' });
          this.state.formErrors.cssTextAreaVINNo_Three = '';
          this.state.formErrors.textAreaVINNo_Three = '';
          this.setState({

            isVinOnevalid: true,
            isVinThreevalid: true,
            isVinTwovalid: true
          });

        }
        if (!this.state.travID) {
          document.getElementById('textAreatravID').value = '';
          this.state.formErrors.cssTextAreatravID = '';
          this.state.formErrors.textAreatravID = '';
          this.setState({ isTravIdValid: true, textAreatravID: '' });
        }
        if (!this.state.quote) {
          document.getElementById('textAreaQuote').value = '';
          this.state.formErrors.cssTextAreaQuote = '';
          this.state.formErrors.textAreaQuote = '';
          this.setState({ isQuoteValid: true, textAreaQuote: '' });
        }
        if (!this.state.claim) {
          document.getElementById('textAreaClaim').value = '';
          this.state.formErrors.cssTextAreaClaim = '';
          this.state.formErrors.textAreaClaim = '';
          this.setState({ isClaimValid: true, textAreaClaim: '' });
        }
        if (!this.state.travPolicy) {
          document.getElementById('textAreaDriverLicenseNo').value = '';
          document.getElementById('textAreaDriverState').value = 'CA';
          this.state.formErrors.cssTextDriverLicenseNo = '';
          this.state.formErrors.textAreaDriverLicenseNo = '';
          this.setState({ isDriverLiscenseValid: true, isDriverStateValid: true, textAreaDriverLicenseNo: '', textAreaDriverState: '' });
        }
        if (!this.state.currentPolicy) {
          document.getElementById('textAreaPolicyBondNumber').value = '';
          this.setState({ textAreaPolicyBondNumber: '' });
          this.state.formErrors.cssTextAreaPolicyBondNumber = '';
          this.state.formErrors.textAreaPolicyBondNumber = '';
          document.getElementById('textAreaAccountNo').value = '';
          this.setState({ textAreaAccountNo: '' });
          this.state.formErrors.cssTextAreaAccountNo = '';
          this.state.formErrors.textAreaAccountNo = '';
          document.getElementById('textAreaVINNo_One').value = '';
          this.setState({ textAreaVINNo_One: '' });
          this.state.formErrors.cssTextAreaVINNo_One = '';
          this.state.formErrors.textAreaVINNo_One = '';
          document.getElementById('textAreaVINNo_Two').value = '';
          this.setState({ textAreaVINNo_Two: '' });
          this.state.formErrors.cssTextAreaVINNo_Two = '';
          this.state.formErrors.textAreaVINNo_Two = '';
          document.getElementById('textAreaVINNo_Three').value = '';
          this.setState({ textAreaVINNo_Three: '' });
          this.state.formErrors.cssTextAreaVINNo_Three = '';
          this.state.formErrors.textAreaVINNo_Three = '';
          this.setState({

            isVinOnevalid: true,
            isVinThreevalid: true,
            isVinTwovalid: true
          });
        }
        if (!this.state.travAgent) {
          document.getElementById('textAreaNationalProducerNo').value = '';
          this.setState({ textAreaNationalProducerNo: '' });
          this.state.formErrors.cssTextAreaNationalProducerNo = '';
          this.state.formErrors.textAreaNationalProducerNo = '';
          document.getElementById('textAreaAgentCode').value = '';
          this.setState({ textAreaAgentCode: '' });
          this.state.formErrors.cssTextAreaAgentCode = '';
          this.state.formErrors.textAreaAgentCode = '';
          this.setState({
            isAccountvalid: true,
            isNPNValid: true,

          });
        }
        if (!this.state.travEmp) {

          document.getElementById('textAreaEmployeeID').value = '';
          this.setState({ textAreaEmployeeID: '' });
          this.state.formErrors.cssTextAreaEmployeeID = '';
          this.state.formErrors.textAreaEmployeeID = '';
          document.getElementById('textAreaEmployeeProdId').value = '';
          this.setState({ textAreaEmployeeProdId: '' });
          this.state.formErrors.cssTextAreaEmployeeTicId = '';
          this.state.formErrors.textAreaEmployeeTicId = '';
          document.getElementById('textAreaEmployeeProdId').value = '';
          document.getElementById('textAreaEmployeeTicId').value = '';
          this.setState({ textAreaEmployeeTicId: '' });
          this.state.formErrors.cssTextAreaEmployeeTicId = '';
          this.state.formErrors.textAreaEmployeeTicId = '';
          this.setState({
            isEmpIdvalid: true,
            isShortNamevalid: true,
            isTicIdValid: true

          });
        }
      }

    );
  }

  handleInput = input => (e) => {

    const value = e.target.value;

    this.setState({ [input]: value }, () => { this.validateTravRel(input, value); });

  }
  leftBlank = input => (e) => {
    const value = e.target.value;
    const name = e.target.name;
    this.setState({ [input]: value },

      () => {
        this.forBlank(input, value, name);

      });
  }
  forBlank(fieldName, value, name) {
    switch (fieldName) {
      case 'firstName':
        if (value === '') {
          this.state.formErrors.cssFirstName = 'tds-field--error';
          this.state.formErrors.firstName = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssFirstName = '';
          this.state.formErrors.firstName = '';
          this.validateField(fieldName, value);
        }
        break;
      case 'lastName':
        if (value === '') {
          this.state.formErrors.cssLastName = 'tds-field--error';
          this.state.formErrors.lastName = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssLastName = '';
          this.state.formErrors.lastName = '';
          this.validateField(fieldName, value);
        }
        break;
      case 'ssn':
        if (value === '') {
          this.state.formErrors.cssSsn = 'tds-field--error';
          this.state.formErrors.ssn = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssSsn = '';
          this.state.formErrors.ssn = '';
        }
        break;



      case 'dob':
        const month = document.getElementById('month').value;
        if (month.length === 2 && name === 'month') {
          document.getElementById('day').focus();
        }
        const day = document.getElementById('day').value;

        if (day.length === 2 && name === 'day') {
          document.getElementById('year').focus();
        }


        if (value === '') {
          this.state.formErrors.cssDob = 'tds-field--error';
          this.state.formErrors.dob = 'Cannot be left blank.';

        }

        else {
          this.state.formErrors.cssDob = '';
          this.state.formErrors.dob = '';
        }

        break;
      case 'address':
        if (value === '') {
          this.state.formErrors.cssAddress = 'tds-field--error';
          this.state.formErrors.address = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssAddress = '';
          this.state.formErrors.address = '';
        }
        break;
      case 'city':
        if (value === '') {
          this.state.formErrors.cssCity = 'tds-field--error';
          this.state.formErrors.city = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssCity = '';
          this.state.formErrors.city = '';
        }
        break;
      case 'zipCode':
        if (value === '') {
          this.state.formErrors.cssZipcode = 'tds-field--error';
          this.state.formErrors.zipCode = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssZipcode = '';
          this.state.formErrors.zipCode = '';
        }
        break;
      case 'phoneNo':
        if (value === '') {
          this.state.formErrors.cssPhoneNo = 'tds-field--error';
          this.state.formErrors.phoneNo = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssPhoneNo = '';
          this.state.formErrors.phoneNo = '';
        }
        break;
      case 'email':
        if (value === '' && !this.state.isInternal) {
          this.state.formErrors.cssEmailMsg = 'tds-field--error';
          this.state.formErrors.email = 'Cannot be left blank.';
        } else {
          this.state.formErrors.cssEmailMsg = '';
          this.state.formErrors.email = '';
          this.state.formErrors.cssEmailMsg = '';
          this.state.formErrors.emailMsg = '';
        }
        if (value.toLocaleLowerCase() === this.state.confirmEmail.toLocaleLowerCase()) {
          this.setState({
            confirmEmailValid: true
          });
          this.state.formErrors.cssConfirmEmail = '';
          this.state.formErrors.confirmEmail = '';
        }
        if (this.state.confirmEmail != '' && value != '') {
          if (value.toLocaleLowerCase() !== this.state.confirmEmail.toLocaleLowerCase()) {
            this.setState({
              confirmEmailValid: false
            });
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Email address must match.';
          }
        }
        break;
      case 'confirmEmail':
        if (value === '') {
          this.state.formErrors.cssConfirmEmail = 'tds-field--error';
          this.state.formErrors.confirmEmail = 'Cannot be left blank.';

        } else {
          this.state.formErrors.cssConfirmEmail = '';
          this.state.formErrors.confirmEmail = '';
        }
        if (value.toLocaleLowerCase() === this.state.email.toLocaleLowerCase()) {
          this.setState({
            confirmEmailValid: true
          });
          this.state.formErrors.cssConfirmEmail = '';
          this.state.formErrors.confirmEmail = '';
        }
        break;



      default:
        break;
    }
  }


  validateField(fieldName, value) {

    let fieldValidationErrors = this.state.formErrors;
    const formErrors1 = this.state.formErrors;
    let firstNameValid = this.state.firstNameValid;
    let lastNameValid = this.state.lastNameValid;
    let phoneNoValid = this.state.phoneNoValid;
    let socialSecurityNoValid = this.state.socialSecurityNoValid;
    let zipCodeValid = this.state.zipCodeValid;
    let dobValid = this.state.dobValid;
    let addressValid = this.state.addressValid;
    let cityValid = this.state.cityValid;
    let emailValid = this.state.emailValid;
    let confirmEmailValid = this.state.confirmEmailValid;


    switch (fieldName) {
      case 'firstName':
        const number = value.replace(
          /(^\s*)|(\s*$)/gi, ''); this.setState({ firstName: number });

        if (!number || number === '') {
          this.state.formErrors.cssFirstName = 'tds-field--error';
          this.state.formErrors.firstName = 'Cannot be left blank.';
          document.getElementById('tbfirstName').scrollIntoView();
          this.setState({
            firstNameValid: false
          });

        }
        else if (number.match(/^[a-zA-Z’'-\s]{1,30}$/i)) {
          this.state.formErrors.cssFirstName = '';
          this.state.formErrors.firstName = '';
          firstNameValid = true;

        } else {
          firstNameValid = false;
          this.state.formErrors.cssFirstName = 'tds-field--error';
          this.state.formErrors.firstName = 'Please enter valid information.';
          document.getElementById('tbfirstName').scrollIntoView();
        }


        break;

      case 'lastName':
        const lName = value.replace(
          /(^\s*)|(\s*$)/gi, ''); this.setState({ lastName: lName });
        if (!lName || lName === '') {
          this.state.formErrors.cssLastName = 'tds-field--error';
          this.state.formErrors.lastName = 'Cannot be left blank.';
        } else if (lName.match(/^[a-zA-Z’'-\s]{1,30}$/i)) {
          this.state.formErrors.cssLastName = '';
          this.state.formErrors.lastName = '';
          lastNameValid = true;
        } else {
          lastNameValid = false;
          this.state.formErrors.cssLastName = 'tds-field--error';
          this.state.formErrors.lastName = 'Please enter valid information.';
        }
        break;
      case 'phoneNo':

        let _phoneNumber;
        if (value !== '') {
          _phoneNumber = value.replace('(', '');
          _phoneNumber = _phoneNumber.replace(')', '');
          _phoneNumber = _phoneNumber.replace(/ /g, '');
          _phoneNumber = _phoneNumber.replace(/-/g, '');
          _phoneNumber = _phoneNumber.replace(/_/g, '');


        }
        if (value === '') {
          phoneNoValid = false;
          this.state.formErrors.cssPhoneNo = 'tds-field--error';
          this.state.formErrors.phoneNo = 'Cannot be left blank.';
        }
        else if (_phoneNumber.length !== 10) {
          phoneNoValid = false;
          this.state.formErrors.cssPhoneNo = 'tds-field--error';
          this.state.formErrors.phoneNo = 'Please enter valid information';
        }

        else {
          this.state.formErrors.cssPhoneNo = '';
          this.state.formErrors.phoneNo = '';
          phoneNoValid = true;
          const number = value.replace(
            /(\d{3})(\d{3})(\d{4})/,
            '($1) $2-$3'
          ); this.setState({ phoneNo: number });
        }
        break;

      case 'ssn':
        let social = value;
        if (value === '') {
          socialSecurityNoValid = false;
          this.state.formErrors.cssSsn = 'tds-field--error';
          this.state.formErrors.ssn = 'Cannot be left blank.';
        } else if (value.match(/^\d{3}\d{2}\d{4}$/)) {
          this.state.formErrors.cssSsn = '';
          this.state.formErrors.ssn = '';
          socialSecurityNoValid = true;
          this.setState({ ssnVri: value });
          this.setState({ ssn: social });
        } else {
          socialSecurityNoValid = false;
          this.state.formErrors.cssSsn = 'tds-field--error';
          this.state.formErrors.ssn = 'Please enter valid information.';
          document.getElementById('tblastName').scrollIntoView();
        }
        break;

      case 'zipCode':

        if (value === '') {
          this.state.formErrors.cssZipcode = 'tds-field--error';
          this.state.formErrors.zipCode = 'Cannot be left blank.';
          this.state.formErrors.zipCodeMsg = '';
          zipCodeValid = false;
          this.state.zipCodeInvalid = true;
        } else if (value.match(/^(9)[0-9]{4}$/)) {
          this.state.formErrors.cssZipcode = '';
          this.state.formErrors.zipCode = '';
          this.state.formErrors.zipCodeMsg = '';
          zipCodeValid = true;
          this.state.zipCodeInvalid = true;


        } else if (value.length !== 5) {
          this.state.formErrors.cssZipcode = 'tds-field--error';
          this.state.formErrors.zipCode = 'Please enter valid information.';
          this.state.formErrors.zipCodeMsg = '';
          this.state.zipCodeInvalid = true;
          zipCodeValid = false;
          document.getElementById('tbcity').scrollIntoView();
        } else {
          this.state.zipCodeInvalid = false;
          this.state.formErrors.cssZipcode = '';
          this.state.formErrors.zipCode = '';

          window.scrollTo(0, 0);
        }
        break;

      case 'dob':

        let month = '';
        let day = '';
        let year = '';
        let finalDate = new Date();
        if (document.getElementById('month')) {
          month = document.getElementById('month').value;
          if (month.length < 2 && month.length > 0) {
            month = '0' + month;
            document.getElementById('month').value = month;
          }
        }
        if (document.getElementById('day')) {
          day = document.getElementById('day').value;
          if (day.length < 2 && day.length > 0) {
            day = '0' + day;
            document.getElementById('day').value = day;
          }
        }
        if (document.getElementById('year')) {
          year = document.getElementById('year').value;

        }
        if (document.getElementById('month') && document.getElementById('day') && document.getElementById('year')) {
          finalDate = year + '-' + month + '-' + day;
        }

        this.setState({
          dob: finalDate
        });

        var tdate = new Date();
        tdate.setHours(0, 0);
        tdate.setMinutes(0, 0);
        tdate.setSeconds(0, 0);
        tdate.setMilliseconds(0, 0);

        var bdate = new Date(finalDate);
        bdate.setHours(0, 0);
        bdate.setMinutes(0, 0);
        bdate.setSeconds(0, 0);
        bdate.setMilliseconds(0, 0);
        bdate.setDate(bdate.getDate() + 1);

        if (value === '') {
          dobValid = false;
          this.state.formErrors.cssDob = 'tds-field--error';
          this.state.formErrors.dob = 'Please enter a valid date.';

        }
        if ((month !== '' && day === '' && year !== '') || (month === '' && day !== '' && year !== '')) {
          dobValid = false;
          this.state.formErrors.cssDob = 'tds-field--error';
          this.state.formErrors.dob = 'Please enter a valid date.';
          document.getElementById('tblastName').scrollIntoView();
        }
        if (month && day && year) {

          var yr = year.match(/^20|19(0|1[1-9]|[0-9][0-9])$/);
          if ((bdate - tdate > 0) || (day === ('31') && (['4', '6', '9', '11', '04', '06', '09'].includes(month)))) {
            dobValid = false;
            this.state.formErrors.cssDob = 'tds-field--error';
            this.state.formErrors.dob = 'Please enter a valid date.';
            document.getElementById('tblastName').scrollIntoView();
          } else if (month === ('2') || month === ('02')) {
            //leap year

            if (year % 4 === 0 && yr) {
              if ((parseInt(day) >= 30) || (year % 4 === 0 && !yr)) {
                dobValid = false;
                this.state.formErrors.cssDob = 'tds-field--error';
                this.state.formErrors.dob = 'Please enter a valid date.';

              } else {
                this.state.formErrors.cssDob = '';
                this.state.formErrors.dob = '';
                dobValid = true;
              }
            } else {
              if (parseInt(day) >= 29) {
                dobValid = false;
                this.state.formErrors.cssDob = 'tds-field--error';
                this.state.formErrors.dob = 'Please enter a valid date.';

              } else {
                this.state.formErrors.cssDob = '';
                this.state.formErrors.dob = '';

                dobValid = true;

              }
            }
          }
          else if (tdate < bdate) {
            dobValid = false;
            document.getElementById('tblastName').scrollIntoView();
            this.state.formErrors.cssDob = 'tds-field--error';
            this.state.formErrors.dob = 'Please enter a valid date.';

          }

          else if (parseInt(month) > 12 || parseInt(day) > 31 || !yr || year.length !== 4) {
            dobValid = false;
            this.state.formErrors.cssDob = 'tds-field--error';
            this.state.formErrors.dob = 'Please enter a valid date.';
            document.getElementById('tblastName').scrollIntoView();

          }
          else if (month.toString() <= ('0') || month.toString() <= ('00') || day.toString() <= ('0') || day.toString() <= ('00')) {
            dobValid = false;
            document.getElementById('tblastName').scrollIntoView();
            this.state.formErrors.cssDob = 'tds-field--error';
            this.state.formErrors.dob = 'Please enter a valid date.';

          }

          else {
            this.state.formErrors.cssDob = '';
            this.state.formErrors.dob = '';

            dobValid = true;

          }

        }
        break;

      case 'address':
        const addr = value.replace(
          /(^\s*)|(\s*$)/gi, ''); this.setState({ address: addr });
        if (addr === '') {
          addressValid = false;
          this.state.formErrors.cssAddress = 'tds-field--error';
          this.state.formErrors.address = 'Cannot be left blank.';
          document.getElementById('tbssn').scrollIntoView();
        } else if (addr.match(/[A-Za-z0-9@~`!@#$%^&*()_=+';:"/?>.<-]*$/)) {
          this.state.formErrors.cssAddress = '';
          this.state.formErrors.address = '';
          addressValid = true;
        } else {
          addressValid = false;
          this.state.formErrors.cssAddress = 'tds-field--error';
          this.state.formErrors.address = 'Please enter valid information.';
        }
        break;



      case 'city':
        const space = value.replace(
          /(^\s*)|(\s*$)/gi, ''); this.setState({ city: space });
        if (space === '') {
          this.state.formErrors.cssCity = 'tds-field--error';
          this.state.formErrors.city = 'Cannot be left blank.';
        } else if (space.match(/^\b(?!.*?\s{2})[A-Za-z ]{1,24}\b$/i)) {
          this.state.formErrors.cssCity = '';
          this.state.formErrors.city = '';
          cityValid = true;
        } else {
          cityValid = false;
          this.state.formErrors.cssCity = 'tds-field--error';
          this.state.formErrors.city = 'Please enter valid information.';
          document.getElementById('tbaddress').scrollIntoView();
        }
        break;

      case 'email':

        if (this.state.isInternal) {
          let eReg = value.match(/^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-\.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+[a-zA-Z0-9\-\.]*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/);

          if (value === '' && this.state.confirmEmail === '' && !value && !this.state.confirmEmail) {
            this.state.emailInvalid = false;
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.emailMsg = '';
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.email = '';
            this.state.formErrors.cssConfirmEmail = '';
            this.state.formErrors.confirmEmail = '';
            emailValid = true;

          }
          else if (eReg && value.toLocaleLowerCase() === this.state.confirmEmail.toLocaleLowerCase()) {
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.email = '';
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.emailMsg = '';
            this.state.formErrors.cssConfirmEmail = '';
            this.state.formErrors.confirmEmail = '';
            emailValid = true;
            confirmEmailValid = true;
            this.state.emailInvalid = true;
          }
          else if (eReg && this.state.confirmEmail === '') {
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.email = '';
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.emailMsg = '';
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Cannot be left blank.';
            confirmEmailValid = false;
            emailValid = true;
            this.state.emailInvalid = true;
          }
          else if (this.state.confirmEmail !== '' && value === '') {
            this.state.formErrors.cssEmailMsg = 'tds-field--error';
            this.state.formErrors.emailMsg = 'Cannot be left blank.';
            emailValid = false;
          }

          else if (!eReg) {
            this.state.formErrors.cssEmailMsg = 'tds-field--error';
            this.state.formErrors.emailMsg = 'Please enter valid information.';
            emailValid = false;
          }
          else if (value !== this.state.confirmEmail) {
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Email address must match.';
            confirmEmailValid = false;
          }
        }
        else {
          if (value === '') {
            this.state.emailInvalid = false;
            emailValid = false;
            this.state.formErrors.emailMsg = 'Cannot be left blank.';
            this.state.formErrors.cssEmailMsg = 'tds-field--error';
            this.state.formErrors.email = 'Your email address is required to proceed. If you dont want to include your email, please call us at 1-555-555-5555 from M-F, 8am - 5pm ET to continue submitting this request.';
            window.scrollTo(0, 0);
          } else if (value.match(/^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-\.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+[a-zA-Z0-9\-\.]*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/)) {
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.email = '';
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.emailMsg = '';
            emailValid = true;
            this.state.emailInvalid = true;

          } else if (value.match(/^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-\.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+[a-zA-Z0-9\-\.]*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/) && value.toLocaleLowerCase() !== this.state.confirmEmail.toLocaleLowerCase()) {
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Email address must match.';
            confirmEmailValid = false;
          }
          else {
            emailValid = false;
            this.state.formErrors.cssEmailMsg = 'tds-field--error';
            this.state.formErrors.emailMsg = 'Please enter valid information.';
            this.state.formErrors.email = '';
          }
        }
        break;

      case 'confirmEmail':

        let email = this.state.email;
        let eReg2 = this.state.email.match(/^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-\.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+[a-zA-Z0-9\-\.]*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/);

        let confirmEmail = value;

        if (this.state.isInternal) {
          if (confirmEmail === '' && this.state.email === '') {
            this.state.formErrors.cssConfirmEmail = '';
            this.state.formErrors.confirmEmail = '';
            this.state.formErrors.cssEmailMsg = '';
            this.state.formErrors.emailMsg = '';
          }
          if (confirmEmail !== '' && this.state.email === '') {
            this.state.formErrors.cssConfirmEmail = '';
            this.state.formErrors.confirmEmail = '';
            this.state.formErrors.cssEmailMsg = 'tds-field--error';
            this.state.formErrors.emailMsg = 'Cannot be left blank.';
          }
          if (email !== '' && eReg2) {
            if (email.toLocaleLowerCase() === confirmEmail.toLocaleLowerCase()) {
              this.state.formErrors.email = '';
              this.state.formErrors.cssEmailMsg = '';
              this.state.formErrors.emailMsg = '';
              emailValid = true;
              this.state.emailInvalid = true;
              this.state.formErrors.cssConfirmEmail = '';
              this.state.formErrors.confirmEmail = '';
              confirmEmailValid = true;
              this.state.confirmEmailValidEnable = true;
            }

            else if (confirmEmail === '') {
              this.state.formErrors.cssConfirmEmail = 'tds-field--error';
              this.state.formErrors.confirmEmail = 'Cannot be left blank.';
              confirmEmailValid = false;
            }

            else if (email.toLocaleLowerCase() !== confirmEmail.toLocaleLowerCase()) {
              this.state.formErrors.cssConfirmEmail = 'tds-field--error';
              this.state.formErrors.confirmEmail = 'Email address must match.';
              confirmEmailValid = false;
              this.state.confirmEmailValidEnable = false;
            }
            else {
              this.state.formErrors.cssConfirmEmail = '';
              this.state.formErrors.confirmEmail = '';
              confirmEmailValid = true;
              this.state.confirmEmailValidEnable = true;
            }
          }

        } else {
          let eReg3 = this.state.email.match(/^(?=^.{6,60}$)((^[a-zA-Z0-9])+(?!.*?[.]{2})([\w\-\.])*)@((?!.*--)(?!.*?[.]{2})(?:[a-zA-Z0-9])+[a-zA-Z0-9\-\.]*)\.([a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?)$/);
          if (confirmEmail === '') {
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Cannot be left blank.';
            confirmEmailValid = false;
            this.state.confirmEmailValidEnable = false;
          }

          else if (email.toLocaleLowerCase() === confirmEmail.toLocaleLowerCase()) {
            this.state.formErrors.cssConfirmEmail = '';
            this.state.formErrors.confirmEmail = '';
            confirmEmailValid = true;
            this.state.confirmEmailValidEnable = true;


          } else if (eReg3 && email.toLocaleLowerCase() !== confirmEmail.toLocaleLowerCase()) {
            this.state.formErrors.cssConfirmEmail = 'tds-field--error';
            this.state.formErrors.confirmEmail = 'Email address must match.';
            confirmEmailValid = false;
            this.state.confirmEmailValidEnable = false;
          }
        }
        break;

      default:
        break;
    }

    this.setState({
      addressValid: addressValid,
      cityValid: cityValid,
      confirmEmailValid: confirmEmailValid,
      dobValid: dobValid,
      emailValid: emailValid,
      firstNameValid: firstNameValid,
      formErrors: fieldValidationErrors,
      lastNameValid: lastNameValid,
      phoneNoValid: phoneNoValid,
      socialSecurityNoValid: socialSecurityNoValid,
      zipCodeValid: zipCodeValid
    },
    );

  }

  forEnable() {
    if (this.state.isInternal) {
      if (!this.state.confirmEmailValid) {
        document.getElementById('tbemail').scrollIntoView();
      }
      if (!this.state.emailValid) {
        document.getElementById('tbphoneNo').scrollIntoView();
      }
      if (this.state.phoneNo === '') {
        this.setState({
          phoneNoValid: false
        });
        this.state.formErrors.cssPhoneNo = 'tds-field--error';
        this.state.formErrors.phoneNo = 'Cannot be left blank.';
        document.getElementById('tbzipCode').scrollIntoView();
      }
      if (!this.state.phoneNoValid) {
        document.getElementById('tbzipCode').scrollIntoView();
      }

      if (this.state.zipCode === '') {
        this.setState({
          zipCodeValid: false
        });
        this.state.formErrors.cssZipcode = 'tds-field--error';
        this.state.formErrors.zipCode = 'Cannot be left blank.';
        document.getElementById('tbcity').scrollIntoView();
      }
      if (!this.state.zipCodeValid) {
        document.getElementById('tbcity').scrollIntoView();
      }
      if (!this.state.zipCodeInvalid) {
        window.scrollTo(0, 0);

      }


      if (this.state.city === '') {
        this.setState({
          cityValid: false
        });
        this.state.formErrors.cssCity = 'tds-field--error';
        this.state.formErrors.city = 'Cannot be left blank.';
        document.getElementById('tbaddress').scrollIntoView();
      }

      if (!this.state.cityValid) {
        document.getElementById('tbaddress').scrollIntoView();
      }

      if (this.state.address === '') {
        this.setState({
          addressValid: false
        });
        this.state.formErrors.cssAddress = 'tds-field--error';
        this.state.formErrors.address = 'Cannot be left blank.';
        document.getElementById('date-input-0').scrollIntoView();
      }

      if (!this.state.addressValid) {
        document.getElementById('date-input-0').scrollIntoView();
      }

      if (this.state.dob === '') {
        this.setState({
          dobValid: false
        });
        this.state.formErrors.cssDob = 'tds-field--error';
        this.state.formErrors.dob = 'Please enter a valid date.';
        document.getElementById('tblastName').scrollIntoView();
      }

      if (!this.state.dobValid) {
        document.getElementById('tblastName').scrollIntoView();
      }

      if (this.state.ssn === '') {
        this.setState({
          socialSecurityNoValid: false
        });
        this.state.formErrors.cssSsn = 'tds-field--error';
        this.state.formErrors.ssn = 'Cannot be left blank.';
        document.getElementById('tblastName').scrollIntoView();
      }

      if (!this.state.socialSecurityNoValid) {
        document.getElementById('tblastName').scrollIntoView();
      }

      if (this.state.lastName === '') {
        this.setState({
          lastNameValid: false
        });
        this.state.formErrors.cssLastName = 'tds-field--error';
        this.state.formErrors.lastName = 'Cannot be left blank.';
        document.getElementById('tbfirstName').scrollIntoView();
      }
      if (!this.state.lastNameValid) {
        document.getElementById('tbfirstName').scrollIntoView();
      }

      if (this.state.firstName === '') {
        this.setState({
          firstNameValid: false
        });
        this.state.formErrors.cssFirstName = 'tds-field--error';
        this.state.formErrors.firstName = 'Cannot be left blank.';
        document.getElementById('tb').scrollIntoView();
      }

      if (!this.state.firstNameValid) {
        document.getElementById('tb').scrollIntoView();
      }

    }

    else {
      if (this.state.confirmEmail === '') {
        this.setState({
          confirmEmailValid: false
        });
        this.state.formErrors.cssConfirmEmail = 'tds-field--error';
        this.state.formErrors.confirmEmail = 'Cannot be left blank.';
        document.getElementById('tbemail').scrollIntoView();
      }
      if (!this.state.confirmEmailValid) {
        document.getElementById('tbemail').scrollIntoView();
      }
      if (this.state.email === '') {
        this.setState({
          emailInvalid: false,
          emailValid: false
        });
        this.state.formErrors.cssEmailMsg = 'tds-field--error';
        this.state.formErrors.emailMsg = 'Cannot be left blank.';
        window.scrollTo(0, 0);
      }
      if (!this.state.emailValid) {
        document.getElementById('tbphoneNo').scrollIntoView();
      }
      if (this.state.phoneNo === '') {
        this.setState({
          phoneNoValid: false
        });
        this.state.formErrors.cssPhoneNo = 'tds-field--error';
        this.state.formErrors.phoneNo = 'Cannot be left blank.';
        document.getElementById('tbzipCode').scrollIntoView();
      }
      if (!this.state.phoneNoValid) {
        document.getElementById('tbzipCode').scrollIntoView();
      }
      if (this.state.zipCode === '') {
        this.setState({
          zipCodeInValid: false
        });
        this.state.formErrors.cssZipcode = 'tds-field--error';
        this.state.formErrors.zipCode = 'Cannot be left blank.';
        document.getElementById('tbcity').scrollIntoView();
      }

      if (!this.state.zipCodeValid) {
        document.getElementById('tbcity').scrollIntoView();
      }
      if (!this.state.zipCodeInvalid) {
        window.scrollTo(0, 0);
      }
      if (this.state.city === '') {
        this.setState({
          cityValid: false
        });
        this.state.formErrors.cssCity = 'tds-field--error';
        this.state.formErrors.city = 'Cannot be left blank.';
        document.getElementById('tbaddress').scrollIntoView();
      }
      if (!this.state.cityValid) {
        document.getElementById('tbaddress').scrollIntoView();
      }
      if (this.state.address === '') {
        this.setState({
          addressValid: false
        });
        this.state.formErrors.cssAddress = 'tds-field--error';
        this.state.formErrors.address = 'Cannot be left blank.';
        document.getElementById('date-input-0').scrollIntoView();
      }
      if (!this.state.addressValid) {
        document.getElementById('date-input-0').scrollIntoView();
      }
      if (this.state.dob === '') {
        this.setState({
          dobValid: false
        });
        this.state.formErrors.cssDob = 'tds-field--error';
        this.state.formErrors.dob = 'Please enter a valid date.';
        document.getElementById('tbssn').scrollIntoView();
      }
      if (!this.state.dobValid) {
        document.getElementById('tblastName').scrollIntoView();
      }
      if (this.state.ssn === '') {
        this.setState({
          socialSecurityNoValid: false
        });
        this.state.formErrors.cssSsn = 'tds-field--error';
        this.state.formErrors.ssn = 'Cannot be left blank.';
        document.getElementById('tblastName').scrollIntoView();
      }
      if (!this.state.socialSecurityNoValid) {
        document.getElementById('tblastName').scrollIntoView();
      }
      if (this.state.lastName === '') {
        this.setState({
          lastNameValid: false
        });
        this.state.formErrors.cssLastName = 'tds-field--error';
        this.state.formErrors.lastName = 'Cannot be left blank.';
        document.getElementById('tbfirstName').scrollIntoView();
      }
      if (!this.state.lastNameValid) {
        document.getElementById('tbfirstName').scrollIntoView();
      }
      if (this.state.firstName === '') {
        this.setState({
          firstNameValid: false
        });
        this.state.formErrors.cssFirstName = 'tds-field--error';
        this.state.formErrors.firstName = 'Cannot be left blank.';
        document.getElementById('tb').scrollIntoView();
      }
      if (!this.state.firstNameValid) {
        document.getElementById('tb').scrollIntoView();
      }
    }
  }

  validateForm() {
    if (this.state.isInternal) {
      this.forEnable();
      this.setState({
        formValid: this.state.firstNameValid &&
          this.state.firstName &&
          this.state.lastNameValid &&
          this.state.lastName &&
          this.state.phoneNoValid &&
          this.state.phoneNo &&
          this.state.socialSecurityNoValid &&
          this.state.ssn &&
          this.state.zipCodeValid &&
          this.state.zipCode &&
          this.state.dobValid &&
          this.state.dob &&
          this.state.addressValid &&
          this.state.address &&
          this.state.cityValid
          && this.state.city
      });
    } else {
      this.forEnable();
      this.setState({
        formValid: this.state.firstNameValid &&
          this.state.lastNameValid &&
          this.state.phoneNoValid &&
          this.state.socialSecurityNoValid &&
          this.state.zipCodeValid &&
          this.state.dobValid &&
          this.state.addressValid &&
          this.state.cityValid &&
          this.state.emailValid &&
          this.state.confirmEmailValid &&
          this.state.confirmEmail !== '' &&
          this.state.email !== '' &&
          this.state.confirmEmail === this.state.email
      });
    }
  }


  cannotbeLeftBlank = input => (e) => {
    const value = e.target.value;
    this.setState({ [input]: value }, () => { this.Blank(input, value); });
  }

  Blank(fieldName, value) {
    switch (fieldName) {
      default:
        break;
    }
  }

  validateTravRel(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let textAreatravIDValid = this.state.textAreatravIDValid;
    let textAreaQuoteValid = this.state.textAreaQuoteValid;
    let textAreaClaimValid = this.state.textAreaClaimValid;
    let textAreaDriverLicenseNoValid = this.state.textAreaDriverLicenseNoValid;
    let textAreaPolicyBondNumberValid = this.state.textAreaPolicyBondNumberValid;
    let textAreaAccountNoValid = this.state.textAreaAccountNoValid;
    let textAreaVINNo_OneValid = this.state.textAreaVINNo_OneValid;
    let textAreaVINNo_ThreeValid = this.state.textAreaVINNo_ThreeValid;
    let textAreaVINNo_TwoValid = this.state.textAreaVINNo_TwoValid;
    let textAreaNationalProducerNoValid = this.state.textAreaNationalProducerNoValid;
    let textAreaAgentCodeValid = this.textAreaAgentCodeValid;
    let textAreaEmployeeIDValid = this.state.textAreaEmployeeIDValid;
    let textAreaEmployeeProdIdValid = this.state.textAreaEmployeeProdIdValid;
    let textAreaEmployeeTicIdValid = this.state.textAreaEmployeeTicIdValid;
    let textAreaDriverStateValid = this.state.textAreaDriverStateValid;

    switch (fieldName) {
      case 'textAreaDriverState':
        this.setState({ isDriverStateValid: true, textAreaDriverState: value });
        break;

      case 'textAreatravID':

        const textAreatravID = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreatravID: textAreatravID });


        if ((textAreatravID === '') || (textAreatravID.match(/^[a-zA-Z0-9@\-\.]{1,60}$/))) {

          this.state.formErrors.cssTextAreatravID = '';
          this.state.formErrors.textAreatravID = '';
          textAreatravIDValid = true;
          this.setState({ isTravIdValid: true });
        } else {
          this.state.formErrors.cssTextAreatravID = 'tds-field--error';
          this.state.formErrors.textAreatravID = 'Please enter a valid Trav ID';
          document.getElementById('T1ID').scrollIntoView();
          this.setState({ isTravIdValid: false });
        }
        break;

      case 'textAreaQuote':

        const textAreaQuote = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaQuote: textAreaQuote });

        if (textAreaQuote === '') {
          this.state.formErrors.cssTextAreaQuote = '';
          this.state.formErrors.textAreaQuote = '';
          this.setState({ isQuoteValid: true });
        }
        else
          if (textAreaQuote.match(/^[a-zA-Z0-9]{1,10}$/)) {
            this.state.formErrors.cssTextAreaQuote = '';
            this.state.formErrors.textAreaQuote = '';
            textAreaQuoteValid = true;
            this.setState({ isQuoteValid: true });
          }
          else {
            this.state.formErrors.cssTextAreaQuote = 'tds-field--error';
            this.state.formErrors.textAreaQuote = 'Please enter a valid Quote Number';
            document.getElementById('QID').scrollIntoView();
            this.setState({ isQuoteValid: false });

          }
        break;

      case 'textAreaClaim':

        const textAreaClaim = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaClaim: textAreaClaim });

        if (textAreaClaim === '') {
          this.state.formErrors.cssTextAreaClaim = '';
          this.state.formErrors.textAreaClaim = '';
          this.setState({ isClaimValid: true });
        }
        else
          if (textAreaClaim.match(/^[a-zA-Z0-9]{1,21}$/)) {
            this.state.formErrors.cssTextAreaClaim = '';
            this.state.formErrors.textAreaClaim = '';
            textAreaClaimValid = true;
            this.setState({ isClaimValid: true });
          }
          else {
            this.state.formErrors.cssTextAreaClaim = 'tds-field--error';
            this.state.formErrors.textAreaClaim = 'Please enter a valid Claim Number';
            document.getElementById('CID').scrollIntoView();
            this.setState({ isClaimValid: false });
          }
        break;

      case 'textAreaDriverLicenseNo':

        const textAreaDriverLicenseNo = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaDriverLicenseNo: textAreaDriverLicenseNo });


        if (textAreaDriverLicenseNo === '') {
          this.state.formErrors.cssTextDriverLicenseNo = '';
          this.state.formErrors.textAreaDriverLicenseNo = '';
          this.setState({ isDriverLiscenseValid: true });
        }
        else
          if (textAreaDriverLicenseNo.match(/^[a-zA-Z0-9]{1,30}$/)) {
            this.state.formErrors.cssTextDriverLicenseNo = '';
            this.state.formErrors.textAreaDriverLicenseNo = '';
            textAreaDriverLicenseNoValid = true;
            this.setState({ isDriverLiscenseValid: true });
          }
          else {
            this.state.formErrors.cssTextDriverLicenseNo = 'tds-field--error';
            this.state.formErrors.textAreaDriverLicenseNo = 'Please enter a valid Driver\'s License Number';
            document.getElementById('DID').scrollIntoView();
            this.setState({ isDriverLiscenseValid: false });
          }
        break;

      case 'textAreaPolicyBondNumber':

        const textAreaPolicyBondNumber = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaPolicyBondNumber: textAreaPolicyBondNumber });

        if (textAreaPolicyBondNumber === '') {
          this.state.formErrors.cssTextAreaPolicyBondNumber = '';
          this.state.formErrors.textAreaPolicyBondNumber = '';
          this.setState({ isStep2Valid: true });
        }
        else
          if (textAreaPolicyBondNumber.match(/^[A-Za-z0-9\-]{1,40}$/)) {
            this.state.formErrors.cssTextAreaPolicyBondNumber = '';
            this.state.formErrors.textAreaPolicyBondNumber = '';
            textAreaPolicyBondNumberValid = true;
            this.setState({ isStep2Valid: true });
          }
          else {
            this.state.formErrors.cssTextAreaPolicyBondNumber = 'tds-field--error';
            this.state.formErrors.textAreaPolicyBondNumber = 'Please enter a valid Policy/Bond Number';
            document.getElementById('PID').scrollIntoView();
            this.setState({ isStep2Valid: false });
          }
        break;


      case 'textAreaAccountNo':

        const textAreaAccountNo = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaAccountNo: textAreaAccountNo });


        if (textAreaAccountNo === '') {
          this.state.formErrors.cssTextAreaAccountNo = '';
          this.state.formErrors.textAreaAccountNo = '';
          this.setState({ isAccountvalid: true });
        }
        else
          if (textAreaAccountNo.match(/^[a-zA-Z0-9]{1,10}$/)) {
            this.state.formErrors.cssTextAreaAccountNo = '';
            this.state.formErrors.textAreaAccountNo = '';
            textAreaAccountNoValid = true;
            this.setState({ isAccountvalid: true });
          }
          else {
            this.state.formErrors.cssTextAreaAccountNo = 'tds-field--error';
            this.state.formErrors.textAreaAccountNo = 'Please enter a valid Account Number';
            this.setState({ isAccountvalid: false });
            document.getElementById('PID').scrollIntoView();

          }
        break;

      case 'textAreaVINNo_One':


        const textAreaVINNo_One = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaVINNo_One: textAreaVINNo_One });


        if (textAreaVINNo_One === '') {
          this.state.formErrors.cssTextAreaVINNo_One = '';
          this.state.formErrors.textAreaVINNo_One = '';
          this.setState({ isVinOnevalid: true });
        }
        else if (textAreaVINNo_One.match(/^[a-zA-Z0-9]{1,22}$/)) {
          this.state.formErrors.cssTextAreaVINNo_One = '';
          this.state.formErrors.textAreaVINNo_One = '';
          textAreaVINNo_OneValid = true;
          this.setState({ isVinOnevalid: true });
        }
        else {
          this.state.formErrors.cssTextAreaVINNo_One = 'tds-field--error';
          this.state.formErrors.textAreaVINNo_One = 'Please enter a valid VIN Number';
          this.setState({ isVinOnevalid: false });
          document.getElementById('PID').scrollIntoView();

        }
        break;


      case 'textAreaVINNo_Two':

        const textAreaVINNo_Two = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaVINNo_Two: textAreaVINNo_Two });

        if (textAreaVINNo_Two === '') {
          this.state.formErrors.cssTextAreaVINNo_Two = '';
          this.state.formErrors.textAreaVINNo_Two = '';
          this.setState({ isVinTwovalid: true });
        }
        else if (textAreaVINNo_Two.match(/^[a-zA-Z0-9]{1,22}$/)) {
          this.state.formErrors.cssTextAreaVINNo_Two = '';
          this.state.formErrors.textAreaVINNo_Two = '';
          textAreaVINNo_TwoValid = true;
          this.setState({ isVinTwovalid: true });
        }
        else {
          this.setState({ isVinTwovalid: false });
          this.state.formErrors.cssTextAreaVINNo_Two = 'tds-field--error';
          this.state.formErrors.textAreaVINNo_Two = 'Please enter a valid VIN Number';
          document.getElementById('PID').scrollIntoView();

        }
        break;

      case 'textAreaVINNo_Three':
        const textAreaVINNo_Three = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaVINNo_Three: textAreaVINNo_Three });

        if (textAreaVINNo_Three === '') {
          this.state.formErrors.cssTextAreaVINNo_Three = '';
          this.state.formErrors.textAreaVINNo_Three = '';
          this.setState({ isVinThreevalid: true });
        }
        else
          if (textAreaVINNo_Three.match(/^[a-zA-Z0-9]{1,22}$/)) {
            this.state.formErrors.cssTextAreaVINNo_Three = '';
            this.state.formErrors.textAreaVINNo_Three = '';
            textAreaVINNo_ThreeValid = true;
            this.setState({ isVinThreevalid: true });
          }
          else {
            this.setState({ isVinThreevalid: false });
            this.state.formErrors.cssTextAreaVINNo_Three = 'tds-field--error';
            this.state.formErrors.textAreaVINNo_Three = 'Please enter a valid VIN Number';
            document.getElementById('PID').scrollIntoView();

          }
        break;

      case 'textAreaNationalProducerNo':
        const textAreaNationalProducerNo = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaNationalProducerNo: textAreaNationalProducerNo });

        if (textAreaNationalProducerNo === '') {
          this.state.formErrors.cssTextAreaNationalProducerNo = '';
          this.state.formErrors.textAreaNationalProducerNo = '';
          this.setState({ isNPNValid: true });
        }
        else
          if (textAreaNationalProducerNo.match(/^[0-9]{1,10}$/)) {
            this.state.formErrors.cssTextAreaNationalProducerNo = '';
            this.state.formErrors.textAreaNationalProducerNo = '';
            textAreaNationalProducerNoValid = true;
            if (textAreaNationalProducerNo.length < 10) {
              let len = textAreaNationalProducerNo.length;
              let i, t;
              t = textAreaNationalProducerNo;
              for (i = len; i < 10; i++) {
                t = t.replace(/^/, '0');
              }

              this.setState({ textAreaNationalProducerNo: t });
              this.setState({ isNPNValid: true });
            }

          }

          else {
            this.state.formErrors.cssTextAreaNationalProducerNo = 'tds-field--error';
            this.state.formErrors.textAreaNationalProducerNo = 'Please enter a valid National Producer Number';
            document.getElementById('NID').scrollIntoView();
            this.setState({ isNPNValid: false });
          }
        break;

      case 'textAreaAgentCode':

        const textAreaAgentCode = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaAgentCode: textAreaAgentCode });


        if (textAreaAgentCode === '') {
          this.state.formErrors.cssTextAreaAgentCode = '';
          this.state.formErrors.textAreaAgentCode = '';
          this.setState({ isAgentCodeValid: true });
        }
        else
          if (textAreaAgentCode.match(/^[a-zA-Z0-9]{5,6}$/)) {
            this.state.formErrors.cssTextAreaAgentCode = '';
            this.state.formErrors.textAreaAgentCode = '';
            textAreaAgentCodeValid = true;
            let a = textAreaAgentCode;
            if (a.length === '5') {
              a = a.replace(/^/, '0');
              this.setState({ textAreaAgentCode: a });
            }
            this.setState({ isAgentCodeValid: true });
          }
          else if (value.length > 0 && value.length < 5) {
            this.state.formErrors.cssTextAreaAgentCode = 'tds-field--error';
            this.state.formErrors.textAreaAgentCode = 'Must be atleast 5 characters';
            this.setState({ isAgentCodeValid: false });
          }
          else {
            this.state.formErrors.cssTextAreaAgentCode = 'tds-field--error';
            this.state.formErrors.textAreaAgentCode = 'Please enter a valid Agent Code';
            document.getElementById('NID').scrollIntoView();
            this.setState({ isAgentCodeValid: false });
          }
        break;


      case 'textAreaEmployeeID':

        const textAreaEmployeeID = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaEmployeeID: textAreaEmployeeID });

        if (textAreaEmployeeID === '') {
          this.state.formErrors.cssTextAreaEmployeeID = '';
          this.state.formErrors.textAreaEmployeeID = '';
          this.setState({ isEmpIdvalid: true });
        }
        else
          if (textAreaEmployeeID.match(/^[a-zA-Z0-9]{9,11}$/)) {
            this.state.formErrors.cssTextAreaEmployeeID = '';
            this.state.formErrors.textAreaEmployeeID = '';
            textAreaEmployeeIDValid = true;
            this.setState({ isEmpIdvalid: true });
          }
          else if ((value.length > 0 && value.length < 9) || (textAreaEmployeeID.length > 0 && textAreaEmployeeID.length < 9)) {
            this.state.formErrors.cssTextAreaEmployeeID = 'tds-field--error';
            this.state.formErrors.textAreaEmployeeID = 'Must be atleast 9 characters';
            this.setState({ isEmpIdvalid: false });
          }
          else {
            this.state.formErrors.cssTextAreaEmployeeID = 'tds-field--error';
            this.state.formErrors.textAreaEmployeeID = 'Please enter a valid Employee ID';

            this.setState({ isEmpIdvalid: false });
            document.getElementById('EID').scrollIntoView();
          }
        break;

      case 'textAreaEmployeeProdId':
        const textAreaEmployeeProdId = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaEmployeeProdId: textAreaEmployeeProdId });

        if (textAreaEmployeeProdId === '') {
          this.state.formErrors.cssTextAreaAgentCode = '';
          this.state.formErrors.textAreaEmployeeProdId = '';
          this.setState({ isShortNamevalid: true });
        }
        else
          if (textAreaEmployeeProdId.match(/^[A-Za-z0-9]{1,8}$/)) {
            this.state.formErrors.cssTextAreaAgentCode = '';
            this.state.formErrors.textAreaEmployeeProdId = '';
            textAreaEmployeeProdIdValid = true;
            this.setState({ isShortNamevalid: true });
          }
          else {
            this.state.formErrors.cssTextAreaAgentCode = 'tds-field--error';
            this.state.formErrors.textAreaEmployeeProdId = 'Please enter a valid PROD ID';
            document.getElementById('EID').scrollIntoView();
            this.setState({ isShortNamevalid: false });
          }
        break;

      case 'textAreaEmployeeTicId':
        const textAreaEmployeeTicId = value.replace(/(^\s*)|(\s*$)/gi, '');
        this.setState({ textAreaEmployeeTicId: textAreaEmployeeTicId });

        if (textAreaEmployeeTicId === '') {
          this.state.formErrors.cssTextAreaEmployeeTicId = '';
          this.state.formErrors.textAreaEmployeeTicId = '';
          this.setState({ isTicIdValid: true });
        }
        else
          if (textAreaEmployeeTicId.match(/^[A-Za-z0-9]{6}$/)) {
            this.state.formErrors.cssTextAreaEmployeeTicId = '';
            this.state.formErrors.textAreaEmployeeTicId = '';
            textAreaEmployeeTicIdValid = true;
            this.setState({ isTicIdValid: true });
          }
          else if ((value.length > 0 && value.length < 6) || (textAreaEmployeeTicId.length > 0 && textAreaEmployeeTicId.length < 6)) {
            this.state.formErrors.cssTextAreaEmployeeTicId = 'tds-field--error';
            this.state.formErrors.textAreaEmployeeTicId = 'Must be 6 characters';
            this.setState({ isTicIdValid: false });
          }
          else {
            this.state.formErrors.cssTextAreaEmployeeTicId = 'tds-field--error';
            this.state.formErrors.textAreaEmployeeTicId = 'Please enter a valid TIC-ID';
            document.getElementById('EID').scrollIntoView();
            this.setState({ isTicIdValid: false });
          }
        break;

      default:
        break;
    }



    this.setState({
      formErrors: fieldValidationErrors,
      textAreaAccountNoValid: textAreaAccountNoValid,
      textAreaAgentCodeValid: textAreaAgentCodeValid,
      textAreaClaimValid: textAreaClaimValid,
      textAreaDriverLicenseNoValid: textAreaDriverLicenseNoValid,
      textAreaDriverStateValid: textAreaDriverStateValid,
      textAreaEmployeeIDValid: textAreaEmployeeIDValid,
      textAreaEmployeeProdIdValid: textAreaEmployeeProdIdValid,
      textAreaEmployeeTicIdValid: textAreaEmployeeTicIdValid,
      textAreaNationalProducerNoValid: textAreaNationalProducerNoValid,
      textAreaPolicyBondNumberValid: textAreaPolicyBondNumberValid,
      textAreaQuoteValid: textAreaQuoteValid,

      textAreaVINNo_OneValid: textAreaVINNo_OneValid,
      textAreaVINNo_ThreeValid: textAreaVINNo_ThreeValid,
      textAreaVINNo_TwoValid: textAreaVINNo_TwoValid,
      textAreatravIDValid: textAreatravIDValid,
    }, this.validateFormTravRel);


  }
  validateFormTravRel() {
    this.setState({

      formValid2:
        this.state.textAreatravIDValid ||
        this.state.textAreaDriverLicenseNoValid ||
        this.state.textAreaDriverStateValid ||
        this.state.textAreaPolicyBondNumberValid ||
        this.state.textAreaAccountNoValid ||
        this.state.textAreaNationalProducerNoValid ||
        this.state.textAreaAgentCodeValid ||
        this.state.textAreaEmployeeIDValid ||
        this.state.textAreaEmployeeProdIdValid ||
        this.state.textAreaEmployeeTicIdValid ||
        this.state.textAreaVINNo_OneValid ||
        this.state.textAreaVINNo_TwoValid ||
        this.state.textAreaVINNo_ThreeValid ||
        this.state.textAreaQuoteValid ||
        this.state.textAreaClaimValid
    });
  }

  navigateToHome() {
    this.setState({
      address: '',
      addressValid: false,
      apt: '',
      aptValid: false,
      autoPolicy: false,
      checked: false,

      city: '',
      cityValid: false,
      claim: false,
      confirmEmail: '',
      confirmEmailValid: false,
      currentPolicy: false,
      dob: '',
      dobValid: false,
      email: '',
      emailInvalid: true,
      emailMsg: '',
      emailValid: false,

      firstName: '',
      firstNameValid: false,
      formErrors: { emailMsg: '', zipCode: '' },
      isCaptchaVerified: false,

      isDivDisabled: false,
      isGoing: false,
      isGoingX: false,
      isGoingY: false,
      isGoingZ: false,
      isNonDivDisabled: false,

      lastName: '',
      lastNameValid: false,
      none: false,
      phoneNo: '',
      phoneNoValid: false,
      quote: false,
      selected: false,
      socialSecurityNoValid: false,
      ssn: '',
      ssnVri: '',


      state: '',

      step: 1,

      tdNone: '',
      textAreaAccountNo: '',
      textAreaAccountNoValid: false,

      textAreaAgentCode: '',
      textAreaAgentCodeValid: false,

      textAreaBondNumber: '',
      textAreaBondNumberValid: false,

      textAreaClaim: '',
      textAreaClaimValid: false,



      textAreaDriverLicenseNo: '',
      textAreaDriverLicenseNoValid: false,

      textAreaDriverState: '',
      textAreaDriverStateValid: false,

      textAreaEmployeeID: '',
      textAreaEmployeeIDValid: false,

      textAreaEmployeeProdId: '',
      textAreaEmployeeProdIdValid: false,

      textAreaEmployeeTicId: '',
      textAreaEmployeeTicIdValid: false,

      textAreaNationalProducerNo: '',
      textAreaNationalProducerNoValid: false,

      textAreaPolicyBondNumber: '',
      textAreaPolicyBondNumberValid: false,



      textAreaQuote: '',
      textAreaQuoteValid: false,

      textAreaVINNo_One: '',
      textAreaVINNo_OneValid: false,

      textAreaVINNo_Three: '',
      textAreaVINNo_ThreeValid: false,

      textAreaVINNo_Two: '',
      textAreaVINNo_TwoValid: false,

      textAreatravID: '',
      textAreatravIDValid: false,

      travAgent: false,
      travEmp: false,
      travID: false,


      travPolicy: false,

      zipCode: '',
      zipCodeInvalid: true,
      zipCodeValid: false
    });
  }

  navigateToServiceDown() {
    this.setState({
      step: 14
    });
  }
  navigatToGrid(data) {
    this.setState({
      gridData: data,
      step: 11
    });
  }
  navigateToIdentityFailure(errorCode, httpCode) {

    this.setState({
      errorCode: errorCode,
      httpCode: httpCode,
      step: 8
    });


  }

  navigateToViewStatusRequest() {
    this.setState({
      step: 10
    });

  }

  navigateToDuplicate(data) {
    this.setState({
      SubmittedDate: data.CPA_CUST_REQUEST_SUBMITTED_DATETIME,
      latestTrackingNumber: data.CPA_CUST_REQUEST_ID,
      requestType: data.CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'Y' ? 'Access' : 'Delete',
      secondSubmittedDate: data.secondLatestReqSubmittedDate,
      secondTrackingNumber: data.SECOND_LATEST_CPA_CUST_REQUEST_ID,
      step: 9
    });


  }

  navigateToAcxiom(data, accessToken, postssn) {
    this.state.questionId = data.examUuid;
    this.state.acxiomResponse = data;
    this.state.postssn = postssn;
    this.setState({
      acxData: data,
      acxiomResponse: data,
      canOnlyDelete: data.canOnlyDelete,
      questionId: data.examUuid,
      step: 4,
      token: accessToken,
    });
  }

  navigateToStep2(canOnlyDelete) {
    const { step } = this.state;
    this.setState({
      canOnlyDelete: canOnlyDelete,
      step: step + 1
    });
  }

  navigateToRelWithTrav() {
    const { step } = this.state;
    this.setState({
      step: step - 4
    });
  }

  errorClass(error) {
    return (error.length === 0 ? '' : 'has-error');
  }

  render() {

    const { step } = this.state;

    const { address, addressValid, apt, city, cityValid, confirmEmail, confirmEmailValid, confirmEmailValidEnable, dob, dobValid, email, emailInvalid, emailValid,
      firstName, firstNameValid, isGoing, isGoingX, isGoingY,
      isGoingZ, isNoneOfThese, lastName, lastNameValid, phoneNo, phoneNoValid, quote, reCatchaKey,
      showZipCodeMsg, socialSecurityNoValid, ssn, ssnVri, state, textAreaAccountNo, textAreaAgentCode, textAreaBondNumber, textAreaClaim,
      textAreaDriverLicenseNo, textAreaDriverState, textAreaEmployeeID, textAreaEmployeeProdId, textAreaEmployeeTicId,
      textAreaNationalProducerNo, textAreaPolicyBondNumber, textAreaQuote,
      textAreaVINNo_One, textAreaVINNo_Three, textAreaVINNo_Two, textAreatravID, travID, zipCode, zipCodeInvalid, zipCodeValid } = this.state;
    const values = {

      address, addressValid, apt, city, cityValid, confirmEmail, confirmEmailValid, confirmEmailValidEnable, dob, dobValid, email, emailInvalid, emailValid,
      firstName, firstNameValid, isGoing, isGoingX, isGoingY, isGoingZ, isNoneOfThese, lastName, lastNameValid, phoneNo, phoneNoValid, reCatchaKey,
      showZipCodeMsg, socialSecurityNoValid, ssn, ssnVri, state, textAreaAccountNo, textAreaAgentCode, textAreaBondNumber, textAreaClaim, textAreaDriverLicenseNo, textAreaDriverState, textAreaEmployeeID, textAreaEmployeeProdId, textAreaEmployeeTicId, textAreaNationalProducerNo, textAreaPolicyBondNumber, textAreaQuote,
      textAreaVINNo_One, textAreaVINNo_Three, textAreaVINNo_Two, textAreatravID, travID, zipCode, zipCodeInvalid, zipCodeValid

    };
    const { formErrors } = this.state;
    const formError2 = { formErrors };
    const { formValid } = this.state;
    const { formValid2 } = this.state;
    const { autoPolicy, claim, currentPolicy, none, travAgent, travEmp, travPolicy } = this.state;
    const checked = { autoPolicy, claim, currentPolicy, none, quote, travAgent, travEmp, travID, travPolicy };
    const { TICIDArea, VINNo1, VINNo2, VINNo3, accountNoArea, agentCodeArea, claimArea, driverLicenseNoArea, empIDArea, nationalProducerNoArea, policyOrBondNoArea, prodIDArea, quoteArea, stateArea, tIDArea, travEmpArera } = this.state;
    const textArea = { TICIDArea, VINNo1, VINNo2, VINNo3, accountNoArea, agentCodeArea, claimArea, driverLicenseNoArea, empIDArea, nationalProducerNoArea, policyOrBondNoArea, prodIDArea, quoteArea, stateArea, tIDArea, travEmpArera };
    const { isAccountvalid, isAgentCodeValid, isClaimValid, isDriverLiscenseValid, isDriverStateValid, isEmpIdvalid, isNPNValid, isQuoteValid, isShortNamevalid, isTicIdValid, isTravIdValid, isVinOnevalid, isVinThreevalid, isVinTwovalid } = this.state;
    const step2ValidValues = { isAccountvalid, isAgentCodeValid, isClaimValid, isDriverLiscenseValid, isDriverStateValid, isEmpIdvalid, isNPNValid, isQuoteValid, isShortNamevalid, isTicIdValid, isTravIdValid, isVinOnevalid, isVinThreevalid, isVinTwovalid };
    // const selected = {isGoingX,isGoingZ};
    if (this.state.isInternal === true && this.state.isOktaAuthorized === false) {
      return (
        <ServiceDownPage navigateToHome={this.navigateToHome.bind(this)} />
      );
    }
    switch (step) {
      case 1:
        return (

          <HomePage
            nextStep={this.nextStep}
            handleUserInput={this.handleUserInput}
            values={values}
            navigateToViewStatusRequest={this.navigateToViewStatusRequest.bind(this)}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
          />
        );

      case 2:
        return (<ExemptionPage
          nextStep={this.nextStep}
          prevStep={this.prevStep}
          handleUserInput={this.handleUserInput}
          navigateToHome={this.navigateToHome.bind(this)}

        />);



      case 3:
        return (
          <VerifyIdentity
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleUserInput={this.handleUserInput}
            leftBlank={this.leftBlank}
            change={this.change}
            values={values}
            error={formError2}
            validateForm={formValid}
            formError2={formError2}
            navigateToAcxiom={this.navigateToAcxiom.bind(this)}
            navigateToHome={this.navigateToHome.bind(this)}
            updateAddressProps={this.updateAddressProps.bind(this)}
            navigateToIdentityFailure={this.navigateToIdentityFailure.bind(this)}
            validateField={this.validateField.bind(this)}
            validateFormFieldStep1={this.validateForm.bind(this)}

            isFormvalid={this.state.formValid}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
            clearError={this.clearError.bind(this)}
          />
        );

      case 4:
        return (
          <AcxiomQuiz
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleInputChange={this.handleInputChange.bind(this)}
            acxiomResponse={this.state.acxiomResponse}
            values={values}
            questionId={this.state.questionId}
            token={this.state.token}
            navigateToAcxiom={this.navigateToAcxiom.bind(this)}
            navigateToStep2={this.navigateToStep2.bind(this)}
            navigateToIdentityFailure={this.navigateToIdentityFailure.bind(this)}
            navigateToHome={this.navigateToHome.bind(this)}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
            postssn={this.state.postssn}
          />
        );


      case 5:
        return (
          <RelWithTrav
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            values={values}
            handleCheckBoxChange={this.handleCheckBoxChange.bind(this)}
            checked={checked}
            textArea={textArea}
            handleInput={this.handleInput}
            error={formError2}
            formError2={formError2}
            validateFormTravRel={formValid2}
            updateDisableDiv={this.updateDisableDiv.bind(this)}
            navigateToHome={this.navigateToHome.bind(this)}
            ClearOtherFieldsData={this.ClearOtherFieldsData.bind(this)}
            cannotbeLeftBlank={this.cannotbeLeftBlank}
            isNonDivDisabled={this.state.isNonDivDisabled}
            isDivDisabled={this.state.isDivDisabled}
            isStep2Valid={this.state.isStep2Valid}
            step2ValidValues={step2ValidValues}
            canOnlyDelete={this.state.canOnlyDelete}
            navigateToDuplicate={this.navigateToDuplicate.bind(this)}
            navigateToAcxiom={this.navigateToAcxiom.bind(this)}
            postssn={this.state.postssn}
          />
        );

      case 6:
        return (
          <AccessOrDelete
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleInputChange={this.handleInputChange.bind(this)}
            values={values}
            navigateToTrackingConfirmation={this.navigateToTrackingConfirmation.bind(this)}
            checked={checked}
            navigateToHome={this.navigateToHome.bind(this)}
            navigateToDeletedTrackingConfirmation={this.navigateToDeletedTrackingConfirmation.bind(this)}
            navigateToAccessDeleteRequest={this.navigateToAccessDeleteRequest.bind(this)}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
          />
        );

      case 7:
        return (
          <AccessRequest
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            requestType={this.state.requestType}
            trackingNo={this.state.trackingNo}
            trackingNoSubmissionDate={this.props.trackingNoSubmissionDate}
            navigateToHome={this.navigateToHome.bind(this)}
          />
        );

      case 8:
        return (
          <IdentityFailure
            navigateToHome={this.navigateToHome.bind(this)}
            errorCode={this.state.errorCode}
            httpCode={this.state.httpCode}

          />
        );

      case 9:
        return (
          <InfiniteDeleteRequests
            handleCheckBoxChange={this.handleCheckBoxChange.bind(this)}
            latestTrackingNumber={this.state.latestTrackingNumber}
            secondSubmittedDate={this.state.secondSubmittedDate}
            secondTrackingNumber={this.state.secondTrackingNumber}
            requestType={this.state.requestType}
            SubmittedDate={this.state.SubmittedDate}
            values={values}
            checked={checked}
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            navigateToDeletedTrackingConfirmation={this.navigateToDeletedTrackingConfirmation.bind(this)}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
            navigateToHome={this.navigateToHome.bind(this)}
            navigateToThankYou={this.navigateToThankYou.bind(this)}
            handleInputChange2={this.handleInputChange2.bind(this)}
            navigateToRelWithTrav={this.navigateToRelWithTrav.bind(this)}
          />
        );

      case 10:
        return (
          <ViewStatus
            reCatchaKey={this.state.reCatchaKey}
            navigatToGrid={this.navigatToGrid.bind(this)}
            navigateToHome={this.navigateToHome.bind(this)}
            navigateToServiceDown={this.navigateToServiceDown.bind(this)}
          />
        );

      case 11:
        return (
          <DataReport
            gridData={this.state.gridData}
            navigateToHome={this.navigateToHome.bind(this)}
          />
        );


      case 12:
        return (
          <DeleteRequest
            requestType={this.state.requestType}
            trackingNo={this.state.trackingNo}
            navigateToHome={this.navigateToHome.bind(this)}

          />
        );

      case 13:
        return (

          <AccessAndDeleteRequest
            accessDeleteData={this.state.accessDeleteData}
            navigateToHome={this.navigateToHome.bind(this)}

          />
        );

      case 14:
        return (
          <ServiceDownPage
            navigateToHome={this.navigateToHome.bind(this)}
          />

        );

      case 15:
        return (
          <ThankYou
            navigateToHome={this.navigateToHome.bind(this)}
          />
        );



    }

  }
});
