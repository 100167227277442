import httpClient from '../../utilities/httpClient';
let client = httpClient.createClient();
class acxiomService {

    static async  getAcxiomToken(data) {
        const requestObject = {
            dob: data.dob,
                emailAddress: data.email,
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: (data.phoneNo||'').replace(/\D/g,''),
                ssn: data.ssn,
                unparsedAddress: {
                    addressLine1: data.address,
                    city: data.city,
                    zipCode: data.zipCode
				},											
        };
        var response = await client.post('/api/verify-identity/token', requestObject);                    
        return response;
    };

    static async getAcxiomScore(data) {
        const requestObject = {
            choices_five: data.choices_five,
            choices_four: data.choices_four,
            choices_one: data.choices_one,
            choices_three: data.choices_three,
            choices_two: data.choices_two,
            examUuId: data.examUuId,
            questionId_five: data.questionId_five,
            questionId_four: data.questionId_four,
            questionId_one: data.questionId_one,
            questionId_three: data.questionId_three,
            questionId_two: data.questionId_two,
            questionSetId: data.questionSetId,
            token: data.token};
			var response = await client.post('/api/verify-identity/score', requestObject);
        return response;
    }
};

export default acxiomService;
