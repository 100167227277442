import React, { Component } from 'react';
import { withRouter } from 'react-router';

export class DeletedataRequest extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.print = this.print.bind(this);
    }
    
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Consumer Privacy | Travelers Insurance';
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    handleSubmit() {
        this.props.navigateToHome();
    }
    print() {
        window.print();
    }

    render() {

        const ReqId = this.props.trackingNo;
        const requestType = this.props.requestType;

        return (

            <div className="tds-container tds-col-md-5">
                <h1 className="tds-h1--small">Your request to delete your data has been submitted.</h1>
                <p className="tds-large">
                    You have requested, pursuant to the California
                    Consumer Privacy Act (CCPA), that we delete
                    personal information that we have collected about
                    you. Please click on the link below for additional
                    information regarding your request for deletion.
                </p>
                                <div className="tds-requestConfirmationPage">
                    <h3>For your reference, here is your Tracking Number:</h3>
                    <div className="tds-col-6">
                        <h4 className="tds-mt-m">Tracking Number</h4>
                        <div className="tds-large">{ReqId}</div>
                    </div>

                    <div className="tds-col-6">
                        <h4 className="tds-mt-m">Type</h4>
                        <div className="tds-large">{requestType}</div>
                    </div>
                </div>
                <p className="tds-large tds-mt-xxl">Thank you for your inquiry.</p>
                <div className="tds-button-container tds-mt-xxl" id="react-no-print">
                    <button type="button" className="tds-button--primary" onClick={this.handleSubmit}>DONE</button>
                    <button type="button" className="tds-button--tertiary" onClick={this.print}>PRINT</button>
                </div>
            </div>
        );
    }
}
export default withRouter(DeletedataRequest);
