
import TrvHttp from '@trv-ent-web/http';
import uuidv1 from 'uuid/v1';


export default {
    createClient: (sessionId) => {
        let trvHttp = new TrvHttp({
            appName: 'cpa-portal',
            baseUrl: '/',
            correlationId: uuidv1(),
            sessionId: sessionId || uuidv1()
          });
        return trvHttp;
    }
};
