import httpClient from '../../utilities/httpClient';
let client = httpClient.createClient();

class CustomerStatusApi {
    static async verifyRequestStatus(data) {
        const requestObject = {
            dateOfBirth: data.dateOfBirth,
            lastName: data.lastName,
            recaptchaToken: data.recaptchaToken,
            reqId: data.reqId,
            ssn: data.ssn
        };
        var response = await client.post('/api/customer-status/verify-status', requestObject);
        return response;
    };
    static async receiveViaSecureEmail(data) {
        let params = { cpa_req: data };
        var response = await client.get('/api/customer-status/receive_email', { params: params });
        return response;
    };
}
export default CustomerStatusApi;
