import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import HomePage from './pages/homePage.js';
import RequestCorrectionConfirmation from './pages/requestCorrectionConfirmation.js';
import VerifyIdentity from './pages/verifyIdentity.js';
import UserForm from './components/UserForm.js';
import { Acxiom } from './pages/acxiomQuiz.js';
import Step2 from './pages/relWithTrav.js';
import {IdentityFailure} from './pages/identityFailure.js';
import GridStatus from './pages/dataReport.js';
import { LoginCallback } from '@okta/okta-react';

class Routes extends React.Component {
  constructor(props) {
    super(props);
  }
  render(){
    return(
      <Switch>
          <Route exact path="/" component={UserForm} />
          <Route exact path="/step1" component={VerifyIdentity} />
          <Route exact path="/acxiom" component={Acxiom} />
          <Route exact path="/step2"  component={props => <Step2 {...props}/>}/>
          <Route exact path ="/home" component={HomePage}/>
          <Route exact path ="/identityfails" component ={IdentityFailure}/>
          <Route exact path ="/status" component={GridStatus} />
          <Route exact path ="/ViewStatus" component={UserForm} />
          <Route path="/authorization-code/callback" component={LoginCallback}/>
          <Route path="/correctionconfirmation" component={RequestCorrectionConfirmation} />
      </Switch>
    );
  }
}

export default withRouter(Routes);
