import React from 'react';
import { withRouter } from 'react-router';
import ReferenceService from '../components/api/referenceService';

class ExemptionPage extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			footerLinks: []
		};
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
		let response = await ReferenceService.configs();
		this.setState({
			footerLinks: response.footer
		});
		this.setState({ isInternal: response.isInternal });

	}
	continue = e => {
		e.preventDefault();
		this.props.nextStep();
	};

	navigate = () => {
		this.props.navigateToHome();
	}

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};


	render() {

		return (
			<div className="tds-container tds-col-md-5">

				<div className="tds-mt-xl">
					<div>
						<h1 className="tds-h1--small">Before you get started</h1>
						<p>
							Information relating to certain types of individuals, including those noted in the list below, is exempt from
							the provisions of the CCPA that relate to requests for access to or deletion of data.
						</p>
					</div>
					<div>
						<p className="tds-bold"> CCPA Exemptions</p>
						<ul className="tds-ul">
							<li className="tds-li">Customers, applicants, claimants and others associated with our personal insurance business
								(e.g. auto, homeowners, umbrella).
							</li>
							<li className="tds-li">Workers compensation beneficiaries and claimants</li>
							<li className="tds-li">Purchasers of an Identity Fraud Expense Reimbursement product.</li>
						</ul>
					</div>
					<div>
						<p >
							<span className="tds-bold">
								If you fall within one of these groups, your request for access to data
								under the CCPA may not result in any records being produced.</span> For additional information about how we collect, use and disclose personal information, please
							refer to the <a id="privacyandsecurity" className="text-decor"
								href={this.state.footerLinks.TRAVELERS_PRIVATE_STATEMENT} target="_blank">
								Privacy & Security</a> section of our website. To continue making a request, please click the button below.
						</p>
					</div>
					<div className="tds-button-container">
						<button type="button" className="tds-button--primary" onClick={this.continue}>CONTINUE</button>
						<button type="button" className="tds-button--tertiary" onClick={this.back}>BACK</button>
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(ExemptionPage);
