import React, { Component } from 'react';
import { withRouter } from 'react-router';

export class AccessAndDelete extends Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.print = this.print.bind(this);
    }
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Consumer Privacy | Travelers Insurance';
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };

    handleSubmit() {
        this.props.navigateToHome();
    }

    print() {
        window.print();
    }

    render() {
        return (

            <div className="tds-container tds-col-md-5">
                <div className="tds-mt-xl">
                    <h1 className="tds-h1--small">Your request to access and delete your data has been submitted.</h1>
                    <div className="tds-large">
                        <p>We should respond to your request for access within 45 days. If we require additional time to respond, we will notify you via email.
                            You have also requested, pursuant to the California Consumer Privacy Act (CCPA),
                            that we delete personal information that we have collected about you.
                            Please click on the link below for additional information regarding your request for deletion.
                        </p>
                    </div>
                </div>
                <div className="tds-col-12" id="printDivAccessBox">
                    <div className="tds-requestConfirmationPage">
                        <h3>For your reference, here are your Tracking Numbers:</h3>
                        <div className="tds-row">
                            <div className="tds-col-6"><h4 className="tds-mx-l">Tracking Number</h4></div>
                            <div className="tds-col-6"><h4 className="tds-mx-xxxl">Type</h4></div>
                        </div>
                        <div className="tds-row">
                            <div className="tds-col-6"><span className="tds-large">{this.props.accessDeleteData[1].CPA_CUST_REQUEST_ID}</span></div>
                            <div className="tds-col-6"><span className="tds-mx-xxxl tds-large">{this.props.accessDeleteData[1].CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'N' ? 'Delete' : 'Access'}</span></div>
                        </div>
                        <hr />
                        <div className="tds-row">
                            <div className="tds-col-6"><span className="tds-large">{this.props.accessDeleteData[0].CPA_CUST_REQUEST_ID}</span></div>
                            <div className="tds-col-6"><span className="tds-mx-xxxl tds-large">{this.props.accessDeleteData[0].CPA_CUST_REQ_TO_ACCESS_DATA_FLG === 'N' ? 'Delete' : 'Access'}</span></div>
                        </div>
                    </div>
                </div>
                <div className="tds-mt-m">
                    <p className="tds-large">Thank you for your inquiry.</p>
                </div>
                <div className="tds-button-container" id="react-no-print">
                    <button type="button" className="tds-button--primary" onClick={this.handleSubmit}>DONE</button>
                    <button type="button" className="tds-button--tertiary" onClick={this.print}>PRINT</button>
                </div>
            </div>
        );
    }
}
export default withRouter(AccessAndDelete);
