export default {
    getClientConfig: () => {
        return {
            internal: process.env.REACT_APP_CLIENT_ISINTERNAL,
            oktaConfig: {
                clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
                disableHttpsCheck: false,
                issuer: process.env.REACT_APP_OKTA_ISSUER,
                pkce: true,
                redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
                scopes: ['openid', 'profile', 'email']
            }
        };
    },
    isRecaptchaEnabled: () =>{
        return JSON.stringify(process.env.REACT_APP_RECAPTCHA_ENABLE) === JSON.stringify('enable');
    }
};
