import React from 'react';
import Recaptcha from 'react-recaptcha';
import ReferenceService from '../components/api/referenceService.js';
import CustomerRequestService from '../components/api/customerRequest';
import AcxiomService from '../components/api/identityVerification';
import { withRouter } from 'react-router';
import NumberFormat from 'react-number-format';
import WarningModal from './warningModal.js';
import Modal from 'react-responsive-modal';
import { Button } from 'trv-tds-react';
import { createLogger } from '@trv-ent-web/logging';
import clientConfig from '../../src/utilities/clientConfig';
var url = process.env.ISINTERNAL;
var isRecaptcha = '';
const RecaptchaEnabled = clientConfig.isRecaptchaEnabled();
let logger = createLogger({ name: 'Verify Identity logger' });
export class VerifyIdentity extends React.Component {
	constructor(props) {
		super(props);
		this.verifyCallback = this.verifyCallback.bind(this);
		this.handleLoad = this.handleLoad.bind(this);
		this.handlePhone = this.handlePhone.bind(this);
		this.navigate = this.navigate.bind(this);
		this.state = {
			PBCity: '',
			PBHouseNumber: '',
			PBState: '',
			PBStreetName: '',
			PBStreetSufix: '',
			PBZip: '',
			busy: false,
			dob: '',
			dobValid: false,
			firstName: '',
			firstNameValid: false,
			formErrors: { dob: '', firstName: '', lastName: '', phoneNo: '', ssn: '', zipCode: '' },
			formValid: false,
			isAddressDifferent: false,
			isCaptchaVerified: false,
			isCloseTrue: false,
			isInternal: false,
			isPBResponseFailed: false,
			isUsePBAddress: false,
			lastName: '',
			lastNameValid: false,
			loading: false,
			message: '',
			open: false,
			pbAddressLineOne: '',
			pbAddressLineTwo: '',
			phoneNo: '',
			phoneNoValid: false,
			postssn: '',
			recaptchaResponse: '',
			ssn: '',
			ssnValid: false,
			ssnVri: '',
			validateForm1: true,
			zipCode: '',
			zipCodeInvalid: false,
			zipCodeValid: false
		};
	}

	leadingZero = (val) => {
		if (val.length === 1) {
			val = '0' + val;
		}
	}

	firstMethod(e) {
		const re = /[0-9]+/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	secondMethod(e) {
		const re = /[,]+/g;
		if (re.test(e.key)) {
			e.preventDefault();
		}
	}

	thirdMethod(e) {
		const re = /[a-zA-Z'-\s]+/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	fourthMethod(e) {
		const re = /^([a-zA-Z0-9/-\s]{1,5})/g;
		if (!re.test(e.key)) {
			e.preventDefault();
		}
	}

	navigate = () => {
		this.props.navigateToHome();
	}

	onOpenModal = (e) => {
		e.preventDefault();
		this.setState({ open: true }
		);
	};

	onCloseModal = () => {
		this.setState({ open: false });
	};

	handleLoad() {
		return this.props.reCatchaKey; //  $ is available here
	}

	async componentDidMount() {
		window.scrollTo(0, 0);
		document.title = 'Consumer Privacy | Travelers Insurance';
		let configs = await ReferenceService.configs();
		this.setState({ isInternal: configs.isInternal });
		}

	showModal = () => {
		this.setState({ show: true });
	};

	hideModal = () => {
		this.setState({ show: false });
	};

	concate = () => {
		var rightNow = this.props.dob;
		var res = rightNow.toISOString().slice(0, 10).replace(/-/g, '');
	}

	pasteBox = (e) => {
		const pasteBox = document.getElementById('no-paste');
		e.preventDefault();
		return false;
	}

	continue = async (e) => {
		if (!this.state.isAddressDifferent && !this.state.isPBResponseFailed) {
			this.setState({
				busy: true
			});
			setTimeout(() => {
				this.setState({
					busy: false
				});
			}, 3000);
		} else {
			this.setState({
				busy: false
			});
		}

		if (this.props.handleUserInput()) {
			const postData = { address: '', addressLine1: '', addressLine2: '', aptSuitNbr: '', city: '', dateOfBirth: '', email: '', firstName: '', lastName: '', phoneNumber: '', ssn: '', zipcode: '' };
			postData.firstName = this.props.values.firstName.toUpperCase();
			postData.lastName = this.props.values.lastName.toUpperCase();
			postData.ssn = this.props.values.ssn;
			postData.ssnVri = this.props.values.ssnVri;
			postData.zipcode = this.props.values.zipCode;
			postData.dateOfBirth = this.props.values.dob;
			let phoneNumber = this.props.values.phoneNo.replace('(', '');
			phoneNumber = phoneNumber.replace(')', '');
			phoneNumber = phoneNumber.replace(/ /g, '');
			phoneNumber = phoneNumber.replace(/-/g, '');
			postData.phoneNumber = phoneNumber;
			postData.city = this.props.values.city;
			postData.email = this.props.values.email;
			postData.address = this.props.values.address;
			postData.aptSuitNbr = this.props.values.apt;
			const postssn = { recaptchaToken: this.state.recaptchaResponse, ssn: postData.ssn };
			const checkReqResponse = await CustomerRequestService.checkRequestCount(postssn, false);
			try {
				if (checkReqResponse != null && checkReqResponse.data.CPA_VALID_VALUE) {
					try {
						const acxiomResponse = await AcxiomService.getAcxiomToken(this.props.values);
						if (acxiomResponse != null && acxiomResponse.data != null && acxiomResponse.data.error) {
							logger.info('TIME OUT FROM ACXIOM TOKEN SERVICE');
							this.props.navigateToIdentityFailure(acxiomResponse.data.error.code, acxiomResponse.data.statusCode);
						}
						else
							if (acxiomResponse !== null && acxiomResponse.data !== null && acxiomResponse.data.error !== undefined) {
								this.props.navigateToIdentityFailure(acxiomResponse.data.error.code, acxiomResponse.data.statusCode);
							}
							else if (acxiomResponse !== null && acxiomResponse.data !== null && acxiomResponse.data.statusCode === 500) {

								this.props.navigateToServiceDown();
							}
							else {
								if (!this.state.isAddressDifferent && !this.state.isPBResponseFailed) {
									let accessToken = acxiomResponse.data.accessToken;

									this.props.navigateToAcxiom(acxiomResponse.data, accessToken);
								}
							}
					}
					catch (e) {
						logger.info('Error occured in Verify Identity in getAcxiomToken', e);
						this.props.navigateToServiceDown();
					}
				}
				else if (checkReqResponse != null && (checkReqResponse.data.name === 'RequestError' || checkReqResponse.data.name === 'invalid Request')) {
					this.props.navigateToServiceDown();
				}
				else {
					const acxiomResponse = await AcxiomService.getAcxiomToken(this.props.values);
					if (checkReqResponse != null && !checkReqResponse.data.CPA_VALID_VALUE) {
						this.props.navigateToIdentityFailure('200-4303', '403');
					} else {
						let accessToken = acxiomResponse.headers.authorization;
						this.setState.postssn = postssn;
						this.props.navigateToAcxiom(acxiomResponse.data, accessToken, postssn);
					}
				}
			}
			catch (e) {
				logger.info('Error occured in Verify Identity in checkreqcount', e);
				this.props.navigateToServiceDown();
			}
		}
	};

	closeX = () => {
		this.setState({
			isAddressDifferent: false,
			isCloseTrue: true
		});
	}

	updateAddress = async (e) => {
		e.preventDefault();
		this.props.clearError();
		this.setState({
			isAddressDifferent: false,
			isUsePBAddress: true
		});
		this.setState({
			isAddressDifferent: true,
		});
		setTimeout(() => {
			this.setState({
				isAddressDifferent: false,
			});
		}, 2200);
		const postData = { address: '', addressLine1: '', addressLine2: '', aptSuitNbr: '', city: '', dateOfBirth: '', email: '', firstName: '', lastName: '', phoneNumber: '', ssn: '', zipcode: '' };
		postData.firstName = this.props.values.firstName;
		postData.lastName = this.props.values.lastName;
		postData.ssn = this.props.values.ssn;
		postData.city = this.state.PBCity;
		postData.zipcode = this.state.PBZip;
		postData.state = this.state.PBState;
		postData.address = this.state.PBStreetName;
		postData.dateOfBirth = this.props.values.dob;
		let phoneNumber = this.props.values.phoneNo.replace('(', '');
		phoneNumber = phoneNumber.replace(')', '');
		phoneNumber = phoneNumber.replace(/ /g, '');
		phoneNumber = phoneNumber.replace(/-/g, '');
		postData.phoneNumber = phoneNumber;
		postData.email = this.props.values.email;
		postData.addressLine1 = this.state.pbAddressLineOne;
		const acxiomResponse = await AcxiomService.getAcxiomToken(postData);
		this.props.updateAddressProps(postData);
	}

	back = e => {
		e.preventDefault();
		this.props.prevStep();
	};

	verifyCallback(response) {
		if (response) {
			this.setState({
				isCaptchaVerified: true,
				recaptchaResponse: response
			});
		}
	}

	errorClass(error) {
		return (error.length === 0 ? '' : 'has-error');
	}

	validateFormStep1() {
		if (this.state.isInternal) {
			this.props.validateFormFieldStep1();
			if (this.props.values.email === '' && this.props.values.confirmEmail === '') {
				if (this.props.values.firstNameValid
					&& this.props.values.firstName !== ''
					&& this.props.values.lastNameValid
					&& this.props.values.lastName !== ''
					&& this.props.values.socialSecurityNoValid
					&& this.props.values.ssn !== ''
					&& this.props.values.dobValid
					&& this.props.values.dob !== ''
					&& this.props.values.addressValid
					&& this.props.values.address !== ''
					&& this.props.values.cityValid
					&& this.props.values.city !== ''
					&& this.props.values.zipCodeValid
					&& this.props.values.zipCode !== ''
					&& this.props.values.zipCodeInvalid
					&& this.props.values.phoneNoValid
					&& this.props.values.phoneNo !== ''
				) {
					this.continue();
				}
			}
			else if (this.props.values.email === '' && this.props.values.confirmEmail !== '' && this.props.values.email !== this.props.values.confirmEmail) {
				if (this.props.values.firstNameValid
					&& this.props.values.firstName !== ''
					&& this.props.values.lastNameValid
					&& this.props.values.lastName !== ''
					&& this.props.values.socialSecurityNoValid
					&& this.props.values.ssn !== ''
					&& this.props.values.dobValid
					&& this.props.values.dob !== ''
					&& this.props.values.addressValid
					&& this.props.values.address !== ''
					&& this.props.values.cityValid
					&& this.props.values.city !== ''
					&& this.props.values.zipCodeValid
					&& this.props.values.zipCode !== ''
					&& this.props.values.phoneNoValid
					&& this.props.values.phoneNo !== ''
					&& this.props.values.emailValid
					&& this.props.values.confirmEmailValid
					&& this.props.values.confirmEmailValidEnable
					&& this.props.values.email !== ''
					&& this.props.values.confirmEmail !== ''
					&& this.props.values.email === this.props.values.confirmEmail
					&& this.props.values.zipCodeInvalid
				) {
					this.continue();
				}
			}
			else {
				if (this.props.values.firstNameValid
					&& this.props.values.firstName !== ''
					&& this.props.values.lastNameValid
					&& this.props.values.lastName !== ''
					&& this.props.values.socialSecurityNoValid
					&& this.props.values.ssn !== ''
					&& this.props.values.dobValid
					&& this.props.values.dob !== ''
					&& this.props.values.addressValid
					&& this.props.values.address !== ''
					&& this.props.values.cityValid
					&& this.props.values.city !== ''
					&& this.props.values.zipCodeValid
					&& this.props.values.zipCode !== ''
					&& this.props.values.phoneNoValid
					&& this.props.values.phoneNo !== ''
					&& this.props.values.emailValid
					&& this.props.values.confirmEmailValid
					&& this.props.values.email !== ''
					&& this.props.values.confirmEmail !== ''
					&& this.props.values.email === this.props.values.confirmEmail
					&& this.props.values.zipCodeInvalid
				) {
					this.continue();
				}
			}


		}
		else {
			this.props.validateFormFieldStep1();
			if (this.props.values.firstNameValid
				&& this.props.values.firstName !== ''
				&& this.props.values.lastNameValid
				&& this.props.values.lastName !== ''
				&& this.props.values.socialSecurityNoValid
				&& this.props.values.ssn !== ''
				&& this.props.values.dobValid
				&& this.props.values.dob !== ''
				&& this.props.values.addressValid
				&& this.props.values.address !== ''
				&& this.props.values.cityValid
				&& this.props.values.city !== ''
				&& this.props.values.zipCodeInvalid
				&& this.props.values.zipCodeValid
				&& this.props.values.zipCode !== ''
				&& this.props.values.phoneNoValid
				&& this.props.values.phoneNo !== ''
				&& this.props.values.emailValid
				&& this.props.values.confirmEmailValid
				&& this.props.values.email !== ''
				&& this.props.values.confirmEmail !== ''
				&& this.props.values.email === this.props.values.confirmEmail
				&& (this.state.isCaptchaVerified || !RecaptchaEnabled)) {
				this.continue();
			}
		}
	}
	forPB = async () => {
		const postData = { address: '', addressLine1: '', addressLine2: '', aptSuitNbr: '', city: '', dateOfBirth: '', email: '', firstName: '', lastName: '', phoneNumber: '', ssn: '', zipcode: '' };
		postData.firstName = this.props.values.firstName.toUpperCase();
		postData.lastName = this.props.values.lastName.toUpperCase();
		postData.ssn = this.props.values.ssn;
		postData.ssnVri = this.props.values.ssnVri;
		postData.zipcode = this.props.values.zipCode;
		postData.dateOfBirth = this.props.values.dob;
		let phoneNumber = this.props.values.phoneNo.replace('(', '');
		phoneNumber = phoneNumber.replace(')', '');
		phoneNumber = phoneNumber.replace(/ /g, '');
		phoneNumber = phoneNumber.replace(/-/g, '');
		postData.phoneNumber = phoneNumber;
		postData.city = this.props.values.city;
		postData.email = this.props.values.email;
		postData.address = this.props.values.address;
		postData.aptSuitNbr = this.props.values.apt;
		const PBData = { singlelineAddress: '' };
		PBData.singlelineAddress = `${postData.address} ${','}${postData.city}${','}${'CA'}${' '}${postData.zipcode}`;
		let addressGeneratedByPB = '';
		let addressEnteredByUser = '';
		addressGeneratedByPB = `${this.state.PBHouseNumber} ${this.state.PBStreetName} ${this.state.PBStreetSufix} ${this.state.PBCity} ${this.state.PBState} ${this.state.PBZip}`;
		addressGeneratedByPB = addressGeneratedByPB.replace(/ /g, '');
		addressEnteredByUser = `${postData.address}${postData.city}${'CA'}${postData.zipcode}`;
		addressEnteredByUser = addressEnteredByUser.replace(/ /g, '');
	}
	handleSubmit = async (event) => {
		logger.info('On click of continue on Verify Identity');
		event.preventDefault();
		this.forPB();
		this.validateFormStep1();
	}
	handlePhone(e) {
		const onlyNums = e.target.value.replace(/[^0-9]/g, '');
		if (onlyNums.length < 10) {
			this.setState({ value: onlyNums });
		} else if (onlyNums.length === 10) {
			const number = onlyNums.replace(
				/(\d{3})(\d{3})(\d{4})/,
				'($1) $2-$3'
			);
			this.setState({ value: number });
		}
	}

	render() {
		const busy = this.state.busy;
		const { open } = this.state;
		let hidden1 = '';
		if (!this.state.isInternal) {
			if (!this.props.values.emailInvalid) {
				hidden1 = 'tds-inline-alert--error';
			} else {
				hidden1 = 'tds-d-none';
			}
		} else {
			hidden1 = 'tds-d-none';
		}
		const { values, handleUserInput, error, validateForm, leftBlank } = this.props;
		const hiddenPB = this.state.isAddressDifferent ? 'tds-alert--info tds-alert--has-inner-icon' : 'tds-d-none';
		const pbMsg = this.state.isPBResponseFailed ? 'tds-inline-alert--error' : 'tds-d-none';
		const hidden = values.zipCodeInvalid ? 'tds-d-none' : 'tds-inline-alert--error';
		if (this.state.isInternal) {
			url = <div>
				<div className={`form-group tds-field ${error.formErrors.cssEmailMsg}`}>
					<label htmlFor="email" className={`tds-field__label ${error.formErrors.cssEmailMsg}`}>Email (Optional) </label>
					<input type="email" className="tds-field__input" name="email" id="tbemail"
						defaultValue={values.email}
						onBlur={handleUserInput('email')}
						onChange={leftBlank('email')}
						onMouseDown={(e) => e.target.focus()}
						maxLength="60"
						aria-describedby="tbemail_help" />
					<div id="tbemail_help" aria-atomic="true" className="tds-field__message" role="alert">
						<p>{error.formErrors.emailMsg}</p>
					</div>
				</div>
				<div className={`form-group tds-field ${error.formErrors.cssConfirmEmail}`}>
					<label htmlFor="confirmEmail" className={`tds-field__label ${error.formErrors.cssConfirmEmail}`}>Re-Enter Email (Optional) </label>
					<input type="email" className="tds-field__input" name="confirmEmail" id="tbconfirmEmail"
						defaultValue={values.confirmEmail}
						onMouseDown={(e) => e.target.focus()}
						onBlur={handleUserInput('confirmEmail')}
						onChange={leftBlank('confirmEmail')}
						maxLength="60"
						aria-describedby="tbconfirmEmail_help" />
					<div id="tbconfirmEmail_help" aria-atomic="true" className="tds-field__message" role="alert">
						<p>{error.formErrors.confirmEmail}</p>
					</div>
				</div>
			</div>;
			isRecaptcha = '';
		} else {
			url = <div>
				<div className={`form-group tds-field ${error.formErrors.cssEmailMsg}`}>
					<label htmlFor="email" className={`tds-field__label ${error.formErrors.cssEmailMsg}`}>Email </label>
					<input type="email" className="tds-field__input" name="email" id="tbemail"
						defaultValue={values.email}
						onBlur={handleUserInput('email')}
						onMouseDown={(e) => e.target.focus()}
						onChange={leftBlank('email')}
						maxLength="60"
						aria-describedby="tbemail_help" />
					<div id="tbemail_help" aria-atomic="true" className="tds-field__message" role="alert">
						<p>{error.formErrors.emailMsg}</p>
					</div>
				</div>
				<div className={`form-group tds-field ${error.formErrors.cssConfirmEmail}`}>
					<label htmlFor="confirmEmail" className={`tds-field__label ${error.formErrors.cssConfirmEmail}`}>Re-Enter Email </label>
					<input type="email" className="tds-field__input" name="confirmEmail" id="tbconfirmEmail"
						defaultValue={values.confirmEmail}
						onMouseDown={(e) => e.target.focus()}
						onBlur={handleUserInput('confirmEmail')}
						onChange={leftBlank('confirmEmail')}
						maxLength="60"
						aria-describedby="tbconfirmEmail_help" />
					<div id="tbconfirmEmail_help" aria-atomic="true" className="tds-field__message" role="alert">
						<p>{error.formErrors.confirmEmail}</p>
					</div>
				</div>
				{RecaptchaEnabled ? <div className="tds-button-container">
					<Recaptcha
						sitekey={values.reCatchaKey}
						render="explicit"
						verifyCallback={this.verifyCallback} />
				</div> : null}
			</div>;
			isRecaptcha =
				RecaptchaEnabled && <div className="tds-button-container">
					<Recaptcha
						sitekey={values.reCatchaKey}
						render="explicit"
						verifyCallback={this.verifyCallback} />
				</div>;
		}
		return (
			<div className="tds-container tds-col-md-5">
				<div className={hidden1} >
					<div id="my-alert-error" tabIndex="-1">
						Your email address is required to proceed, please enter an email or you may contact us at 1-866-976-3041 from M-F, 8am - 5pm ET to help submit your request.
					</div>
				</div>
				<div id="zipInvalidMessage" className={hidden}>We're sorry! The California Consumer Privacy Act applies only to California residents.
				</div>
				<form method="POST" onSubmit={this.handleSubmit}>
					<h4>Step 1 out of 4</h4>
					<h1 className="tds-h1--small">Let's start by telling us who you are.</h1>
					{/* style = fontSize:48 discarded for tds7upgrade */}
					<p>
						The information provided here will be processed solely to
						verify your identity. We take great
						care in protecting the information you provide.
					</p>
					<div id="tb"></div>
					<div className={`tds-field ${error.formErrors.cssFirstName}`}>
						<label htmlFor="firstName" className={`tds-field__label ${error.formErrors.cssFirstName}`}>First Name</label>
						<input type="text" className="tds-field__input" id="tbfirstName"
							area-required="true"
							defaultValue={values.firstName}
							onBlur={handleUserInput('firstName')}
							onKeyPress={(e) => this.thirdMethod(e)}
							onChange={leftBlank('firstName')}
							onMouseDown={(e) => e.target.focus()}
							maxLength="30"
							aria-describedby="tbfirstName_help" />
						<div id="tbfirstName_help" aria-atomic="true" className="tds-field__message" role="alert">
							<p>{error.formErrors.firstName}</p>
						</div>
					</div>
					<div className={`tds-field ${error.formErrors.cssLastName}`}>
						<label htmlFor="lastName" className={`tds-field__label ${error.formErrors.cssLastName}`}>Last Name</label>
						<input type="text" className="tds-field__input" id="tblastName"
							name="lastName"
							defaultValue={values.lastName}
							onBlur={handleUserInput('lastName')}
							onKeyPress={(e) => this.thirdMethod(e)}
							onChange={leftBlank('lastName')}
							onMouseDown={(e) => e.target.focus()}
							maxLength="30"
							aria-describedby="tblastName_help"
						></input>
						<div id="tblastName_help" aria-atomic="true" className="tds-field__message" role="alert">
							<p>{error.formErrors.lastName}</p>
						</div>
					</div>
					<div className="tds-row">
						<div className="tds-col">
							<div className={`form-group tds-field ${error.formErrors.cssSsn}`}>
								<label htmlFor="ssn" className={`tds-field__label ${error.formErrors.cssSsn}`}>Social Security Number </label>
								<div className="col-sm-7 col-10 float-left">
									<input type="password" className="tds-field__input" name="ssn" id="tbssn"
										onMouseDown={(e) => e.target.focus()}
										defaultValue={values.ssn}
										onBlur={handleUserInput('ssn')}
										onKeyPress={(e) => this.firstMethod(e)}
										onChange={leftBlank('ssn')}
										maxLength="9"
										aria-describedby="tbssn_help" />
									<div id="tbssn_help" aria-atomic="true" className="tds-field__message" role="alert">
										<p>{error.formErrors.ssn}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="tds-col">
						<div className="tds-col">
							<fieldset className={`${error.formErrors.cssDob}`}>
							<legend className={`tds-field__label-date-time-input fitContent ${error.formErrors.cssDob}`}>Date Of Birth</legend>
								<div className="tds-date-input" id="date-input-0">
									<span className="tds-date-input__date-part">

										<input name="month" type="text" inputMode="numeric" pattern="\d*"
											onKeyPress={(e) => this.firstMethod(e)}
											onMouseDown={(e) => e.target.focus()}
											placeholder="MM"
											maxLength="2" data-date-part="month" aria-label="Month" id="month"
											defaultValue={values.dobMonth}
											onBlur={handleUserInput('dob')}
											onChange={leftBlank('dob')} />
									</span>
									<span className="tds-date-input__date-part">
										<input name="day" type="text" inputMode="numeric" pattern="\d*"
											onKeyPress={(e) => this.firstMethod(e)}
											placeholder="DD" maxLength="2" data-date-part="day" aria-label="Day" id="day"
											defaultValue={values.dobDay} onBlur={handleUserInput('dob')} onChange={leftBlank('dob')}
											onMouseDown={(e) => e.target.focus()} />
									</span>
									<span className="tds-date-input__date-part">
										<input name="year" type="text" inputMode="numeric" pattern="\d*"
											onKeyPress={(e) => this.firstMethod(e)}
											placeholder="YYYY" maxLength="4" data-date-part="year" aria-label="Year" id="year"
											defaultValue={values.dobYear} onBlur={handleUserInput('dob')} onChange={leftBlank('dob')}
											onMouseDown={(e) => e.target.focus()} />
									</span>
								</div>
								<div aria-atomic="true" className="tds-field__message" role="alert">
									<p>{error.formErrors.dob}</p>
								</div>
							</fieldset>
						</div>
						</div>
					</div>
					<legend className="tds-mt-xl">Your Address</legend>
					<div className={`tds-mt-xl ${hiddenPB}`}>
						<button aria-label="Dismiss dialog" className="tds-modal__dismiss" type="button" onClick={this.closeX}>
							<svg focusable="false">
								<use href="/assets/icons/functional/symbol-sprites.svg#x-1-18"></use>
								<path d="M1.146 1.146a.5.5 0 01.708 0l4.135 4.136 4.136-4.136a.5.5 0 01.708.708L6.696 5.989l4.137 4.136a.5.5 0 01.057.638l-.057.07a.5.5 0 01-.708 0L5.99 6.696l-4.135 4.137a.5.5 0 11-.708-.708L5.282 5.99 1.146 1.854a.5.5 0 01-.057-.638z" fill="currentColor"></path>
							</svg></button>
						<div id="pbMsg" ><b>THIS ADDRESS WAS NOT FOUND.</b>
							<div className="tds-mt-xl"><b>DID YOU MEAN?</b></div>
							{this.state.pbAddressLineOne}
							{this.state.pbAddressLineTwo}
							<div className="tds-mt-xl"><a href="#" onClick={this.updateAddress}>Use Suggested Address</a></div>
						</div>
					</div>
					<div id="pbMsg" className={pbMsg}>The street, state, or zipcode you entered does not match a valid California address. Please try again.
					</div>
					<div className={`tds-field ${error.formErrors.cssAddress}`}>
						<label htmlFor="address" className={`tds-field__label ${error.formErrors.cssAddress}`}>Address</label>
						<input type="text"
							className="tds-field__input" name="address" id="tbaddress"
							value={values.address}
							onKeyPress={(e) => this.secondMethod(e)}
							onBlur={handleUserInput('address')}
							onChange={leftBlank('address')}
							maxLength="70"
							aria-describedby="tbaddress_help"
							onMouseDown={(e) => e.target.focus()}
						></input>
						<div id="tbaddress_help" aria-atomic="true" className="tds-field__message" role="alert">
							<p>{error.formErrors.address}</p>
						</div>
					</div>
					<div className="tds-field">
						<label htmlFor="apt" className="tds-field__label">APT / Suite # (Optional) </label>
						<input type="text" className="tds-field__input" name="apt" minLength="1" maxLength="5"
							defaultValue={values.apt} onKeyPress={(e) => this.fourthMethod(e)}
							onChange={handleUserInput('apt')} onMouseDown={(e) => e.target.focus()} />
					</div>
					<div className={`tds-field ${error.formErrors.cssCity}`}>
						<label htmlFor="city" className={`tds-field__label ${error.formErrors.cssCity}`}>City</label>
						<input type="text" className="tds-field__input" name="city"
							id="tbcity"
							onMouseDown={(e) => e.target.focus()}
							value={values.city}
							onBlur={handleUserInput('city')}
							maxLength="24"
							aria-describedby="tbcity_help"
							onChange={leftBlank('city')}
						></input>
						<div id="tbcity_help" aria-atomic="true" className="tds-field__message" role="alert">
							<p>{error.formErrors.city}</p>
						</div>
					</div>
					<div className="tds-row">
						<div className="tds-col-5">
							<div className="form-group tds-field">
								<label htmlFor="state" className="col-sm-4 control-label float-left tds-field__label">State</label>
								<div className="col-sm-7 col-10 float-left">
									<select className="tds-field__input" name="tds-field__input" id="dropdown-example" defaultValue={'CA'} disabled>
										<option className="tds-field__input form-control" name="state" value="CA" hidden disabled>CA</option>
									</select>
								</div>
							</div>
						</div>
						<div className="tds-col-7 mbMobile">
							<div className={`form-group tds-field ${error.formErrors.cssZipcode}`}>
								<label htmlFor="zipcode" className={`col-sm-5 col-md-4 control-label alignLeft float-left tds-field__label ${error.formErrors.cssZipcode}`} >Zip Code</label>
								<div className="col-sm-6 col-md-6 float-left">
									<input type="text" name="zipCode" className="tds-field__input form-control"
										onKeyPress={(e) => this.firstMethod(e)} maxLength="5" id="tbzipCode"
										value={values.zipCode} onMouseDown={(e) => e.target.focus()}
										onBlur={handleUserInput('zipCode')}
										aria-describedby="tbzipCode_help"
										onChange={leftBlank('zipCode')} />
									<div id="tbzipCode_help" aria-atomic="true" className="tds-field__message" role="alert">
										<p>{error.formErrors.zipCode}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<legend className="tds-mt-xl">Your Contact Info</legend>
					<div className={`form-group tds-field ${error.formErrors.cssPhoneNo}`}>
						<label htmlFor="phoneNo" className={`tds-field__label ${error.formErrors.cssPhoneNo}`}>Phone Number</label>
						<NumberFormat format="(###) ###-####" mask="_" className="tds-field__input"
							onBlur={handleUserInput('phoneNo')} defaultValue={values.phoneNo} id="tbphoneNo"
							onChange={leftBlank('phoneNo')} onMouseDown={(e) => e.target.focus()}
							aria-describedby="tbphoneNo_help" />
						<div id="tbphoneNo_help" aria-atomic="true" className="tds-field__message" role="alert">
							<p>{error.formErrors.phoneNo}</p>
						</div>
					</div>
					{url}
					<div className="tds-button-container" >
						{busy ? (<Button className="tds-button--primary" variant="primary" disabled={false}
							small={false}
							busy
							onClick={this.handleSubmit} > CONTINUE </Button>) :
							(<Button className="tds-button--primary" variant="primary" disabled={false}
								small={false}
								busy={false}
								onClick={this.handleSubmit} > CONTINUE </Button>)}
						<button type="button" className="tds-button--tertiary" onClick={this.onOpenModal}>CANCEL</button>
						<Modal open={open}
							onClose={this.onCloseModal}
							center
							showCloseIcon={true} >
							<WarningModal
								onCloseModal={this.onCloseModal.bind(this)}
								navigateToHome={this.navigate.bind(this)} />
						</Modal>
					</div>
				</form>
			</div>
		);
	}
}
export default withRouter(VerifyIdentity);
