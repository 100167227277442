import React, { Component } from 'react';

export class ServiceDownPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        document.title = 'Consumer Privacy | Travelers Insurance';
    }

    navigate = () => {
        this.props.navigateToHome();
    }

    render() {
        return (
            <div className="tds-container tds-col-md-5">
                <div className="tds-mt-xl">
                    <h1 className="tds-h1--small">We're sorry.</h1>
                    <p className="tds-large"> We are currently unavailable. Please try back soon.</p>
                    <div className="tds-button-container">
                        <button type="button" className="tds-button--tertiary" onClick={this.navigate}>CANCEl</button>
                    </div>
                    <hr />
                    <div className="tds-mt-l tds-text-align-md-center">
                        <span className="tds-bold">Technical Support</span>
                        <div>
                            If you're having problems registering, call us for help.
                        </div>
                        <a href = "tel:1-844-505-7208">1-844-505-7208</a>
                        <div>
                        8 a.m. to 8 p.m. ET, Monday-Friday
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ServiceDownPage;
