import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import CustomerRequestApi from '../components/api/customerRequest';

export class CorrectInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            details: '',
            errorMessage: '',
            hasError: false,
            isSubmitted: false,
            open: false,
            show: true,
            submitSuccessful: false
        };
    }

    onChange = (e) => {
        this.setState({
            details: e.target.value
        });
        if (e.target.value === '') {
            this.setState({
                errorMessage: 'Cannot be left blank.',
                hasError: true
            });
        }
        else {
            this.setState({
                errorMessage: '',
                hasError: false
            });
        }
    }

    submit = async () => {
        this.setState({
            isSubmitted: true
        });
        if (this.state.details === '') {
            this.setState({
                errorMessage: 'Cannot be left blank.',
                hasError: true
            });
        } else {
            const request = {
                details: this.state.details,
                trackingNumber: this.props.trackingNumber
            };
            const response = await CustomerRequestApi.updateInformation(request);
            if (response?.status === 200) {
                this.props.history.push({ pathname: '/correctionconfirmation', state: { trackingNumber: this.props.trackingNumber } });
                this.setState({
                    submitSuccessful: true
                });
            } else {
                this.setState({
                    errorMessage: 'Something went wrong when submitting your request.',
                    hasError: true
                });
                this.hideModal();
            }
        }
    };

    hideModal = () => {
        this.props.onCloseModal();
    };

    render() {
        const showHideClassName = this.state.show ? 'tds-col-md-12 tds-mt-xl' : 'modal display-none';
        return (
            <div className={showHideClassName}>
                <h3 className="tds-col-12 tds-text-align-sm-center">
                    Please describe the personal information you would like us to correct :
                </h3>
                <hr />
                <div className="tds-mt-xl">
                    <div className={(this.state.hasError && this.state.isSubmitted) ? 'tds-field tds-field--error' : this.state.submitSuccessful ? 'tds-field tds-field--success' : 'tds-field'}>
                        <textarea id={(this.state.hasError && this.state.isSubmitted) ? 'textarea-error' : this.state.submitSuccessful ? 'textarea-success-message' : 'textarea-basic'} className="tds-field__input" rows="5" onChange={this.onChange} minLength="5" maxLength="1500"></textarea>
                        {(this.state.hasError && this.state.isSubmitted) ?
                            <div id="textarea-error-message" className="tds-field__message" role="alert">{this.state.errorMessage}</div>
                            : this.state.submitSuccessful ?
                             <div id="textarea-success-message" className="tds-field__message" role="alert">Thank you for your submission.</div>
                                :
                                null
                        }
                    </div>
                </div>
                <div className="tds-button-container">
                    <button type="button" id="btnSubmit" className="tds-button--primary closeButton" onClick={this.submit}>
                        SUBMIT
                    </button>
                </div>
                <div className="tds-button-container">
                    <button type="button" id="btnClose" className="tds-button--tertiary closeButton" onClick={this.hideModal}>
                        CLOSE
                    </button>
                </div>
            </div>
        );
    }
}

export default withRouter(CorrectInformation);
