import httpClient from '../../utilities/httpClient';

let client = httpClient.createClient();
let appConfig = null;

class ReferenceService {
  static async configs() {
    if (!appConfig) {
      var response = await client.get('/api/reference/configs');
      appConfig = {
        disclosureLink: response.data.footerLegalUrl,
        footer: {
          TRAVELERS_LEGAL_URL: response.data.footerLegalUrl,
          TRAVELERS_PRIVATE_STATEMENT: response.data.footerPrivacyStatement
        },
        getCaptchaKey: response.data.recaptchaKey,
        isInternal: response.data.isInternal
      };
    }
    return appConfig;
  }

  static async vaultService(data) {
    var response = await client.post('/api/ssn-vault/ssn-vault', {
      ssn: data
    });
    return response;
  }

  static async pbService(data) {
    console.log('data', data);
    var response = await client.post(
      '/api/pb-standardize-address/getPBAddress', data);
    return response;
  }
}

export default ReferenceService;
